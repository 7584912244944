import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Highcharts from 'highcharts/highcharts';
import { Options } from 'highcharts';
import moment from 'moment';
import { Router } from '@angular/router';
import { SCREEN_OVERVIEW, TODAY, YESTERDAY } from '../../param-util';
import { MatDialog } from '@angular/material/dialog';
import { ReviewAnalyticModalComponent } from '../review-analytic-modal/review-analytic-modal.component';
import { ChartData, ChartDataInsights, ChartDataResponse } from '../../interfaces/chart-data-response';

@Component({
  selector: 'app-analytic-card',
  templateUrl: './analytic-card.component.html',
  styleUrls: ['./analytic-card.component.scss']
})
export class AnalyticCardComponent implements AfterViewInit {
  @Input() title: string;
  @Input() chartId: string;
  @Input() values: () => Promise<{ [key: string]: any } | ChartDataResponse>;
  @Input() selectedMonth: number;
  @Input() specificationDataLoaded: boolean;
  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, public dialog: MatDialog) {}

  compare: { [key: string]: any } = {};
  cardData: {
    percent: number;
    color: string;
    total: number;
    today: number;
    yesterday: number;
    data: number;
  };
  data: Array<any>;
  isDataLoaded = false;
  allData: any[];
  isSpecificationVisible: boolean = false;
  budget = 0;

  ngAfterViewInit(): void {
    this.values().then(
      (response: ChartDataResponse | any) => {
        if (this.chartId.includes('pta-analytics')) {
          const chartData = response as ChartDataResponse;
          this.allData = chartData.data.map((data: ChartData) => [new Date(data.key).getTime(), data.value]);
          if (this.selectedMonth === -1) {
            this.data = this.allData.slice(30);
          } else {
            this.data = this.allData.filter((data: number[]) => new Date(data[0]).getMonth() === this.selectedMonth);
          }
          const insights = chartData.insights as ChartDataInsights;

          let segregateData = {};
          insights.totalCountSegregation.map((data: ChartData) => {
            segregateData[data.key] = data.value;
          });

          this.cardData = {
            percent: insights.changeType === 'Positive' ? insights.changeValue : insights.changeValue * -1,
            color: insights.changeType === 'Positive' ? '#049400' : '#cf0808',
            total: insights.totalCount,
            today: segregateData['Today'],
            yesterday: segregateData['Yesterday'],
            data: segregateData['30D']
          };

          const sparklineOption = this.getSparklineChart(this.chartId, this.data, this.cardData.color);
          Highcharts.chart(this.chartId, sparklineOption);
          this.isDataLoaded = true;
        } else {
          this.budget = response.budget ? response.budget : 0;
          this.compare['multipleDayCompare'] = response.multipleDayCompare;
          this.compare.multipleDayCompare['percent'] = Number(
            Number(this.compare.multipleDayCompare.percent).toFixed(0)
          );
          this.compare['oneDayCompare'] = response.oneDayCompare;
          this.data = response.data;
          this.allData = response.allData;

          const sparklineOption = this.getSparklineChart(
            this.chartId,
            this.data,
            this.compare.multipleDayCompare.color
          );
          Highcharts.chart(this.chartId, sparklineOption);
          this.isDataLoaded = true;
        }
      },
      (reject: any) => {
        this.isDataLoaded = false;
      }
    );
  }

  specification(): void {
    this.isSpecificationVisible = !this.isSpecificationVisible;
    this.response.emit();
  }

  getSparklineChart(name: string, paramData: Array<number>[], color: string): Options {
    let chartOption: Options = {
      chart: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        type: 'spline',
        margin: [2, 0, 2, 0],
        width: 100,
        height: 25,
        style: {
          overflow: 'visible'
        }
      },
      time: {
        useUTC: false
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
        type: 'datetime'
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        outside: true,
        formatter: function () {
          let suffix = '';
          if (name === 'utilization-analytics') {
            suffix = '%';
          }
    
          const formattedValue =  
             Highcharts.numberFormat(this.y, this.y % 1 !== 0 ? 2 : 0, '.', ',');
      
          return `<span>${moment(this.x).format('DD-MMM-yyyy')}: <b>${formattedValue}${suffix}</b></span>`;
        }
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 2,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          },
          pointPlacement: 'end'
        },
        column: {
          negativeColor: '#910000',
          borderColor: 'silver'
        }
      },
      series: [
        {
          color: color,
          name: name,
          type: 'spline',
          data: paramData
        }
      ]
    };
    return chartOption;
  }

  navigate(chartId: string, clickedButtonType: 'Yesterday' | 'Today'): void {
    const commonColumn = ['assetName', 'fromDate', 'toDate', 'siteName'];
    switch (chartId) {
      case 'hour-run-analytics':
        this.router.navigate([
          '/pages/insights/utilization',
          {
            activatedColumns: [...commonColumn, 'workRun', 'utilizationPercent']
          }
        ]);
        break;
      case 'fuel-consumption-analytics':
        this.router.navigate([
          '/pages/insights/utilization',
          {
            activatedColumns: [...commonColumn, 'actualFuel', 'estimatedFuel', 'carbonIntensity']
          }
        ]);
        break;
      case 'kilometer-analytics':
        this.router.navigate([
          '/pages/insights/utilization',
          {
            activatedColumns: [...commonColumn, 'totalKM']
          }
        ]);
        break;
      case 'utilization-analytics':
        this.router.navigate([
          '/pages/insights/utilization',
          {
            activatedColumns: [...commonColumn, 'utilizationPercent']
          }
        ]);
        break;
      case 'ore-mining-analytics':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: YESTERDAY === clickedButtonType ? YESTERDAY : TODAY,
            chartType: chartId
          }
        ]);
        break;
      case 'plant-processing-analytics':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: YESTERDAY === clickedButtonType ? YESTERDAY : TODAY,
            chartType: chartId
          }
        ]);
        break;
      case 'rehabilitation-analytics':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: YESTERDAY === clickedButtonType ? YESTERDAY : TODAY,
            chartType: chartId
          }
        ]);
        break;
      default:
        break;
    }
  }

  reviewAnalytics(): void {
    if (this.isDataLoaded) {
      const dialogRef = this.dialog.open(ReviewAnalyticModalComponent, {
        width: '60vw',
        // height: '60vh',
        backdropClass: 'reviewAnalytic',
        data: {
          chartId: this.chartId,
          data: this.allData,
          title: this.title,
          selectedMonth: this.selectedMonth
        }
      });
    }
  }
}
