import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalHeaderSiteService {
  public headerSite = new BehaviorSubject<string>('');

  public headerSiteName = new BehaviorSubject<string>('');

  public headerActiveClass = new BehaviorSubject<boolean>(true);

  constructor() {}
}
