import { Component } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';

@Component({
  selector: 'app-redirect-renderer',
  templateUrl: './redirect-renderer.component.html',
  styleUrls: ['./redirect-renderer.component.scss']
})
export class RedirectRendererComponent extends AgCellRendererBase {
  constructor() {
    super();
  }

  ngOnInit() {}

  onClickRedirect(selectedValue: string) {
    this.sendRedirectEvent(AgCellRendererEvent.REDIRECT_LINK_EVENT, selectedValue + '');
  }
}
