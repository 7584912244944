import { Component } from '@angular/core';
import Highcharts from 'highcharts/highcharts';
import { Options } from 'highcharts';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { isNullOrUndefined } from '../../function-util';

@Component({
  selector: 'app-sparkline-cell-renderers',
  templateUrl: './sparkline-cell-renderers.component.html',
  styleUrls: ['./sparkline-cell-renderers.component.scss']
})
export class SparklineCellRenderersComponent extends AgCellRendererBase {
  constructor() {
    super();
  }

  name;
  data;

  ngOnInit() {
    if (!isNullOrUndefined(this.params.value)) {
      this.data = Object.values(this.params.value);
      this.name = Object.keys(this.params.value);
      this.chartOptions.series = [
        {
          name: this.name,
          type: 'spline',
          data: this.data
        }
      ];
    }
  }

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Options = {
    chart: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      type: 'area',
      margin: [2, 0, 2, 0],
      width: 180,
      height: 25,
      style: {
        overflow: 'visible'
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      lineColor: '#0000',
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      formatter() {
        const series = this.points[0].series;
        if (series.data[this.x].name != undefined) {
          return `<span style="font-size: 10px"> ${series.data[this.x].name}</span><br/>
            <br>
            <b>${this.y}</b>`;
        } else {
          return `<span style="font-size: 10px"> ${series.name[this.x]}</span><br/>
          <br>
          <b>${this.y}</b>`;
        }
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        }
      },
      column: {
        negativeColor: '#910000',
        borderColor: 'silver'
      }
    },
    series: [
      {
        name: '',
        type: 'area',
        data: []
      }
    ]
  };
}
