<app-sidebar
  (collapsedEvent)="receiveCollapsed($event)"
  [myCollapseSidebar]="myCollapse"
  [logginUserName]="logginUserName"></app-sidebar>
<div (click)="closeNav()" [ngClass]="{ blurStyle: !collapedSideBar }">
  <div>
    <div class="d-flex flex-column" style="min-height: 100%">
      <!-- [style.padding-top.px]="fullTopHeaderBar.offsetHeight" -->
      <div class="flex-shrink-0 flex-grow-0 fixed-top" style="z-index: 2" #fullTopHeaderBar>
        <app-header
          (collapsedEventHeader)="receiveCollapsedHeader()"
          [logginUserClientName]="logginUserClientName"></app-header>
      </div>
      <div [ngClass]="{ 'flex-shrink-0 flex-grow-1': true }">
        <section [ngClass]="{ collapsed: collapedSideBar }" class="main-container">
          <router-outlet (activate)="onActivate()"></router-outlet>
        </section>
      </div>
      <!--     <div [ngClass]="{ 'flex-shrink-0 flex-grow-0':true }">
          <app-footer></app-footer>
        </div>
        <app-toast aria-live="polite" aria-atomic="true"></app-toast> -->
    </div>
  </div>
</div>
<!-- <footer [ngClass]="{'al-footer-collapsed': collapedSideBar, 'al-footer': true, 'clearfix' : true }">
<div class="al-footer-main clearfix">
    <div class="al-copy">&copy; <a href="/">COGNECTO</a> {{currentYear}}</div>
</div>
</footer> -->
<!-- <i #baBackTop class="fa fa-angle-up ba-back-top" (click)="onClick()" title="Back to Top"></i> -->
