<div class="flex-child">
    <div class="header-row">
        <div class="row-1 body-font">{{ title }}</div>
        <div *ngIf="specificationDataLoaded">
            <i class="fa-sharp fa-regular fa-plus specification-icon" *ngIf="chartId === 'ore-mining-analytics' && !isSpecificationVisible" (click)="specification()"></i>
            <i class="fa-solid fa-minus specification-icon" *ngIf="chartId === 'ore-mining-analytics' && isSpecificationVisible" (click)="specification()"></i>
        </div>
    </div>
    <div class="row-2 graph">
        <div class="stats">
            <p class="total-sum">{{ compare?.multipleDayCompare?.totalSum || cardData?.data }}</p>
            <img src="./../../../../assets/icons/Arrow 1.svg" *ngIf="compare?.multipleDayCompare?.percent > 0 || cardData?.percent > 0" alt="" />
            <img src="./../../../../assets/icons/arrow_down.svg" *ngIf="0 > compare?.multipleDayCompare?.percent || 0 > cardData?.percent"
                alt="" />
            <p *ngIf="(compare?.multipleDayCompare?.percent | number) || cardData?.percent"
            [ngStyle]="{ color: compare?.multipleDayCompare?.color || cardData?.color }" class="percent body-font">{{
                (compare?.multipleDayCompare?.percent | number) || cardData?.percent }}%</p>
        </div>
        <div class="spark-graph" (click)="reviewAnalytics()">
            <div id="{{ chartId }}"></div>
            <div class="is-data-found" *ngIf="!isDataLoaded">
                <P>No Data Found</P>
            </div>
        </div>
    </div>
    <div class="row-3 difference">
        <p *ngIf="budget > 0" class="see-full-report" (click)="navigate(chartId, 'Today')">
            Budget: {{budget}}</p>
        <ng-container *ngIf="selectedMonth === -1">
            <p class="see-full-report" (click)="navigate(chartId, 'Today')">{{ compare?.oneDayCompare?.totalSum || cardData?.today }} Today</p>
            <p class="see-full-report" (click)="navigate(chartId, 'Yesterday')">{{ compare?.oneDayCompare?.previousSum || cardData?.yesterday }}
                Yesterday</p>
        </ng-container>
    </div>
</div>