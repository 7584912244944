// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'http://localhost:8082/iot',
  websocketUrl: 'http://localhost:8082/iot/iot-ws',

  // serverUrl: 'https://api.dev.cognecto.com/iot',
  // websocketUrl: 'https://api.dev.cognecto.com/iot/iot-ws',

  // serverUrl: 'http://dev-cognecto.us-east-1.elasticbeanstalk.com/iot',
  // websocketUrl: 'http://dev-cognecto.us-east-1.elasticbeanstalk.com/iot/iot-ws',

  // serverUrl: 'https://app.cognecto.com/iot',
  // websocketUrl: 'https://app.cognecto.com/iot/iot-ws',
  mapbox: {
    accessToken: 'pk.eyJ1Ijoic3dlbWFub2oiLCJhIjoiY2s3dnc2cHVuMGdxbDNtbzJjM2lidDBsbCJ9.SvTBS6iD-PyxWQ3YI59Y2w'
  },
  aggrid: {
    // tslint:disable-next-line:max-line-length
    license:
      'CompanyName=Cognecto Private Limited,LicensedApplication=app.aognecto.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021760,ExpiryDate=3_November_2022_[v2]_MTY2NzQzMzYwMDAwMA==dfaa1516a26ce7c1a02822f8f4ea2f3b'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
