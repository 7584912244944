<mat-card class="chart-card">
  <mat-card-title>
    <div class="container-header">
      <div class="title">
        <img class="header-icon" [src]="headerIcon" />
        <p class="heading">{{ title }}</p>
      </div>
      <div class="indicate">
        <p class="indicate-msg">{{ indicateMsg }}</p>
      </div>
    </div>
    <hr class="hr-line" />
  </mat-card-title>
  <mat-card-content>
    <div class="chart-container">
      <div class="graph">
        <div [id]="chartId"></div>
        <div class="is-data-found" *ngIf="!isDataFound">
          <P>No Data Found</P>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions style="padding: 0px 16px">
    <div class="footer">
      <p class="body-font">{{ lastUpdatedMsg }}</p>
      <p class="body-font"><span class="see-full-report" (click)="openReport(chartId)">See full report</span></p>
    </div>
  </mat-card-actions>
</mat-card>
