<div class="step-list-container">
    <app-step-card [stepTitle]="stepListTitle" [stepSubtitle]="stepListSubtitle"
     [stepTitleColor]="stepListTitleColor" [stepSubtitleColor]="stepListSubtitleColor"
     [faIconClass]="faIconClass">
        <div class="d-flex justify-content-end align-items-center w-100">
            <button nz-button nzType="primary" (click)="onAddClick()">
                <i class="fa fa-plus" [style.color]="'white'"></i> Add field
            </button>
        </div>
    </app-step-card>
    <div class="list-container">
        <ng-content></ng-content>
    </div>
</div>