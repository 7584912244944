import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-operator-report-btn',
  templateUrl: './operator-report-btn.component.html',
  styleUrls: ['./operator-report-btn.component.scss']
})
export class OperatorReportBtnComponent {
  @Input() chartArray: any;
  @Input() currentActive: string;
  @Input() forPdf: boolean = false;
  @Output() tabClicked: EventEmitter<string> = new EventEmitter<string>();

  showTab(title: string): void {
    this.tabClicked.emit(title);
  }
}
