<div class="d-flex">
  <ng-progress
    [meteor]="options.meteor"
    [color]="options.color"
    [min]="options.minimum"
    [speed]="options.speed"
    [spinner]="options.spinner"
    [spinnerPosition]="'right'"
    [direction]="'ltr+'"
    [thick]="options.thick"
    [trickleSpeed]="options.trickleSpeed"
    [ease]="options.ease">
  </ng-progress>
  <div class="navbar navbar-expand-lg d-flex justify-content-between align-items-center flex-grow-1 deskTop">
    <div class="d-flex">
      <!-- style="margin-top: -40px;" -->
      <!--            <button class="header-msg-icon-btn"></button>-->
      <button class="header-alert-icon-btn notification-count" (click)="notify($event)"></button>
      <div class="d-flex flex-row nav-footer">
        <button
          *ngFor="let module of provisioneddashboardModules; let i = index"
          id="{{ module.pathValue }}"
          [class]="'nav-item nav-footer-item' + (i === currentTabIndex ? ' active' : '')"
          (click)="getDashboardMenu(module.pathValue, undefined, undefined)">
          {{ module.moduleName }}
        </button>
      </div>
    </div>
    <div class="d-flex">
      <div [ngClass]="searchBarToggle ? 'search-bar-show' : 'search-bar-hide'" class="search-bar">
        <nz-select
          [(nzOpen)]="nzSelectState"
          id="nz-searh-bar-click-id"
          class="nz-search-bar"
          nzSize="large"
          [nzBackdrop]
          nzShowSearch
          [formControl]="selectedSiteId">
          <nz-option-group class="search-bar-group-title" nzLabel="Sites">
            <nz-option *ngFor="let site of allSites" [nzLabel]="site.siteName" [nzValue]="site"> </nz-option>
          </nz-option-group>
          <nz-option-group nzLabel="Assets">
            <nz-option *ngFor="let asset of allAssets" [nzLabel]="asset.assetName" [nzValue]="asset"> </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <button
        type="button"
        class="btn btn-outline-secondary nav-button search-bar-icon"
        matTooltip="Search"
        (click)="getSearchBarToggle('desktopIpad')">
        <i class="fa" [ngClass]="searchBarToggle ? 'fa-times' : 'fa-search'"></i>
      </button>

      <div class="btn-group" role="group">
        <button
          type="button"
          class="btn btn-outline-secondary nav-button map-icon-btn"
          matTooltip="Map"
          (click)="navigate('map')"></button>
        <button
          type="button"
          class="btn btn-outline-secondary nav-button trend-analysis-icon-btn"
          matTooltip="Trend Analysis"
          (click)="navigate('trend-analysis')"></button>
        <button
          type="button"
          class="btn btn-outline-secondary nav-button video-analysis-icon-btn"
          matTooltip="Live Feed"
          (click)="navigate('live-feed')"></button>
      </div>
      <!-- <div class="loggedUser"><p>{{logginUserClientName}}</p></div> -->
      <app-quick-access></app-quick-access>
      <div ngbDropdown #userDropdown="ngbDropdown">
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm nav-button ml-2 user-icon-btn"
          (click)="userDropdown.open()"
          ngbDropdownToggle></button>
        <div ngbDropdownMenu class="logout-dropdown">
          <div class="heading-wrapper">
            <div class="imgcontainer">
              <img src="../../../../assets/icons/header-bar-icons/user.svg" class="img-fluid" />
            </div>
            <div class="textContainer">
              <div class="font-small">{{ loggedInUser }}</div>
              <hr />
              <div class="bold">{{ logginUserClientName }}</div>
            </div>
          </div>

          <button (click)="gotoProfile()" class="logout-dropdown-item" ngbDropdownItem> Profile </button>
          <button (click)="signOut()" class="logout-dropdown-item" ngbDropdownItem> Logout </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container isMobile">
  <div class="row">
    <div class="header">
      <ul class="sidenav">
        <li
          *ngFor="let module of provisioneddashboardModules; let i = index"
          id="{{ module.pathValue }}"
          [class]="module.pathValue + (i === 0 ? ' active' : '')"
          (click)="getDashboardMenu(module.pathValue, undefined, undefined)"></li>
        <li class="map-icon-btn" matTooltip="map" (click)="navigate('map')"></li>
        <!-- <li class="live-feed"
                    matTooltip="Live Feed" (click)="navigate('live-feed')">
                </li> -->
      </ul>
    </div>
  </div>
  <div class="row nmb-cstm-row">
    <div class="nmb-sub-header">
      <div class="search-bar-m">
        <div [ngClass]="searchBarToggle ? 'search-bar-show' : 'search-bar-hide'" class="search-bar-mobile">
          <nz-select
            [(nzOpen)]="nzSelectStateMobile"
            id="nz-searh-bar-click-id-mobile"
            [nzSuffixIcon]="qrScannerTemplate"
            class="nz-search-bar-mobile"
            nzSize="large"
            nzShowSearch
            [formControl]="selectedSiteId">
            <nz-option-group class="search-bar-group-title" nzLabel="Sites">
              <nz-option *ngFor="let site of allSites" [nzLabel]="site.siteName" [nzValue]="site"> </nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Assets">
              <nz-option *ngFor="let asset of allAssets" [nzLabel]="asset.assetName" [nzValue]="asset"> </nz-option>
            </nz-option-group>
          </nz-select>
          <ng-template #qrScannerTemplate>
            <i class="fa fa-qrcode" (click)="qrCodeScanner($event)" aria-hidden="true"></i>
          </ng-template>
        </div>
        <button class="search-bar-btn" matTooltip="Search" (click)="getSearchBarToggle('mobile')">
          <i class="fa" [ngClass]="searchBarToggle ? 'fa-times' : 'fa-search'"></i>
        </button>
      </div>
      <div class="nmb-notify">
        <button class="header-alert-icon-btn notification-count" (click)="notify($event)"></button>
      </div>
      <div class="nmb-drop">
        <div ngbDropdown #userDropdown="ngbDropdown">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm nav-button ml-2 user-icon-btn nmb-right"
            (click)="userDropdown.open()"
            ngbDropdownToggle></button>
          <div ngbDropdownMenu class="logout-dropdown">
            <button (click)="gotoProfile()" class="logout-dropdown-item" ngbDropdownItem> Profile </button>
            <button (click)="signOut()" class="logout-dropdown-item" ngbDropdownItem> Logout </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-10">
      <main *ngIf="showNotification">
        <div class="notificationOverlay" (click)="hidenotification()"></div>
        <div class="notification-container">
          <!--                    <div class="notificationOverlay"></div>-->
          <h3>Notifications</h3>
          <span class="poAbs rightAlign" (click)="setRead($event)">Mark as read </span>
          <div class="notification-type" *ngFor="let item of notificationResponseObject; let i = index">
            <div class="general-notify" *ngIf="item.notificationType === 'ALERT'">
              <div
                class="notification mb-2 wpAlerts"
                [ngClass]="'notification_' + i"
                [class.new]="item.readStatus === 'N'"
                (click)="navNotification(item.notificationType, item.mappingId, i, item.rowId)">
                <div class="no-flex">
                  <div class="alert left"></div>
                  <div class="rightContent">
                    <h4 class="title">Alert Notification</h4>
                    <h5 class="title">{{item.notificationCaseType}}</h5>
                    <i class="material-icons dp48 right" (click) = "deleteNot($event, i, item.rowId)">clear</i>
                    <p class="description">{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="whatsapp-notify" *ngIf="item.notificationType === 'CASE_WHATSAPP'">
              <div
                class="notification notificationAlerts"
                style="top: 16px"
                [ngClass]="'notification_' + i"
                [class.new]="item.readStatus === 'N'"
                (click)="navNotification(item.notificationType, item.mappingId, i, item.rowId)">
                <div class="content">
                  <div class="wp left"></div>
                  <div class="rightContent" style="margin-left: 20px">
                    <h4 class="title">Whatsapp Notification</h4>
                    <h5 class="title">{{item.notificationCaseType}}</h5>
                    <i class="material-icons dp48 right" (click) = "deleteNot($event, i, item.rowId)">clear</i>
                    <p class="description">{{ item.description }}</p>
                    <p class="description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>

<div class="container-fluid isLandscape">
  <div class="row">
    <div class="col-lg-12 col-sm-12 col-md-12 p-0 d-flex align-item-center justify-content-end">
      <div class="header">
        <ul class="sidenav">
          <li
            *ngFor="let module of provisioneddashboardModules; let i = index"
            id="{{ module.pathValue }}"
            [class]="module.pathValue + (i === 0 ? ' active' : '')"
            (click)="getDashboardMenu(module.pathValue, undefined, undefined)"></li>
          <li class="map-icon-btn" matTooltip="map" (click)="navigate('map')"></li>
          <li class="live-feed" matTooltip="Live Feed" (click)="navigate('live-feed')"></li>
        </ul>
      </div>
    </div>
  </div>
</div>
