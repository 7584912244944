<mat-card>
  <div *ngIf="!forPdf" class="chart-group">
    <div class="connected-chart chart-monthly">
      <div [id]="'connectedMonthlyChart'+chartName"></div>
    </div>
    <div class="connected-chart chart-source">
      <div [id]="'connectedSourceChart'+chartName"></div>
    </div>
    <div class="connected-chart chart-daily">
      <div [id]="'connectedDailyChart'+chartName"></div>
    </div>
  </div>
</mat-card>


<div class="chart-group" *ngIf="forPdf">
  <div class="connected-chart chart-monthly">
    <div [id]="'connectedMonthlyChart'+chartName"></div>
  </div>
  <div class="connected-chart chart-source">
    <div [id]="'connectedSourceChart'+chartName"></div>
  </div>
  <div class="connected-chart chart-daily">
    <div [id]="'connectedDailyChart'+chartName"></div>
  </div>
</div>