<div class="modal-content modal-centered" data-backdrop="static" data-keyboard="false" style="padding: 15px;">
  <div class="d-flex flex-column modal-body">
    <div [innerHtml]="modalContent"></div>
    <div class="form-group d-flex justify-content-center" *ngIf="showInputText">
      <mat-form-field class="mt-4 w-75" appearance="outline">
        <mat-label>{{ placeholderContent }}</mat-label>
        <input matInput [(ngModel)]="inputContent" />
      </mat-form-field>
    </div>
    <div class="d-flex flex-wrap justify-content-around align-items-center" style="margin-top: 1.3rem;">
      <span>Select Site: </span>
      <mat-form-field appearance="outline" class="site-select">
        <mat-label>Site</mat-label>
        <mat-select placeholder="Select Site Name" [(value)]="selectedSiteId">
          <mat-option *ngFor="let site of siteList" [value]="site.siteId">
            {{ site.siteName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="modal-footer text-right bttn">
    <button mat-button color="primary" *ngIf="showCancelButton" (click)="cancel()">{{ cancelMessage }}</button>
    <button mat-button color="primary" (click)="confirm()">{{ okMessage }}</button>
  </div>
</div>
