import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetListService } from '../../layout/models/asset/asset-service/asset-list.service';
import { isNullOrUndefined } from '../function-util';
import moment from 'moment';
import { DOCUMENT_CATEGORIES } from '../param-util';

@Component({
  selector: 'app-upload-documentation-modal',
  templateUrl: './upload-documentation-modal.component.html',
  styleUrls: ['./upload-documentation-modal.component.scss']
})
export class UploadDocumentationModalComponent implements OnInit {
  fileCategories = DOCUMENT_CATEGORIES.FILES;
  requestObj: {
    fileCategory: string;
    expiration: string;
    document: any;
  };
  fileAttachment;
  assetId: number;
  todayDate: string;
  assetReceived = true;
  assetsList: { assetId: number; assetName: string }[];
  filteredAssetList: { assetId: number; assetName: string }[];
  isDefaultCategorySelected = false;
  defualtCategory = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { assetId: number; category: string },
    private dialogRef: MatDialogRef<UploadDocumentationModalComponent>,
    private snackBar: MatSnackBar,
    private assetService: AssetListService
  ) {
    if (data.assetId !== -1) {
      this.assetId = data.assetId;
    } else {
      this.assetId = null;
      this.assetReceived = false;
    }
    if (!isNullOrUndefined(data.category) && data.category !== '') {
      this.isDefaultCategorySelected = true;
      this.defualtCategory = this.isDefaultCategorySelected ? this.data.category : null;
    }
  }

  ngOnInit() {
    this.todayDate = moment(new Date()).format('YYYY-MM-DD');
    this.requestObj = {
      fileCategory: this.defualtCategory,
      expiration: this.isDefaultCategorySelected ? this.todayDate : null,
      document: null
    };
    if (!this.assetReceived) {
      this.assetService.getAllAssets('-1').subscribe((res) => {
        this.assetsList = res.map((asset) => ({
          assetId: asset.assetId,
          assetName: asset.assetName
        }));
        this.filteredAssetList = this.assetsList;
      });
    }
  }

  cancel() {
    this.dialogRef.close('N');
  }

  confirm() {
    this.dialogRef.close('Y');
  }

  onFileSelected(event: any) {
    const file = (event.target as any).files;
    if (file.length != 0) {
      this.requestObj.document = file.item(0);
    }
  }

  onSubmit() {
    if (this.validateEntry()) {
      const docFile = new FormData();
      docFile.append('fileCategory', this.requestObj.fileCategory);
      docFile.append('expiration', this.requestObj.expiration);
      docFile.append('assetId', String(this.assetId));
      docFile.append('document', this.requestObj.document);
      this.assetService.uploadAssetDocument(docFile).subscribe((res) => {
        if (!isNullOrUndefined(res)) {
          this.openSnackbar('success', 'File Uploaded Successfully');
          this.confirm();
        } else {
          this.openSnackbar('error', 'Unable to complete Action');
        }
      });
    }
  }

  validateEntry(): boolean {
    if (isNullOrUndefined(this.assetId)) {
      this.openSnackbar('error', 'Please select an Asset');
      return false;
    } else if (isNullOrUndefined(this.requestObj.fileCategory)) {
      this.openSnackbar('error', 'Please select the Document Type');
      return false;
    } else if (isNullOrUndefined(this.requestObj.document)) {
      this.openSnackbar('error', 'Please select the Document to upload');
      return false;
    } else if (isNullOrUndefined(this.requestObj.expiration)) {
      this.openSnackbar('error', 'Please select an expiry date for the document');
      return false;
    }
    return true;
  }

  openSnackbar(type, message) {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      panelClass:
        type == 'info'
          ? ['info-snackbar']
          : type == 'error'
          ? ['error-snackbar']
          : type == 'success'
          ? ['success-snackbar']
          : null
    });
  }
}
