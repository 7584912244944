import { Component } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';
import { COLORS } from '../../param-util';

@Component({
  selector: 'app-crud-action',
  templateUrl: './crud-action.component.html',
  styleUrls: ['./crud-action.component.scss']
})
export class CrudActionComponent extends AgCellRendererBase {
  selectedColor: string;
  colors = COLORS;

  edit(event: any) {
    this.sendEvent(AgCellRendererEvent.EDIT_EVENT, event);
  }

  view(event: any) {
    this.sendEvent(AgCellRendererEvent.VIEW_EVENT, event);
  }

  delete(event: any) {
    this.sendEvent(AgCellRendererEvent.DELETE_EVENT, event);
  }

  active(event: any) {
    this.sendEvent(AgCellRendererEvent.ACTIVE_EVENT, event);
  }

  activeStatus(event: any) {
    this.sendEvent(AgCellRendererEvent.ACTIVE_EVENT, event);
  }

  status(event: any) {
    this.sendEvent(AgCellRendererEvent.STATUS_EVENT, event);
  }

  download(event: any) {
    this.sendEvent(AgCellRendererEvent.DOWNLOAD_EVENT, event);
  }

  location(event: any) {
    this.sendEvent(AgCellRendererEvent.LOCATION, event);
  }

  tripHistory(event: any) {
    this.sendEvent(AgCellRendererEvent.TRIP_HISTORY, event);
  }

  fillColor(event: any): void {
    this.sendEvent(AgCellRendererEvent.FILL_COLOR, event);
  }
}
