import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { monthsWithFullName } from '../param-util';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit {
  @Input() name: string = '';
  @Input() defaultSelected = '';
  @Input() filterMenu: Array<string> = ['ALL', 'WEEK', 'WEEK_RANGE', 'MONTH', 'MONTH_RANGE', 'RANGE'];
  @Output() onDateChanged = new EventEmitter<{}>();

  customForm: FormGroup;
  monthForm: FormGroup;
  weekForm: FormGroup;
  today = new Date();
  year = new Date().getFullYear();
  month = new Date().getMonth() + 1;
  dataInformation: String = '';
  toggle: {
    ALL: boolean;
    DAY: boolean;
    WEEK: boolean;
    MONTH: boolean;
    YEAR: boolean;
    RANGE: boolean;
  } = {
    ALL: false,
    DAY: false,
    WEEK: false,
    MONTH: false,
    YEAR: false,
    RANGE: false
  };

  filterMap: {
    ALL: boolean;
    DAY: boolean;
    WEEK: boolean;
    MONTH: boolean;
    WEEK_RANGE: boolean;
    MONTH_RANGE: boolean;
    YEAR: boolean;
    RANGE: boolean;
  } = {
    ALL: false,
    DAY: false,
    WEEK: false,
    MONTH: false,
    WEEK_RANGE: false,
    MONTH_RANGE: false,
    YEAR: false,
    RANGE: false
  };

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.customForm = this.formBuilder.group({
      fromDate: '',
      toDate: ''
    });
    this.monthForm = this.formBuilder.group({
      monthYear: ''
    });
    this.weekForm = this.formBuilder.group({
      week: ''
    });
  }

  ngOnInit() {
    this.filterMenu.forEach((menu) => {
      this.filterMap[menu] = true;
    });
    this.toggle[this.defaultSelected] = true;
  }

  changeToggle(type: 'ALL' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'RANGE') {
    this.toggle['ALL'] = false;
    this.toggle['DAY'] = false;
    this.toggle['WEEK'] = false;
    this.toggle['MONTH'] = false;
    this.toggle['YEAR'] = false;
    this.toggle['RANGE'] = false;
    this.toggle[type] = true;
  }

  changeDate(type: 'ALL' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR') {
    let dates = {
      fromDate: '',
      toDate: ''
    };

    if (type === 'ALL') {
      dates = this.getAllData();
    } else if (type === 'DAY') {
      dates = this.getDailyData();
    } else if (type === 'WEEK') {
      dates = this.getWeeklyData();
    } else if (type === 'MONTH') {
      dates = this.getMonthlyData();
    } else if (type === 'YEAR') {
      dates = this.getThisYearData();
    }

    let response = {
      type: type,
      fromDate: dates.fromDate,
      toDate: dates.toDate
    };
    this.onDateChanged.emit(response);
  }

  getAllData() {
    this.dataInformation = 'All ' + this.name;
    return { fromDate: '', toDate: '' };
  }

  getDailyData() {
    let fromDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.dataInformation = `${this.name} of ${fromDate}`;
    return { fromDate, toDate: fromDate };
  }

  getThisYearData() {
    let fromDate = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0];
    let toDate = new Date().toISOString().split('T')[0];
    this.dataInformation = `${this.name} Between ${fromDate} and ${toDate}`;
    return { fromDate, toDate };
  }

  getWeeklyData() {
    let fromDate = moment().subtract(6, 'day').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');
    this.dataInformation = `${this.name} Between ${fromDate} and ${toDate}`;
    return { fromDate, toDate };
  }

  getMonthlyData() {
    let fromDate = moment().subtract(29, 'day').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');
    this.dataInformation = `${this.name} Between ${fromDate} and ${toDate}`;
    return { fromDate, toDate };
  }

  changeCustomDate(type: 'WEEK_RANGE' | 'MONTH_RANGE' | 'RANGE', dateValues: any) {
    let dates = {
      fromDate: '',
      toDate: ''
    };

    if (type === 'WEEK_RANGE') {
      dates = this.getCustomWeekData(dateValues);
    } else if (type === 'MONTH_RANGE') {
      dates = this.getCustomMonthData(dateValues);
    } else if (type === 'RANGE') {
      dates = this.getCustomData(dateValues);
    }

    let response = {
      type: type,
      fromDate: dates.fromDate,
      toDate: dates.toDate
    };
    this.onDateChanged.emit(response);
  }

  getCustomData(dateValues) {
    this.dataInformation = `${this.name} Between ${dateValues.fromDate} and ${dateValues.toDate}`;
    return dateValues;
  }

  getCustomMonthData(dateValues) {
    let year = Number(dateValues.monthYear.slice(0, 4));
    let monthIndex = Number(dateValues.monthYear.slice(5, 7)) - 1;
    let fromDate = moment().year(year).month(monthIndex).startOf('month').format('YYYY-MM-DD');
    let toDate = moment().year(year).month(monthIndex).endOf('month').format('YYYY-MM-DD');
    let month = dateValues.monthYear.slice(5, 6) + (Number(dateValues.monthYear.slice(6, 7)) - 1);
    this.dataInformation = `${this.name} - ${monthsWithFullName[month]} ${year}`;
    month.padStart();
    return { fromDate, toDate };
  }

  getCustomWeekData(dateValues) {
    let week = Number(dateValues.week.slice(6, 8));
    let year = Number(dateValues.week.slice(0, 4));
    let fromDate = moment().year(year).day('Monday').isoWeek(week).format('YYYY-MM-DD');
    let toDate = moment().year(year).day('Sunday').isoWeek(week).format('YYYY-MM-DD');
    this.dataInformation = `${this.name} - Week ${week} of ${year}`;
    return { fromDate, toDate };
  }
}
