import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuService } from './menu.service';
import { AuthService } from '../../../auth/auth.service';
import { SVG_ICONS } from 'src/app/shared/param-util';
import { NzIconService } from 'ng-zorro-antd/icon';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isActive: boolean;
  // collapsed: boolean;
  showMenu: string;
  showSubMenu: string;
  pushRightClass: string;
  isToggle: boolean;
  toggleMenu: boolean;
  loggedInUserDetails: any;

  public menuItems: any[];
  protected _menuItemsSub: Subscription;
  protected _onRouteChange: Subscription;

  @Output() collapsedEvent = new EventEmitter<boolean>();
  collapsed: boolean;

  @Input()
  set myCollapseSidebar(value: boolean) {
    this.toggleCollapsed(value);
  }

  constructor(private authService: AuthService, public router: Router, private service: MenuService,
    private iconService: NzIconService) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
    Object.keys(SVG_ICONS).forEach((icon: string) => {
      const namespaceName =
        icon === 'logInfo'
          ? 'log-info'
          : icon === 'userManagement'
          ? 'user-management'
          : icon;
      this.iconService.addIconLiteral(
        `ng-zorro:${namespaceName}`,
        SVG_ICONS[icon]
      );
    });
  }

  @Input()
  logginUserName: string;

  ngOnInit() {
    this.isActive = false;
    // this.collapsed = false;
    this.showMenu = '';
    this.showSubMenu = '';
    this.pushRightClass = 'push-right';
    this.isToggle = false;
    this.toggleMenu = false;
    /* this._onRouteChange = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.menuItems) {
                    this.selectMenuAndNotify();
                } else {
                    // on page load we have to wait as event is fired before menu elements are prepared
                    setTimeout(() => this.selectMenuAndNotify());
                }
            }
        }); */

    this._menuItemsSub = this.service.menuItems.subscribe(this.updateMenu.bind(this));
  }
  getCorrespondingIcon(icon: string): string {
    return `ng-zorro:${icon.toLowerCase()}`;
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }

    if (this.collapsed) {
      this.collapsed = !this.collapsed;
      this.collapsedEvent.emit(this.collapsed);
    }
  }

  addExpandSubClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  toggleCollapsed(val: boolean) {
    this.collapsed = !this.collapsed;
    this.collapsedEvent.emit(this.collapsed);
    this.showMenu = '0';
  }

  selectParentRoutePath(routerPath) {
    this.router.navigate(['pages', routerPath]);
    this.showMenu = '0';
    if (!this.collapsed) {
      this.collapsed = !this.collapsed;
      this.collapsedEvent.emit(this.collapsed);
    }
  }

  selectChildRoutePath(routerPaths) {
    routerPaths = routerPaths.filter((rouPath) => rouPath !== ' ' && rouPath !== '/');
    const routerPath = routerPaths.join('/');

    this.router.navigateByUrl(routerPath);
    this.showMenu = '0';
    this.collapsed = !this.collapsed;
    this.collapsedEvent.emit(this.collapsed);
    this.toggleMenu = false;
    const dom = document.querySelector('.menu');
    dom.classList.remove('opened');
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    if (this.isToggle) {
      this.isToggle = false;
    } else {
      this.isToggle = true;
    }
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify(): void {
    if (this.menuItems) {
      this.menuItems = this.service.selectMenuItem(this.menuItems);
    }
  }

  signOut() {
    this.authService.signOut().subscribe({
      next: (res) => {
        this.router.navigate(['auth', 'login']);
      },
      error: (err) => {
        sessionStorage.clear();
        this.router.navigate(['auth', 'login']);
      }
    });
  }

  gotoProfile() {
    if (!this.collapsed) {
      this.collapsed = !this.collapsed;
      this.collapsedEvent.emit(this.collapsed);
    }
    this.router.navigate(['pages', 'userManagement', 'user-profile']);
  }

  gotoDashboard() {
    this.router.navigate(['pages/dashboard']);
  }

  countBadgeStyle(countBadgeTitle) {
    if (
      countBadgeTitle.toLowerCase() == 'all alerts' ||
      countBadgeTitle.toLowerCase() == 'all cases'
    ) {
      return {
        background: '#2a5c74',
      };
    } else if (countBadgeTitle.toLowerCase() == 'open') {
      return {
        background: '#DC3545',
      };
    } else if (countBadgeTitle.toLowerCase() == 'closed') {
      return {
        background: '#009900',
      };
    } else if (countBadgeTitle.toLowerCase() == 'assigned' || countBadgeTitle.toLowerCase() == 'pending') {
      return {
        background: '#d2a319',
      };
    } else if (countBadgeTitle.toLowerCase() == 'dismissed') {
      return {
        background: '#007BFF',
      };
    } else {
      return { border: null, color: null, background: null };
    }
  }

  toggleSideBar(evnt) {
    this.toggleMenu = !this.toggleMenu;
  }
}
