import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SiteSpecification } from 'src/app/shared/interfaces/site-specification';
import { SiteParameters } from 'src/app/shared/interfaces/site-parameters';

@Injectable({
  providedIn: 'root'
})
export class SiteDetailsService {
  requestApi: String = '/v1/site';

  constructor(private http: HttpClient) {}

  getAllSites(): Observable<any> {
    return this.http.get(this.requestApi + '/getAllSites');
  }

  createSites(sitesData: any): Observable<any> {
    return this.http.post(this.requestApi + '/addUpdateSite', sitesData);
  }

  updateSiteStatus(siteId: any, status: any): Observable<any> {
    return this.http.delete(this.requestApi + '/updateSiteStatusBySiteId/' + siteId + '/' + status);
  }

  getTripPaths(siteId: number): Observable<any> {
    return this.http.get(this.requestApi + `/getTripPaths/${siteId}`);
  }

  setTripPaths(
    siteId: number,
    tripPaths: {
      siteId: number;
      pathName: string;
      sourceGeoFenceId: number;
      destinationGeoFenceId: number;
      imeiList: string[];
    }[]
  ): Observable<any> {
    return this.http.post(this.requestApi + `/setTripPaths/${siteId}`, tripPaths);
  }

  //   createTripPaths(createTripPaths: { pathName: string, siteId: number, sourceGeoFenceId: number, destinationGeoFenceId: number }[]): Observable<any> {
  //       return this.http.post(this.requestApi + '/createTripPaths', createTripPaths);
  //   }

  //   updateTripPaths(updateTripPaths: { tripPathId: number, pathName: string, siteId: number, sourceGeoFenceId: number, destinationGeoFenceId: number }[]): Observable<any> {
  //       return this.http.post(this.requestApi + '/updateTripPaths', updateTripPaths);
  //   }

  // deleteTripPaths(tripPathIds: number[]): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     }),
  //     body: tripPathIds
  //   };
  //   return this.http.delete(this.requestApi + '/deleteTripPaths', options);
  // }

  deleteTripPath(tripPathId: number): Observable<any> {
    return this.http.delete(this.requestApi + `/deleteTripPath/${tripPathId}`);
  }

  addKMZFiles(KMZFileEntry: FormData): Observable<any> {
    return this.http.post(this.requestApi + '/addKmlFiles', KMZFileEntry);
  }

  getKMZFileLinks(): Observable<any> {
    return this.http.get(this.requestApi + '/getKmlFiles');
  }

  updateKMZFilesFields(KMZFileFields: { fileId: number; fileName?: string; status?: string }): Observable<any> {
    return this.http.post(this.requestApi + '/updateKmlFilesFields', KMZFileFields);
  }

  deleteKMZFiles(fileId: number): Observable<any> {
    return this.http.delete(this.requestApi + `/deleteKmlFiles/${fileId}`);
  }

  addSpecificationToSite(updateList: any[]): Observable<any> {
    return this.http.post(this.requestApi + '/addSiteSpecification', updateList);
  }

  getSpecificationBySite(siteId: string): Observable<SiteSpecification[]> {
    return this.http.get<SiteSpecification[]>(this.requestApi + '/getAllSpecificationBySiteId/' + siteId);
  }

  updateTripTargetEnable(data): Observable<any> {
    return this.http.post(this.requestApi + '/updateTripTargetEnable', data);
  }

  isSitesTripCountEnable(siteId: string): Observable<SiteParameters> {
    return this.http.get<SiteParameters>(this.requestApi + '/isSitesTripCountEnable/' + siteId);
  }

  updateScheduleShifts(data): Observable<any> {
    return this.http.post(this.requestApi + '/updateScheduleShifts', data);
  }

  getShiftsScheduleBySite(siteId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getShiftsScheduleBySite/' + siteId);
  }

  getShiftCountBySite(siteId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getShiftCountBySite/' + siteId);
  }

  updateUtilizationHourOfSite(siteHourData): Observable<any> {
    return this.http.post(this.requestApi + '/updateUtilizationHourOfSite', siteHourData);
  }

  getShiftsListBySite(siteId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getShiftsListBySite/' + siteId);
  }

  getSiteRefueledByBarrel(siteId: number): Observable<any> {
    return this.http.get(`${this.requestApi}/getSiteRefueledByBarrel/${siteId}`);
  }

  getSitesByUser(): Observable<any> {
    return this.http.get(this.requestApi + '/getSitesByUser');
  }

  deleteSiteSpecification(specId: number): Observable<any> {
    return this.http.delete(this.requestApi + '/deleteSiteSpecification?specId=' + specId);
  }

  getSiteLocations(siteId: number): Observable<any> {
    return this.http.get(`${this.requestApi}/getSiteLocations/${siteId}`);
  }
}
