<nav
  class="sidebar"
  [ngClass]="{
    sidebarPushRight: isActive,
    collapsed: collapsed,
    isShow: toggleMenu,
    isHide: !toggleMenu
  }">
  <div [ngClass]="{ collapsed: collapsed }" class="product-brand">
    <img src="../../../../assets/images/login-page/cognecto-logo.svg" class="logo" onClick="gotoDashboard()" />
    <span class="product-name">COGNECTO</span>
  </div>
  <div class="list-group">
    <!-- <a routerLink="/dashboard" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-fw fa-dashboard"></i>&nbsp;
            <span>{{ 'Dashboard' | translate }}</span>
        </a> -->

    <div class="nested-menu" *ngFor="let item of menuItems" [ngClass]="item.title">
      <a
        *ngIf="!item.children; else showItems"
        (click)="selectParentRoutePath(item.route.path)"
        [title]="item.title | translate"
        [routerLinkActive]="['router-link-active']"
        class="list-group-item">
        <!--                 <i *ngIf="item.icon && !item.icon.includes('.svg')" class="{{ item.icon }}"
                                    matTooltip="{{item.title}}"></i> -->
        <button
          *ngIf="item.icon && item.icon.includes('.svg')"
          class="mr-2 {{ item.icon.split('.')[0] }}"
          matTooltip="{{ item.title }}"></button>
        <span class="">{{ item.title | translate }}</span>
      </a>
      <ng-template #showItems>
        <a class="list-group-item" (click)="addExpandClass(item.title)">
          <!--<i *ngIf="item.icon && !item.icon.includes('.png')" class="{{ item.icon }}"
                                matTooltip="{{item.title}}"></i> -->
          <button *ngIf="item.icon && item.icon.includes('.svg')" class="mr-2 {{ item.icon.split('.')[0] }}"
            matTooltip="{{ item.title }}"
            matTooltipPosition="right"></button>
          <span>{{ item.title | translate }}</span>
          <span *ngIf="!isToggle">
            <i *ngIf="showMenu !== item.title; else showMenuIcon" class="fa fa-angle-down float-right"></i>
            <ng-template #showMenuIcon> <i class="fa fa-angle-up float-right"></i> </ng-template>
          </span>
        </a>
      </ng-template>
      <li class="nested" [class.expand]="showMenu === item.title">
        <ul class="submenu" *ngFor="let child of item?.children">
          <li>
            <a
              *ngIf="!child.children; else showSubMenus"
              [routerLinkActive]="['router-link-active']"
              (click)="selectChildRoutePath(child.route.paths)">
              <!-- <i *ngIf="item.icon" class="{{ item.icon }}"></i> -->
              <span>{{ child.title | translate }}</span>
              <span class="badge badge-secondary pull-right mr-3" [ngStyle]="countBadgeStyle(child.title)">{{
                child.count
              }}</span>
            </a>
            <ng-template #showSubMenus>
              <a href="javascript:void(0)" [routerLinkActive]="['router-link-active']" (click)="addExpandSubClass(child.title)">
                <i *ngIf="item.icon" class="{{ item.icon }}"></i>
                <span>{{ child.title | translate }}</span>
                <span *ngIf="!isToggle">
                  <i *ngIf="showSubMenu !== child.title; else subMenuIcon" class="fa fa-angle-down float-right pd-r"></i>
                  <ng-template #subMenuIcon> <i class="fa fa-angle-up float-right pd-r"></i> </ng-template>
                </span>
              </a>
            </ng-template>
            <div class="nested" [class.expand1]="showSubMenu === child.title">
              <ul class="submenu1" *ngFor="let child1 of child?.children">
                <li>
                  <a [href]="'#' + child1.route.paths.join('/')" [routerLinkActive]="['router-link-active']">
                    <i *ngIf="item.icon" class="{{ item.icon }}"></i>
                    <span>{{ child1.title | translate }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </div>
  </div>
</nav>

<div class="d-flex isMobile posAbs" id="wrapper">
  <button
    class="menu"
    (click)="toggleSideBar($event)"
    onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
    aria-label="Main Menu">
    <svg height="30" viewBox="0 0 100 100" width="50">
      <path
        class="line line1"
        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
      <path class="line line2" d="M 20,50 H 80"></path>
      <path
        class="line line3"
        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
    </svg>
  </button>
  <!--    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"-->
  <!--            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"-->
  <!--            (click)="toggleSideBar($event)" style="z-index: 9999;">-->
  <!--        <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">menu-->
  <!--        </mat-icon>-->
  <!--    </button>-->
</div>
