import { Component, OnInit, Input } from '@angular/core';
import { openPasscodeDialog } from '../components-function-util';
import { FuelEnergyService } from 'src/app/layout/dashboard/fuel-energy/fuel-energy.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-logged-passcode',
  templateUrl: './logged-passcode.component.html',
  styleUrls: ['./logged-passcode.component.scss']
})
export class LoggedPasscodeComponent implements OnInit {
  @Input() passcodeType: string;

  operatorName = '';
  popConfirmMessage = '';

  constructor(private fuelService: FuelEnergyService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const operatorDetails = JSON.parse(localStorage.getItem('operator'));
    this.operatorName = operatorDetails.name;
    this.popConfirmMessage = `Current operator: ${operatorDetails.name}. Do you want to log out operator?`;
  }

  onClick(): void {
    openPasscodeDialog(this.dialog, this.fuelService, this.passcodeType).then((res: boolean) => {
      if (res) {
        const operatorDetails = JSON.parse(localStorage.getItem('operator'));
        this.operatorName = operatorDetails.name;
        this.popConfirmMessage = `Current operator: ${operatorDetails.name}. Do you want to log out operator?`;
      }
    });
  }
}
