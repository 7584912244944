import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  signIn(loginData: any): Observable<any> {
    return this.http.post(
      `/oauth/token?grant_type=password&username=${loginData.userId}&password=${loginData.password}`,
      null
    );
  }

  signOut() {
    const requestHeaders = {
      headers: new HttpHeaders({
        accessToken: sessionStorage.getItem('accessToken') || '',
        refreshToken: sessionStorage.getItem('refreshToken') || ''
      })
    };

    return this.http.delete(`/removeToken`, requestHeaders).pipe(
      map((res) => {
        sessionStorage.clear();
      })
    );
  }

  getLoggedInUserDetails(): Observable<any> {
    return this.http.get(`/v1/user/getLoggedinUser`);
  }

  verifyUser(userId: any): Observable<any> {
    return this.http.post('/v1/user/verifyUser', userId);
  }

  verifyOtp(otp: string, userName: string): Observable<any> {
    return this.http.post(`/v1/user/verifyOtp/${otp}`, null, {
      params: { username: userName }
    });
  }

  resetUserPassword(password: string, userName: string): Observable<any> {
    return this.http.post(`/v1/user/resetUserPassword/${userName}`, null, {
      params: { password: password }
    });
  }
}
