import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetClassService {
  requestApi: string = '/v1/assetClass';

  constructor(private http: HttpClient) {}

  getAllAssetClass(): Observable<any> {
    return this.http.get(this.requestApi + '/getAllAssetClass');
  }

  deleteAssetClass(assetClassId: any): Observable<any> {
    return this.http.delete(this.requestApi + '/deleteAssetClassById/' + assetClassId);
  }

  addUpdateAssetClass(assetClass: any): Observable<any> {
    return this.http.post(this.requestApi + '/addUpdateAssetClass', assetClass);
  }

  getAllSensorTag(): Observable<any> {
    return this.http.get('/v1/sensorTag/getAllSensorTag');
  }

  getAssetClassById(assetClassId: String): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetClassById/' + assetClassId);
  }

  getAssetClassDescById(): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetClassDescById');
  }
}
