import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterType } from '../enum-util';
import { isNullOrUndefined } from '../function-util';
import { FilterModalOption } from './filter-modal-option';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent {
  @Input() filterList: FilterModalOption[] = [];
  @Input() class: string = '';
  @Input() style: { [key: string]: string } = {
    padding: '0.3rem',
    border: '1px black solid'
  };
  @Output() onResetFilter = new EventEmitter();
  @Output() onFilterApplied = new EventEmitter<{}>();

  isPopupVisible: boolean = false;
  filteredElement: {
    filterName: string;
    filteredOptions: {
      name: string;
      checked: boolean;
    }[];
  }[] = [];
  filterType = FilterType;
  radioFilteredElement: string;

  applyFilter(): void {
    this.onFilterApply();
    this.isPopupVisible = false;
    this.onFilterApplied.emit(this.filteredElement);
  }

  resetFilter(): void {
    this.radioFilteredElement = null;
    this.filterList.forEach((filter) => filter.filterOptions.map((option) => (option.checked = false)));
    this.isPopupVisible = false;
    this.onResetFilter.emit();
  }

  onFilterApply(): void {
    this.filteredElement = [];
    this.filterList.forEach((filter) => {
      if (filter.filterType === this.filterType.radio && !isNullOrUndefined(this.radioFilteredElement)) {
        this.filteredElement.push({
          filterName: filter.filterName,
          filteredOptions: [
            {
              name: this.radioFilteredElement,
              checked: true
            }
          ]
        });
      } else if (filter.filterType === this.filterType.checkbox) {
        const filteredOptions = filter.filterOptions.filter((option) => option.checked);
        if (filteredOptions.length > 0) {
          this.filteredElement.push({
            filterName: filter.filterName,
            filteredOptions: filteredOptions
          });
        }
      }
    });
  }
}
