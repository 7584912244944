<div class="mat-card chart-report-card">
  <mat-card-title style="margin: 0px !important">
    <div class="heading">
      <img class="header-icon" [src]="generalData?.headerIcon" />
      <div class="header-name">
        <h6 class="main-header">{{ header }}</h6>
        <h6 class="sub-header">{{ subHeader }}</h6>
      </div>
    </div>
  </mat-card-title>
  <div class="fuel-card">
    <div class="report-analytics">
      <div class="analytic">
        <div class="comparison-data">
          <span class="yesterday-data">{{ generalData?.chartData?.yestValue }}</span>
          <img src="./../../../../assets/icons/arrow_down.svg" *ngIf="!yestVMonthChangeTypeIsPositive" />
          <img src="./../../../../assets/icons/Arrow 1.svg" *ngIf="yestVMonthChangeTypeIsPositive" />
          <span [ngStyle]="{ color: yestVMonthChangeTypeIsPositive ? 'green' : 'red' }"
            >{{ generalData?.chartData?.yestVMonth }} %</span
          >
        </div>
        <div class="comparison-period">
          <span>(Yesterday Vs Current month)</span>
        </div>
      </div>
      <div class="graph" (click)="openScatterChart()">
        <div id="{{ chartId }}"></div>
      </div>
      <div class="one-day-compare">
        <div class="current-month-analytic">
          <span>Current Month</span>
          <span class="current-month-data">{{ generalData?.chartData?.currMonthValue }}</span>
        </div>
        <div class="ytd-analytic">
          <span>YTD</span>
          <span class="ytd-data">{{ generalData?.chartData?.yearToDateAvg }}</span>
        </div>
      </div>
    </div>
    <div class="report">
      <ag-grid-angular
        [defaultColDef]="defaultColDef"
        [rowData]="reportData"
        [columnDefs]="columnDefs"
        [rowHeight]="30"
        [headerHeight]="35"
        [context]="context"
        style="height: 400px !important"
        [suppressCellSelection]="true"
        [enableBrowserTooltips]="true"
        class="ag-theme-balham"
        [frameworkComponents]="frameworkComponents"
        [suppressRowClickSelection]="true"
        (gridReady)="onGridReady($event)"
        [getRowStyle]="getRowStyle">
      </ag-grid-angular>
    </div>
  </div>
</div>
