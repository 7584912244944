<button
  type="button"
  class="btn btn-outline-secondary nav-button quick-icon-btn"
  matTooltip="Quick Access"
  nz-popover
  nzPopoverTitle=""
  nzPopoverTrigger="hover"
  [nzPopoverContent]="quickAccessTemplate"
  nzPopoverPlacement="bottomRight"
  [(nzPopoverVisible)]="isPopupVisible"
  nzOverlayClassName="quick-access-popover-overlay">
  <svg focusable="false" viewBox="0 0 24 24">
    <path
      d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
  </svg>
</button>

<ng-template style="border: solid 1px" #quickAccessTemplate class="quick-access-template">
  <div class="quick-access-content">
    <div class="quick-access-items">
      <ng-container class="" *ngFor="let item of quickAccessList">
        <div class="quick-box" (click)="getSelectedQuickAccess(item)">
          <div class="icon">
            <img src="./../../../../../assets/icons/quick-access-icons/{{ item.image }}" alt="item.name" />
          </div>
          <div class="title">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
