import { Component, OnInit, ElementRef, HostListener, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { MenuService } from './components/sidebar/menu.service';
import { LayoutService } from './layout.service';
import { Routes } from '@angular/router';
import jQuery from 'jquery';
import { DashboardService } from './dashboard/dashboard.service';
import { GlobalHeaderSiteService } from '../core/services/global-header-site.service';
import { AuthService } from '../auth/auth.service';
import { LoggedInUserDetailService } from '../core/services/loggedIn-user-detail-service';
import { CLIENT_NAME, COGNECTO, SUPER_USER, USER_NAME } from '../shared/param-util';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  collapedSideBar: boolean;
  currentYear = new Date().getFullYear();

  menuItems: any[] = [];

  constructor(
    private _menuService: MenuService,
    private menuService: LayoutService,
    private loggedInUserDetService: LoggedInUserDetailService,
    private dashboardService: DashboardService,
    private globalSiteService: GlobalHeaderSiteService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  globalSiteId: string;
  logginUserName: string;
  logginUserClientName: string;
  isLoading: boolean;
  ngOnInit() {
    this.globalSiteService.headerSite.subscribe((globalSiteId) => {
      this.globalSiteId = globalSiteId;
      if (globalSiteId == '') {
        if (this.menuItems.length == 0) {
          this.menuService.getAllMenu().subscribe((menus) => {
            this.menuItems.push(menus);
            this._menuService.updateMenuByRoutes(<Routes>this.menuItems);
          });
        }
      } else {
        this.authService.getLoggedInUserDetails().subscribe((user) => {
          this.loggedInUserDetService.loggedInUserDetails.next(user);

          this.logginUserName = user.userName;
          if (user.client != null) {
            this.logginUserClientName =
              user.client.displayName != null ? user.client.displayName : user.client.clientName;
          }
          sessionStorage.setItem(CLIENT_NAME, this.logginUserClientName);
          sessionStorage.setItem(USER_NAME, user.firstName + ' ' + user.lastName);

          var isSuperUser = user.roles.filter((role) => role.roleName == 'ROLE_SUPER_USER');

          if (isSuperUser.length != 0) {
            this.logginUserClientName = COGNECTO;
            sessionStorage.setItem(USER_NAME, SUPER_USER);
          }
          this.setAlertAndCaseCount(this.menuItems[0]);
        });
      }
    });
  }

  onActivate() {
    window.scroll(0, 0);
  }

  setAlertAndCaseCount(menus: any) {
    this.dashboardService.getAllAlertCount(this.globalSiteId).subscribe((alertResponse) => {
      menus.children.forEach((menu) => {
        if (menu.data && menu.data.menu.title == 'Alerts') {
          menu.children.forEach((alertMenu) => {
            if (alertMenu.data.menu.title == 'All Alerts')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.totalAlertCount ? alertResponse.totalAlertCount : 0;
            else if (alertMenu.data.menu.title == 'Open')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.openAlertCount ? alertResponse.openAlertCount : 0;
            else if (alertMenu.data.menu.title == 'Closed')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.closedAlertCount ? alertResponse.closedAlertCount : 0;
            else if (alertMenu.data.menu.title == 'Assigned')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.assignedAlertCount ? alertResponse.assignedAlertCount : 0;
            else if (alertMenu.data.menu.title == 'Dismissed')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.dismissedAlertCount ? alertResponse.dismissedAlertCount : 0;
            else if (alertMenu.data.menu.title == 'Pending')
              alertMenu.data.menu.count =
                alertResponse && alertResponse.pendingAlertCount ? alertResponse.pendingAlertCount : 0;
          });
        }
      });

      this.dashboardService.getAllCaseCount(this.globalSiteId).subscribe((caseResponse) => {
        menus.children.forEach((menu) => {
          if (menu.data && menu.data.menu.title == 'Cases') {
            menu.children.forEach((caseMenu) => {
              if (caseMenu.data.menu.title == 'All Cases')
                caseMenu.data.menu.count =
                  caseResponse && caseResponse.totalCaseCount ? caseResponse.totalCaseCount : 0;
              else if (caseMenu.data.menu.title == 'Open')
                caseMenu.data.menu.count = caseResponse && caseResponse.openCaseCount ? caseResponse.openCaseCount : 0;
              else if (caseMenu.data.menu.title == 'Closed')
                caseMenu.data.menu.count =
                  caseResponse && caseResponse.resolvedCaseCount ? caseResponse.resolvedCaseCount : 0;
              else if (caseMenu.data.menu.title == 'Pending')
                caseMenu.data.menu.count =
                  caseResponse && caseResponse.pendingCaseCount ? caseResponse.pendingCaseCount : 0;
            });
          }
        });

        if (this.menuItems.length !== 0) this.menuItems = [];

        this.menuItems.push(menus);
        this._menuService.updateMenuByRoutes(<Routes>this.menuItems);
      });
    });
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  myCollapse = false;
  receiveCollapsedHeader() {
    this.myCollapse = !this.myCollapse;
  }

  closeNav() {
    if (!this.collapedSideBar) this.myCollapse = !this.myCollapse;
  }

  // Logic for back to Top transition button
  @Input() position: number = 400;
  @Input() showSpeed: number = 500;
  @Input() moveSpeed: number = 1000;

  el: any;

  @ViewChild('baBackTop', { static: false }) _selector: ElementRef;

  ngAfterViewInit() {
    this.el = this._selector.nativeElement;
    this._onWindowScroll();
  }

  onClick(): boolean {
    jQuery('html, body').animate({ scrollTop: 0 }, { duration: this.moveSpeed });
    return false;
  }

  @HostListener('window:scroll')
  _onWindowScroll(): void {
    window.scrollY > this.position ? jQuery(this.el).fadeIn(this.showSpeed) : jQuery(this.el).fadeOut(this.showSpeed);
  }
}
