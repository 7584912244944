<mat-card class="analytics-card">
  <mat-card-content>
    <div class="analytics-container">
      <div class="container-header">
        <img src="./../../../../assets/icons/overview-icons/analytics.svg" />
        <p class="heading">{{analyticModalHeading}}</p>
      </div>
      <div class="container-subheading" *ngIf="analyticModalType==='ANALYTICS' || (analyticModalType==='PTA' && assetList.length > 0)">
        <p class="body-font">
          <span style="color: #000">{{analyticModalDesc}}</span>
          <nz-select
            class="overview-stats-select"
            [(ngModel)]="selectedMonth"
            (ngModelChange)="onAnalyticsMonthChange()">
            <nz-option
              [nzLabel]="month.monthName"
              [nzValue]="month.monthIndex"
              *ngFor="let month of monthList"></nz-option>
          </nz-select>
        </p>
        <button class="body-font" (click)="openAddStats()"> {{statsButtonText}} </button>
      </div>
      <div class="flex-parent">
        <ng-container *ngFor="let chart of analyticsArray">
          <app-analytic-card
            *ngIf="chart.enabled"
            [title]="chart.title"
            [chartId]="chart.chartId"
            [values]="chart.values"
            [selectedMonth]="selectedMonth">
          </app-analytic-card>
        </ng-container>
        <div class="no-data-found" *ngIf="assetList.length > 0 && analyticsArray.length === 0">
          <P>No Data Found for <strong>{{selectedAsset?.assetName}}</strong></P>
        </div>
        <div class="no-data-found" *ngIf="assetList.length === 0">
          <P>No Data Found</P>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>