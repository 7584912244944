<span
  type="button"
  [ngStyle]="style"
  [class]="class"
  matTooltip="Filter"
  nz-popover
  nzPopoverTitle=""
  nzPopoverTrigger="click"
  [nzPopoverContent]="filterModalTemplate"
  nzPopoverPlacement="bottomRight"
  nzVisible="isPopupVisible"
  nzPopoverOverlayClassName="filter-modal-popover-overlay">
  <i class="fa fa-filter"></i>
</span>

<ng-template style="border: solid 1px" #filterModalTemplate class="filter-modal-template">
  <div class="filter-modal-content">
    <div class="filter-header">
      <div class="filter-text">Filters</div>
      <div class="filter-action">
        <div class="filter-reset" (click)="resetFilter()">Clear all</div>
        <div class="filter-apply" (click)="applyFilter()">Apply</div>
      </div>
    </div>
    <hr>
    <div class="filter-modal-items">
      <mat-expansion-panel *ngFor="let option of filterList" class="option-card mat-elevation-z0">
        <mat-expansion-panel-header class="filter-modal-expansion-header">
          <mat-panel-title>
            <div class="filter-option-name">
              <div class="option-name">
                <span>{{ option.filterName }}</span>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-filter-modal-body">
          <div class="filter-sub-list">
            <div *ngIf="option.filterType === filterType.radio">
              <mat-radio-group [(ngModel)]="radioFilteredElement" class="item filter-sub-option">
                <mat-radio-button [value]="item.name" *ngFor="let item of option.filterOptions">
                  {{ item.name }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="item filter-sub-option" *ngIf="option.filterType === filterType.checkbox">
              <div *ngFor="let item of option.filterOptions">
                <mat-checkbox [(ngModel)]="item.checked" [id]="item.name">{{ item.name }} </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</ng-template>
