import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FleetService {
  fleetApiPrefixUrl: String = '/v1/fleet/';

  constructor(private http: HttpClient) {}

  getAssetsByStatus(siteId: String): Observable<any> {
    return this.http.get(this.fleetApiPrefixUrl + 'getAssetInfoByStatus/' + siteId);
  }

  getAssetStatusTrend(siteId: String): Observable<any> {
    return this.http.get(this.fleetApiPrefixUrl + 'getAssetStatusTrend/' + siteId);
  }

  getHourRunByAssetClass(siteId: String, assetClassNameList: Array<any>, fetchType: string): Observable<any> {
    return this.http.get(
      this.fleetApiPrefixUrl + 'getHourRunByAssetClass/' + siteId + '/' + assetClassNameList + '/' + fetchType
    );
  }

  getTripCountProductionByAssetClass(
    siteId: String,
    assetClassName: string,
    tripCategory: string,
    fetchType: string
  ): Observable<any> {
    return this.http.get(
      this.fleetApiPrefixUrl +
        'getTripCountProductionByAssetClass/' +
        siteId +
        '/' +
        assetClassName +
        '/' +
        tripCategory +
        '/' +
        fetchType
    );
  }

  getTripProductionScatterTrendByAssetClassAndCategory(
    siteId: String,
    assetClassName: string,
    tripCategory: string,
    fetchType: string
  ): Observable<any> {
    return this.http.get(
      this.fleetApiPrefixUrl +
        'getTripProductionScatterTrendByAssetClassAndCategory/' +
        siteId +
        '/' +
        assetClassName +
        '/' +
        tripCategory +
        '/' +
        fetchType
    );
  }

  getAssetCountByPreStartStatus(siteId: number, date: string, shiftSequence: string): Observable<any> {
    return this.http.get('/v1/logInfo/getAssetCountByPreStartStatus/' + siteId + '/' + date + '/' + shiftSequence);
  }

  getSiteCountByPreStartStatus(siteId: number, date: string, shiftSequence: string): Observable<any> {
    return this.http.get('/v1/logInfo/getSiteCountByPreStartStatus/' + siteId + '/' + date + '/' + shiftSequence);
  }

  getAssetClassCountByPreStartStatus(siteId: number, date: string, shiftSequence: string): Observable<any> {
    return this.http.get('/v1/logInfo/getAssetClassCountByPreStartStatus/' + siteId + '/' + date + '/' + shiftSequence);
  }

  getPreStartStatusCountBySite(siteId: number, date: string, shiftSequence: string): Observable<any> {
    return this.http.get('/v1/logInfo/getPreStartStatusCountBySite/' + siteId + '/' + date + '/' + shiftSequence);
  }

  getPreStartStatusCountByAssetClass(siteId: number, date: string, shiftSequence: string): Observable<any> {
    return this.http.get('/v1/logInfo/getPreStartStatusCountByAssetClass/' + siteId + '/' + date + '/' + shiftSequence);
  }

  getAssetInfoForPreCheckupCharts(
    siteId: number,
    date: string,
    shiftSequence: string,
    assetClassName: string
  ): Observable<any> {
    return this.http.get(
      '/v1/logInfo/getAssetInfoForPreCheckupCharts/' +
        siteId +
        '/' +
        date +
        '/' +
        shiftSequence +
        '?assetClass=' +
        assetClassName
    );
  }
}
