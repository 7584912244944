<div class="custom-filter">
  <div class="all-filter">
    <div class="filter-btn" role="group" aria-label="Basic example" data-toggle="buttons">
      <label
        *ngIf="filterMap.ALL"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.ALL"
        (click)="changeDate('ALL'); changeToggle('ALL')">
        <input type="radio" value="weekly" id="option0" autocomplete="off" checked />All
      </label>
      <label
        *ngIf="filterMap.DAY"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.DAY"
        (click)="changeDate('DAY'); changeToggle('DAY')">
        <input type="radio" value="daily" id="option1" autocomplete="off" />1 D
      </label>
      <label
        *ngIf="filterMap.WEEK"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.WEEK"
        (click)="changeDate('WEEK'); changeToggle('WEEK')">
        <input type="radio" value="weekly" id="option2" autocomplete="off" />1 W
      </label>
      <label
        *ngIf="filterMap.MONTH"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.MONTH"
        (click)="changeDate('MONTH'); changeToggle('MONTH')">
        <input type="radio" name="monthly" id="option3" autocomplete="off" />1 M
      </label>
      <label
        *ngIf="filterMap.YEAR"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.YEAR"
        (click)="changeDate('YEAR'); changeToggle('YEAR')">
        <input type="radio" name="year" id="option4" autocomplete="off" />This Year
      </label>
      <label
        *ngIf="filterMap.RANGE"
        class="btn btn-outline-secondary dateBtn"
        [class.active]="toggle.RANGE"
        (click)="changeToggle('RANGE')">
        <input type="radio" name="custom" id="option5" autocomplete="off" />Custom
      </label>
    </div>

    <form
      *ngIf="toggle.RANGE"
      [formGroup]="customForm"
      (ngSubmit)="changeCustomDate('RANGE', customForm.value)"
      class="range-form">
      <div class="date-form">
        <label class="range-label" for="fromDate">From Date</label>
        <input id="fromDate" type="date" class="form-control mx-sm-3" formControlName="fromDate" />
      </div>

      <div class="date-form">
        <label class="range-label" for="toDate">To Date</label>
        <input
          id="toDate"
          type="date"
          class="form-control mx-sm-3"
          formControlName="toDate"
          (change)="changeCustomDate('RANGE', customForm.value)" />
      </div>
    </form>

    <form
      *ngIf="toggle.MONTH && filterMap.MONTH_RANGE"
      [formGroup]="monthForm"
      (ngSubmit)="changeCustomDate('MONTH_RANGE', monthForm.value)"
      class="range-form">
      <div class="date-form">
        <label class="range-label" for="monthYear">Select Month and Year</label>
        <input
          id="monthYear"
          type="month"
          class="form-control mx-sm-3"
          formControlName="monthYear"
          (change)="changeCustomDate('MONTH_RANGE', monthForm.value)" />
      </div>
    </form>

    <form
      *ngIf="toggle.WEEK && filterMap.WEEK_RANGE"
      [formGroup]="weekForm"
      (ngSubmit)="changeCustomDate('WEEK_RANGE', weekForm.value)"
      class="range-form">
      <div class="date-form">
        <label class="range-label" for="week">Select Week</label>
        <input
          id="week"
          type="week"
          class="form-control mx-sm-3"
          formControlName="week"
          (change)="changeCustomDate('WEEK_RANGE', weekForm.value)" />
      </div>
    </form>
  </div>

  <div class="d-flex isHide">
    <mat-hint align="start" class="mat-hint-primary alert-date-info">{{ dataInformation }}</mat-hint>
  </div>
</div>
