import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { CodeInputModule } from 'angular-code-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { QRCodeModule } from 'angularx-qrcode';
import { MaterialModule } from './material.module';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { en_GB, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { RenderHighstockComponent } from './render-highstock/render-highstock.component';
import { RenderHighchartComponent } from './render-highchart/render-highchart.component';
import { AddStatsModalComponent } from './add-stats-modal/add-stats-modal.component';
import { AnalyticsModalComponent } from './analytics-modal/analytics-modal.component';
import { ReviewAnalyticModalComponent } from './analytics-modal/review-analytic-modal/review-analytic-modal.component';
import { AnalyticCardComponent } from './analytics-modal/analytic-card/analytic-card.component';
import { ChartReportCardComponent } from './chart-report-card/chart-report-card.component';
import { ScatterChartModalComponent } from './chart-report-card/scatter-chart-modal/scatter-chart-modal.component';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { CommonPasscodeModalComponent } from './common-passcode-modal/common-passcode-modal.component';
import { CreateAssetByUserComponent } from './create-asset-by-user/create-asset-by-user.component';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { DailyMonthlyConnectedChartComponent } from './daily-monthly-connected-chart/daily-monthly-connected-chart.component';
import { OcrOperationComponent } from './ocr-operation/ocr-operation.component';
import { QrCodeOperationComponent } from './qr-code-operation/qr-code-operation.component';
import { UploadDocumentationModalComponent } from './upload-documentation-modal/upload-documentation-modal.component';
import { CamelToTitleCasePipe } from './cameltotitlecase.pipe';

import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';

import { AssetIntervalInfoRendererComponent } from './renderers/asset-interval-info-renderer/asset-interval-info-renderer.component';
import { BarCellRendererComponent } from './renderers/bar-cell-renderer/bar-cell-renderer.component';
import { ButtonRendererComponent } from './renderers/button-renderer/button-renderer.component';
import { CellEditRendererComponent } from './renderers/cell-edit-renderer/cell-edit-renderer.component';
import { CellPopoverComponent } from './renderers/cell-popover/cell-popover.component';
import { CellUserPopoverComponent } from './renderers/cell-user-popover/cell-user-popover.component';
import { CountRendererComponent } from './renderers/count-renderer/count-renderer.component';
import { CrudActionComponent } from './renderers/crud-action/crud-action.component';
import { DateCellEditorComponent } from './renderers/date-cell-editor/date-cell-editor.component';
import { DropdownCellEditorComponent } from './renderers/dropdown-cell-editor/dropdown-cell-editor.component';
import { EditActionRendererComponent } from './renderers/edit-action-renderer/edit-action-renderer.component';
import { LinkRendererComponent } from './renderers/link-renderer/link-renderer.component';
import { LinkSiteRenderComponent } from './renderers/link-site-render/link-site-render.component';
import { RedirectRendererComponent } from './renderers/redirect-renderer/redirect-renderer.component';
import { SparklineCellRenderersComponent } from './renderers/sparkline-cell-renderers/sparkline-cell-renderers.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { CommonMapComponent } from './common-map/common-map.component';
import { DashboardBtnComponent } from './dashboard-btn/dashboard-btn.component';
import { RadioGroupModalComponent } from './radio-group-modal/radio-group-modal.component';
import { IconsProviderModule } from './ng-zorro-icons.module';
import { ModalCloseBtnComponent } from './modal-close-btn/modal-close-btn.component';
import { ServiceIconCellRenderComponent } from './renderers/service-icon-cell-render/service-icon-cell-render.component';
import { OperatorReportBtnComponent } from './operator-report-btns/operator-report-btn.component';
import { HighstockModalComponent } from './render-highstock/highstock-modal/highstock-modal.component';
import { LoggedPasscodeComponent } from './logged-passcode/logged-passcode.component';
import { ExcelExportComponent } from './excel-export/excel-export.component';
import { DateTimeCellEditorComponent } from './renderers/date-time-cell-editor/date-time-cell-editor.component';
import { StepCardComponent } from './step-card/step-card.component';
import { StepListComponent } from './step-list/step-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    MaterialModule,
    NgZorroAntdModule,
    NzOutletModule,
    NgbModule,
    CodeInputModule,
    HighchartsChartModule,
    NgxMatIntlTelInputComponent,
    QRCodeModule,
    NgxScannerQrcodeModule,
    IconsProviderModule
  ],
  declarations: [
    RenderHighstockComponent,
    RenderHighchartComponent,
    AddStatsModalComponent,
    AnalyticsModalComponent,
    ReviewAnalyticModalComponent,
    AnalyticCardComponent,
    ChartReportCardComponent,
    ScatterChartModalComponent,
    CommonModalComponent,
    CommonDialogComponent,
    CommonPasscodeModalComponent,
    CreateAssetByUserComponent,
    CustomFilterComponent,
    DailyMonthlyConnectedChartComponent,
    OcrOperationComponent,
    QrCodeOperationComponent,
    UploadDocumentationModalComponent,
    CamelToTitleCasePipe,
    AssetIntervalInfoRendererComponent,
    BarCellRendererComponent,
    ButtonRendererComponent,
    CellEditRendererComponent,
    CellPopoverComponent,
    CellUserPopoverComponent,
    CountRendererComponent,
    CrudActionComponent,
    DateCellEditorComponent,
    DropdownCellEditorComponent,
    EditActionRendererComponent,
    LinkRendererComponent,
    LinkSiteRenderComponent,
    RedirectRendererComponent,
    SparklineCellRenderersComponent,
    FilterModalComponent,
    DropdownFilterComponent,
    CommonMapComponent,
    DashboardBtnComponent,
    RadioGroupModalComponent,
    ModalCloseBtnComponent,
    ServiceIconCellRenderComponent,
    OperatorReportBtnComponent,
    HighstockModalComponent,
    LoggedPasscodeComponent,
    ExcelExportComponent,
    DateTimeCellEditorComponent,
    StepCardComponent,
    StepListComponent
  ],
  entryComponents: [
    AddStatsModalComponent,
    ReviewAnalyticModalComponent,
    AnalyticCardComponent,
    ScatterChartModalComponent,
    CommonModalComponent,
    CommonDialogComponent,
    CommonPasscodeModalComponent,
    CreateAssetByUserComponent,
    CustomFilterComponent,
    OcrOperationComponent,
    QrCodeOperationComponent,
    UploadDocumentationModalComponent,
    BarCellRendererComponent,
    ButtonRendererComponent,
    CellEditRendererComponent,
    CellPopoverComponent,
    CellUserPopoverComponent,
    CountRendererComponent,
    CrudActionComponent,
    DateCellEditorComponent,
    DropdownCellEditorComponent,
    EditActionRendererComponent,
    LinkRendererComponent,
    LinkSiteRenderComponent,
    RedirectRendererComponent,
    SparklineCellRenderersComponent,
    FilterModalComponent,
    DropdownFilterComponent,
    CommonMapComponent,
    RadioGroupModalComponent,
    ServiceIconCellRenderComponent,
    ExcelExportComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    MaterialModule,
    NgZorroAntdModule,
    NgbModule,
    AddStatsModalComponent,
    AnalyticsModalComponent,
    AnalyticCardComponent,
    ChartReportCardComponent,
    CommonModalComponent,
    DailyMonthlyConnectedChartComponent,
    OcrOperationComponent,
    QrCodeOperationComponent,
    CamelToTitleCasePipe,
    BarCellRendererComponent,
    ButtonRendererComponent,
    CellEditRendererComponent,
    CellPopoverComponent,
    CellUserPopoverComponent,
    CrudActionComponent,
    DateCellEditorComponent,
    DropdownCellEditorComponent,
    EditActionRendererComponent,
    LinkRendererComponent,
    LinkSiteRenderComponent,
    SparklineCellRenderersComponent,
    CustomFilterComponent,
    NgxMatIntlTelInputComponent,
    RenderHighchartComponent,
    RenderHighstockComponent,
    FilterModalComponent,
    DropdownFilterComponent,
    CommonMapComponent,
    DashboardBtnComponent,
    RadioGroupModalComponent,
    QRCodeModule,
    IconsProviderModule,
    ModalCloseBtnComponent,
    OperatorReportBtnComponent,
    ServiceIconCellRenderComponent,
    LoggedPasscodeComponent,
    ExcelExportComponent,
    StepCardComponent,
    StepListComponent
  ],
  providers: [{ provide: NZ_I18N, useValue: en_GB }]
})
export class SharedModule {}
