<div class="modal-header">
  <h4 class="modal-title">{{ chartInfoDetailParams.modalHeader }}</h4>
  <app-modal-close-btn class="close-icon" btnAlignment="top-1" btnSize="larger" [closeByMethod]=true (closeDialog)="closeModalForDashboardCharts()">
  </app-modal-close-btn>
</div>
<div class="modal-content chart-details">
  <!--   <table class="ui compact table">
    <thead>
        <tr>
            <th>Asset Name</th>
            <th>Site</th>
            <th>
              {{
                columnTitles[chartInfoDetailParams.parameterColumn] ?
                      columnTitles[chartInfoDetailParams.parameterColumn]
                      : "columnTitles-mapping-needed"
              }}
            </th>
            <th>Select</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let asset of chartInfoDetailParams.assetList">
            <td>{{ asset.assetName }}</td>
            <td>{{ asset.siteName }}</td>
            <td>{{
                    (chartInfoDetailParams.parameterColumn === "" || chartInfoDetailParams.parameterColumn === undefined) ? ""
                      : isString(asset[chartInfoDetailParams.parameterColumn]) ? asset[chartInfoDetailParams.parameterColumn]
                      : asset[chartInfoDetailParams.parameterColumn] % 1 === 0 ? asset[chartInfoDetailParams.parameterColumn]
                      : asset[chartInfoDetailParams.parameterColumn].toFixed(2)
                }}
            </td>
            <td>
              <mat-radio-button (change)="clickedAsset(asset)"></mat-radio-button>
            </td>
        </tr>
    </tbody>
  </table> -->

  <div class="commonAgGrid">
    <ag-grid-angular
      [defaultColDef]="defaultColDef"
      #grid
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      class="ag-theme-material"
      [rowHeight]="30"
      [headerHeight]="35"
      [context]="context"
      style="width: 100%; height: calc(100vh - 500px)"
      [suppressCellSelection]="false"
      class="ag-theme-balham"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="'single'"
      [suppressRowClickSelection]="false"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
<mat-hint align="end" class="mat-hint-danger" *ngIf="errorMessage.length != 0">
  {{ errorMessage }}
</mat-hint>
<div class="d-flex chart-detail-footer">
  <div class="d-flex icon-buttons">
    <img
      src="../../../../assets/icons/header-bar-icons/map.svg"
      (click)="navigate('map')"
      ngbTooltip="Map"
      class="navigate-icon" />
    <img
      src="../../../../assets/icons/header-bar-icons/trend-analysis.svg"
      (click)="navigate('trend-analysis')"
      ngbTooltip="Trend Analysis"
      class="navigate-icon" />
    <img
      src="../../../../assets/icons/header-bar-icons/alert-notification.svg"
      (click)="navigate('alerts')"
      ngbTooltip="Alerts"
      class="navigate-icon" />
    <img
      src="../../../../assets/icons/cases_black.svg"
      (click)="navigate('cases')"
      ngbTooltip="Cases"
      class="navigate-icon" />
    <img
      src="../../../../assets/icons/configurator_black.svg"
      (click)="navigate('configurator')"
      ngbTooltip="Configurator"
      class="navigate-icon" />
  </div>
  <div class="d-flex close-button">
    <button mat-button color="primary" color="primary" (click)="closeModalForDashboardCharts()"> Close </button>
  </div>
</div>
