import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteDetailsService } from '../../layout/setup/sites/site-service/site-detail.service';
import { isNullOrUndefined } from '../function-util';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  showCancelButton = true;
  modalContent: string;
  okMessage = 'Ok';
  cancelMessage = 'Cancel';
  showInputText = true;
  placeholderContent = '';
  inputContent = '';
  selectedSiteId: number;
  siteList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    private siteService: SiteDetailsService
  ) {}

  ngOnInit() {
    this.siteService.getAllSites().subscribe((res) => {
      this.siteList = res;
    });
    this.modalContent = this.data.modalContent;
    this.placeholderContent = this.data.placeholderContent;
    this.showCancelButton = this.data.showCancelButton;
    this.okMessage = this.data.okMessage;
  }

  cancel() {
    this.dialogRef.close('N');
  }

  confirm() {
    if (!isNullOrUndefined(this.inputContent) && this.inputContent !== '' && !isNullOrUndefined(this.selectedSiteId)) {
      const response = {
        entityName: this.inputContent,
        siteId: this.selectedSiteId
      };
      this.dialogRef.close(response);
    }
  }
}
