import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SiteDetailsService } from '../../setup/sites/site-service/site-detail.service';
import { FormControl } from '@angular/forms';
import { GlobalHeaderSiteService } from 'src/app/core/services/global-header-site.service';
import { dashboardList, NEW_TAB_REDIRECTION, USER_NAME } from '../../../shared/param-util';
import { isNullOrUndefined } from 'src/app/shared/function-util';
import $ from 'jquery';
import { NotificationDetailsService } from '../notification-details.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssetListService } from '../../models/asset/asset-service/asset-list.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QrCodeOperationComponent } from 'src/app/shared/qr-code-operation/qr-code-operation.component';
import { DashboardModuleHeader } from 'src/app/shared/enum-util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  showMenu = false;
  showNotification = false;
  allAssets = [];
  options = {
    minimum: 0.08,
    maximum: 1,
    ease: 'linear',
    speed: 1200,
    trickleSpeed: 1300,
    meteor: true,
    spinner: false,
    spinnerPosition: "'right'",
    direction: "'leftToRightIncreased'",
    color: 'rgb(248, 147, 31)',
    thick: true
  };

  @Output() collapsedEventHeader = new EventEmitter<null>();

  constructor(
    private translate: TranslateService,
    public router: Router,
    private authService: AuthService,
    private siteService: SiteDetailsService,
    private globalSiteService: GlobalHeaderSiteService,
    private notService: NotificationDetailsService,
    private _snackBar: MatSnackBar,
    private assetListService: AssetListService,
    private modalService: NgbModal,
    private headerService: GlobalHeaderSiteService
  ) {
    this.router.events.subscribe((val: NavigationEnd): void => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  allSites: any[];
  selectedSiteId = new FormControl();
  defaultTab = 'fleet';
  currentTab = undefined;
  currentTabIndex: number;
  provisioneddashboardModules = [];
  kpiOptions: {
    menuName: string;
    value: string;
    children: {
      name: string;
      value: string;
      Id: string;
    }[];
  }[] = [];
  delIndices = [];
  notificationResponseObject: {
    rowId: number;
    notificationId: number;
    notificationCaseType: string;
    notificationType: 'CASE_WHATSAPP' | 'CASE_ASSIGNED' | 'ALERT' | 'ASSET_STATUS_CHANGE';
    readStatus: string;
    description: string;
    mappingId: number;
    time: number[];
  }[];
  @Input()
  logginUserClientName: string;

  clientName = '';
  searchBarToggle = window.innerWidth <= 1200 ? false : true;
  nzSelectState: boolean = false;
  nzSelectStateMobile: boolean = false;
  prevInnerWidthMode: 'mobile' | 'ipad' | 'window';
  loggedInUser: string;
  clientId;
  siteId: string;

  async ngOnInit(): Promise<void> {
    let activeModule = this.router.url.split('/')[3].split(';')[0];
    const indexofDashboard = dashboardList.indexOf(activeModule);

    this.currentTabIndex = indexofDashboard == -1 ? null : indexofDashboard;

    this.headerService.headerActiveClass.subscribe((res: boolean) => {
      if (res == false) {
        dashboardList.forEach((module: string) => {
          if (!isNullOrUndefined(document.getElementById(module))) {
            document.getElementById(module).classList.remove('active');
          }
        });
      }
    });

    this.pushRightClass = 'push-right';

    this.authService
      .getLoggedInUserDetails()
      .subscribe((user: { sites: []; client: { clientId: number }; roles: []; dashboardModules: any }) => {
        this.allSites = user.sites;
        // .filter(site => site.siteStatus === "ACTIVE");
        // this.logginUserClientName = user.client.displayName;
        this.clientId = user.client.clientId;
        this.loggedInUser = sessionStorage.getItem(USER_NAME);
        if (user.sites.length > 0) {
          this.allSites.splice(0, 0, { siteId: -1, siteName: 'All' }); // inserted 'All' Values in 0th index
          this.selectedSiteId.setValue(this.allSites[0]);
        }
        const modules = Object.keys(user.dashboardModules);
        modules.forEach((module: string) => {
          this.kpiOptions.push({
            menuName: module.charAt(0).toLocaleUpperCase() + module.slice(1),
            value: module.toLowerCase(),
            children: user.dashboardModules[module].map(
              (ele: { navTitle: any; sectionId: any; navId: any }, _i: any) => ({
                name: ele.navTitle,
                value: ele.sectionId,
                id: ele.navId
              })
            )
          });
        });
        modules.forEach((module: string) => {
          switch (module) {
            case DashboardModuleHeader.OVERVIEW.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.OVERVIEW,
                pathValue: module
              });
              break;
            case DashboardModuleHeader.FLEET.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.FLEET,
                pathValue: module
              });
              break;
            case DashboardModuleHeader.FUEL.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.FUEL,
                pathValue: module
              });
              break;
            case DashboardModuleHeader.MAINTENANCE.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.MAINTENANCE,
                pathValue: module
              });
              break;
            case DashboardModuleHeader.OPERATOR.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.OPERATOR,
                pathValue: module
              });
              break;
            case DashboardModuleHeader.REPAIR.toLocaleLowerCase():
              this.provisioneddashboardModules.push({
                moduleName: DashboardModuleHeader.REPAIR,
                pathValue: module
              });
              break;
          }
        });
      });

    if (
      !isNullOrUndefined(localStorage.getItem(NEW_TAB_REDIRECTION.NEW_TAB_FLAG)) &&
      !isNullOrUndefined(localStorage.getItem(NEW_TAB_REDIRECTION.NEW_TAB_MODULE_FLAG)) &&
      !isNullOrUndefined(localStorage.getItem(NEW_TAB_REDIRECTION.NEW_TAB_ASSET_ID))
    ) {
      while (this.clientId === undefined) {
        await new Promise((resolve: (value: unknown) => void) => setTimeout(resolve, 100));
      }

      const assetId = localStorage.getItem(NEW_TAB_REDIRECTION.NEW_TAB_ASSET_ID);
      const newTabModule = localStorage.getItem(NEW_TAB_REDIRECTION.NEW_TAB_MODULE_FLAG);

      if (newTabModule == NEW_TAB_REDIRECTION.NEW_TAB_MODULE_MAP) {
        this.router
          .navigate([
            '/pages/dashboard/' + (this.clientId == 15 ? 'here' : 'google') + '-map',
            { assetId: assetId, viewMode: 'route-info' }
          ])
          .then(() => {
            window.location.reload();
          });
      } else if (newTabModule == NEW_TAB_REDIRECTION.NEW_TAB_MODULE_ASSET) {
        this.router.navigate(['/pages/models/asset-configuration-detail/' + assetId, { mode: 'view' }]).then(() => {
          window.location.reload();
        });
      }

      localStorage.removeItem(NEW_TAB_REDIRECTION.NEW_TAB_FLAG);
      localStorage.removeItem(NEW_TAB_REDIRECTION.NEW_TAB_MODULE_FLAG);
      localStorage.removeItem(NEW_TAB_REDIRECTION.NEW_TAB_ASSET_ID);
    }

    this.selectedSiteId.valueChanges.subscribe((site: { siteId: string; siteName: string; assetId: number }) => {
      this.searchBarToggle = false;
      if (site.assetId != null) {
        this.viewAssetDetail(site, 'view');
      } else {
        this.globalSiteService.headerSite.next(site.siteId);
        this.globalSiteService.headerSiteName.next(site.siteName);
      }
      this.siteId = isNullOrUndefined(site.assetId) ? site.siteId : '-1';
      this.assetListService.getAllAssets(this.siteId).subscribe((res: any) => {
        this.allAssets = res;
      });
    });

    this.notService.getNotifications().subscribe((allRes: any) => {
      this.notificationResponseObject = allRes;
    });

    this.prevInnerWidthMode = window.innerWidth <= 575 ? 'mobile' : window.innerWidth <= 1200 ? 'ipad' : 'window';
  }

  gotoDashboard(): void {
    this.router.navigate(['pages/dashboard']);
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar(): void {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  collapseEvent(): void {
    this.collapsedEventHeader.emit();
  }

  public userProfile(): void {
    // const details = this.authService.credentials;
    this.router.navigateByUrl('/pages/userManagement/user-profile');
  }

  onLoggedout(): void {
    this.authService.signOut();
  }

  navigate(page: 'map' | 'trend-analysis' | 'video-analysis' | 'live-feed'): void {
    // document.getElementById(this.dashboardMenu).className = 'nav-item nav-footer-item';
    switch (page) {
      case 'map':
        if (this.clientId == 15) {
          this.router.navigateByUrl('/pages/dashboard/here-map');
        } else {
          this.router.navigateByUrl('/pages/dashboard/google-map');
        }
        break;
      case 'trend-analysis':
        this.router.navigateByUrl('/pages/analytics/trend-analysis');
        break;
      case 'video-analysis':
        this.router.navigateByUrl('/pages/analytics/video-analysis');
        break;
      case 'live-feed':
        this.router.navigateByUrl('/pages/dashboard/live-feed');
        break;
    }
  }

  getDashboardMenu(
    menu: 'overview' | 'fleet' | 'fuel' | 'maintenance' | 'operator' | 'repair',
    section: any,
    id: any
  ): void {
    this.provisioneddashboardModules.forEach((module: { pathValue: string }, i: number) => {
      document.getElementById(module.pathValue).className = 'nav-item nav-footer-item';

      if (module.pathValue == menu) {
        localStorage.setItem('currentTabIndex', i.toString());
      }
    });
    this.currentTab = menu;
    // localStorage.setItem("currentTab", this.currentTab);
    switch (menu) {
      case 'overview':
        document.getElementById('overview').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/overview', { sectionid: section }]);
        break;
      case 'fleet':
        document.getElementById('fleet').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/fleet', { sectionid: section }]);
        break;
      case 'fuel':
        document.getElementById('fuel').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/fuel-energy', { sectionid: section }]);
        break;
      case 'maintenance':
        document.getElementById('maintenance').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/maintenance', { sectionid: section }]);
        break;
      case 'operator':
        document.getElementById('operator').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/operator', { sectionid: section }]);
        break;
      case 'repair':
        document.getElementById('repair').className = 'nav-item nav-footer-item active';
        this.router.navigate(['/pages/dashboard/repair', { sectionid: section }]);
        break;
    }
    setTimeout(() => {
      this.gotoSection(id);
    }, 500);
  }

  signOut(): void {
    this.authService.signOut().subscribe({
      next: (_res: any) => {
        localStorage.removeItem('currentTabIndex');
        this.router.navigate(['auth', 'login']).then(() => {
          window.location.reload();
        });
      },
      error: () => {
        sessionStorage.clear();
        this.router.navigate(['auth', 'login']);
      }
    });
  }

  gotoProfile(): void {
    // document.getElementById(this.dashboardMenu).className = 'nav-item nav-footer-item';
    this.router.navigate(['pages', 'userManagement', 'user-profile']);
  }

  notify(evnt: any): void {
    this.showNotification = !this.showNotification;
  }

  hidenotification(): void {
    const delItems = JSON.parse(sessionStorage.getItem('deletedItems'));
    this.showNotification = false;
  }

  gotoSection(sectionId: string): void {
    const targetDiv = document.getElementById('scrollspy').getElementsByClassName(sectionId)[0];
    targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.setActive(sectionId);
  }

  setRead(evnt: any): void {
    const dom = document.querySelectorAll('.notification.new');
    const newItems: any = this.getContentByVal(this.notificationResponseObject, 'N');
    this.notService.markAllAsRead(newItems).subscribe((_res: any) => {
      for (let i = 0; i < dom.length; i++) {
        dom[i].classList.remove('new');
      }
      // this.provisioneddashboardModules = [];
      // this.ngOnInit();
    });
  }

  myFunc = (num: Number): Number => Number(num);

  deleteNot(e: any, index: number, currRow: number): void {
    e.preventDefault();
    e.stopPropagation();
    let curNotification;
    const intArr = [currRow];
    // @ts-ignore
    if (!isNullOrUndefined(intArr)) {
      this.notService.deleteNotification(intArr).subscribe((res: { Message: string }) => {
        const type = 'success';
        // @ts-ignore
        this._snackBar.open(res.Message, '', {
          duration: 10000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          // tslint:disable-next-line:max-line-length
          panelClass: type == 'success' ? ['success-snackbar'] : null
        });
        sessionStorage.removeItem('deletedItems');
        this.delIndices = [];
        // this.provisioneddashboardModules = [];
        // this.notService.deleteNotification(intArr).subscribe();
        // this.ngOnInit();
      });
    }
    curNotification = $('.notification_' + index);
    $(curNotification).addClass('deleted');
    // this.provisioneddashboardModules = [];
  }

  getContentByVal(object: { readStatus: string }[], kProp: string): typeof data {
    const data = [];
    object.forEach((post: { readStatus: string }) => {
      if (post.readStatus == kProp) {
        data.push(post['rowId']);
      }
    });
    return data;
  }

  setActive(sectionId: string): void {
    const domEle = document.querySelectorAll('.nav-link');
    for (let i = 0; i < domEle.length; i++) {
      domEle[i].classList.remove('active');
      if ($(domEle[i]).attr('id') == sectionId) {
        $(domEle[i]).addClass('nav-link active');
      }
    }
  }

  // tslint:disable-next-line:max-line-length
  navNotification(
    page: 'CASE_WHATSAPP' | 'CASE_ASSIGNED' | 'ALERT' | 'ASSET_STATUS_CHANGE',
    resID: number,
    index: number,
    rowId: number
  ): void {
    let curNotification;
    const intArr = [rowId];
    // @ts-ignore
    this.notService.markAllAsRead(intArr).subscribe(() => {
      curNotification = $('.notification_' + index);
      $(curNotification).removeClass('new');
      // this.ngOnInit();
      setTimeout(() => {
        this.showNotification = false;
        switch (page) {
          case 'CASE_WHATSAPP':
            this.router.navigateByUrl('/pages/cases/case-list/all/' + resID);
            break;
          case 'CASE_ASSIGNED':
            this.router.navigateByUrl('/pages/analytics/trend-analysis');
            break;
          case 'ALERT':
            this.router.navigateByUrl('/pages/analytics/video-analysis');
            break;
          case 'ASSET_STATUS_CHANGE':
            this.router.navigateByUrl('/pages/dashboard/live-feed');
            break;
        }
      }, 800);
    });
  }

  viewAssetDetail(data: { assetId: number }, openMode: string): void {
    this.searchBarToggle = false;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/pages/models/asset-configuration-detail/' + data.assetId, { mode: openMode }]);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }): void {
    let innerWidth = event.target.innerWidth;
    let innerWidthMode: 'mobile' | 'ipad' | 'window';
    if (innerWidth > 0 && innerWidth <= 575) {
      innerWidthMode = 'mobile';
    } else if (innerWidth > 575 && innerWidth <= 1200) {
      innerWidthMode = 'ipad';
    } else {
      innerWidthMode = 'window';
    }
    if (this.prevInnerWidthMode !== innerWidthMode) {
      this.prevInnerWidthMode = innerWidthMode;
      this.searchBarToggle = false;
      this.nzSelectState = false;
      this.nzSelectStateMobile = false;
    }
  }

  getSearchBarToggle(windowType: 'desktopIpad' | 'mobile'): void {
    this.searchBarToggle = !this.searchBarToggle;
    if (this.searchBarToggle) {
      setTimeout(() => {
        if (windowType == 'desktopIpad') {
          this.nzSelectState = true;
        } else {
          this.nzSelectStateMobile = true;
        }
      }, 10);
    } else {
      setTimeout(() => {
        if (windowType == 'desktopIpad') {
          this.nzSelectState = false;
        } else {
          this.nzSelectStateMobile = false;
        }
      }, 10);
    }
  }

  qrCodeScanner(e: { stopPropagation: () => void }): void {
    e.stopPropagation();
    this.nzSelectState = false;
    this.nzSelectStateMobile = false;
    const activeModal: NgbModalRef = this.modalService.open(QrCodeOperationComponent, {
      backdrop: 'static'
    });
    activeModal.componentInstance.isSearchAssetQr = true;
    activeModal.result.then((response: any): void => {
      if (response != 'N') {
        this.searchBarToggle = false;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([
            '/pages/models/asset-configuration-detail/' + response.asset.entityId,
            { mode: 'view' }
          ]);
        });
      }
    });
  }
}
