import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FuelEnergyService } from 'src/app/layout/dashboard/fuel-energy/fuel-energy.service';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { ScannerQRCodeConfig, NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
@Component({
  selector: 'app-qr-code-operation',
  templateUrl: './qr-code-operation.component.html',
  styleUrls: ['./qr-code-operation.component.scss']
})
export class QrCodeOperationComponent implements OnInit {
  showCancelButton: boolean = true;
  showRetryButton: boolean = false;
  showConfirmButton: boolean = false;
  showScanButton: boolean = false;
  showAssetSelection: boolean = false;
  defaultSelectFirstAsset: boolean = false;
  isConfigureQr: boolean = false;
  isSearchAssetQr: boolean = false;
  showInput: boolean = true;
  showDownloadButton: boolean = false;
  modalContent: string;
  okButtonName: string = 'Confirm';
  cancelButtonName: string = 'Close';
  retryButtonName: string = 'Re-Submit';
  scanButtonName: string = 'Re-Scan';
  isScanning: boolean = true;
  assetList: any[] = [];
  filteredAssetList: any[] = [];
  qrCodeData: string;
  assetName: string;
  qrCodeQuantity: number;
  qrCodeList: any[] = null;
  qrElementType: any = 'text';
  generateQrCodeClass: string;
  qrFiles: any[] = [];
  qrScannerconfig: ScannerQRCodeConfig = {
    vibrate: 400,
    deviceActive: 0,
    constraints: {
      audio: false,
      video: {
        width: window.innerWidth
      }
    }
  };

  public asset: FormControl = new FormControl();
  public assetFilter: FormControl = new FormControl();

  constructor(private activeModal: NgbActiveModal, private fuelService: FuelEnergyService) {}

  ngOnInit() {
    this.filteredAssetList = this.assetList.slice(0);
    if (this.isConfigureQr) {
      this.modalContent = 'Please scan the QR Code!';
    } else if (this.isSearchAssetQr) {
      this.modalContent = 'Please scan the QR Code!';
    } else {
      this.modalContent = 'How many number of QR code you want to generate?';
      this.okButtonName = 'Generate';
      this.showConfirmButton = true;
    }
  }

  public handlePermisssions(action: NgxScannerQrcodeComponent, fn: string): void {
    action[fn]().subscribe();
  }
  cancelModal() {
    this.activeModal.close('N');
  }

  okModal() {
    if (this.isConfigureQr) {
      if (this.asset.value) {
        this.callQrCodeOperationApi();
      } else {
        this.modalContent = 'Please select the asset from below list and then confirm.';
      }
    } else {
      if (this.qrCodeQuantity != null && this.qrCodeQuantity <= 100 && this.qrCodeQuantity > 0) {
        this.modalContent = 'Generating QR Codes . . .';
        this.showInput = false;
        const data = {
          numberOfQrCodeGenerate: this.qrCodeQuantity,
          operationType: 'GENERATE_QR'
        };
        this.fuelService.qrCodeOperation(data).subscribe((response) => {
          if (response != null && response.generateQrStatus != null && response.generateQrStatus == 'SUCCESS')
            this.qrCodeList = response.qrCodeDataList;
          this.modalContent = 'QR Codes generated successfully.';
          this.generateQrCodeClass = 'generate-qr-code';
          this.showDownloadButton = true;
          this.showConfirmButton = false;
        });
      } else {
        this.modalContent = 'Please input the number of quantity between 0 to 100.';
      }
    }
  }

  filterAsset() {
    let search = this.assetFilter.value.toLowerCase();
    if (!search) {
      this.filteredAssetList = this.assetList.slice(0);
      return;
    } else {
      this.filteredAssetList = this.assetList.filter((item) => item.assetName.toLowerCase().includes(search));
    }
  }

  onCodeResult(event: any): void {
    this.isScanning = false;
    this.modalContent = 'Scan completed! Please select the asset to which QR code will be configured.';
    this.qrCodeData = event;
    this.showConfirmButton = true;
    this.showAssetSelection = true;
    if (this.defaultSelectFirstAsset) {
      this.asset.setValue(this.assetList[0]);
    }
  }

  onAssetSearchCodeResult(event: any): void {
    this.isScanning = false;
    this.qrCodeData = event;
    const data = {
      qrCodeData: event,
      operationType: 'FETCH_ASSET_BY_QR'
    };
    this.fuelService.qrCodeOperation(data).subscribe(
      (response) => {
        if (response != null && response.fetchQrStatus != null && response.fetchQrStatus == 'SUCCESS') {
          this.activeModal.close(response);
        } else {
          this.modalContent = 'Either QR is not configured or it is invalid';
          this.showCancelButton = true;
          this.showScanButton = true;
        }
      },
      (error) => {
        this.modalContent = error.error.IOTException;
        this.showCancelButton = true;
        this.showScanButton = true;
      }
    );
  }

  reScan() {
    this.isScanning = true;
    this.showRetryButton = false;
    this.showConfirmButton = false;
    this.showScanButton = false;
    this.modalContent = 'Please scan the QR Code!';
    this.asset.setValue(null);
  }

  callQrCodeOperationApi() {
    this.modalContent = 'Processing...';
    const data = {
      qrCodeData: this.qrCodeData,
      operationType: 'CONFIGURE_QR',
      asset: {
        entityId: this.asset.value.assetId
      }
    };
    this.fuelService.qrCodeOperation(data).subscribe(
      (response) => {
        if (response != null && response.configQrStatus != null && response.configQrStatus == 'SUCCESS') {
          this.modalContent =
            'Scanned QR code is configured to asset ' + '<b>' + this.asset.value.assetName + '</b>' + ' successfully.';
          this.showRetryButton = false;
          this.showConfirmButton = false;
          this.showCancelButton = true;
          this.showScanButton = true;
          this.showAssetSelection = false;
          this.scanButtonName = 'Configure Another QR';
          this.cancelButtonName = 'Close';
        } else {
          this.modalContent = 'Some internal error occurred! Please try again.';
          this.showRetryButton = true;
          this.showConfirmButton = false;
          this.showCancelButton = true;
          this.showScanButton = true;
        }
      },
      (error) => {
        this.modalContent = error.error.IOTException;
        this.showRetryButton = true;
        this.showConfirmButton = false;
        this.showCancelButton = true;
        this.showScanButton = true;
      }
    );
  }

  makeFilesOfQr() {
    let metadata = {
      type: 'image/jpeg'
    };
    for (let index = 1; index <= this.qrCodeQuantity; index++) {
      const fileNameToDownload = 'Cognecto_QR_' + index;
      let base64Img = document.getElementsByClassName('qRCode')[index - 1].children[0]['src'];

      base64Img = base64Img.substr(base64Img.lastIndexOf(',') + 1);
      const byteCharacters = atob(base64Img);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], metadata);

      let file = new File([blob], fileNameToDownload + '.jpeg', metadata);
      this.qrFiles.push(file);

      /*       fetch(base64Img)
        .then(res => res.blob())
        .then((blob) => {
          // IE
          if (false) { //window.navigator && window.navigator.msSaveOrOpenBlob
            // window.navigator.msSaveOrOpenBlob(blob,fileNameToDownload);
          } else { // Chrome
            let file = new File([blob], fileNameToDownload + ".jpeg", metadata);
            this.fileUrls.push(file);
          }
        }); */
    }
  }

  downloadZip() {
    this.makeFilesOfQr();
    var zip = new JSZip();
    for (let i = 0; i < this.qrFiles.length; i++) {
      zip.file(this.qrFiles[i].name, this.qrFiles[i], { base64: true });
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      FileSaver.saveAs(content, 'Cognecto_QR_' + new Date().getTime() + '.zip');
    });
    this.modalContent = 'QR Codes are downloaded successfully!';
    this.showDownloadButton = false;
    this.cancelButtonName = 'Close';
    this.qrCodeList = [];
    this.generateQrCodeClass = '';
  }
}
