<div class="action-buttons">
  <a *ngIf="isCurrentRowEditing == true" (click)="onUpdate($event)" class="update" data-action="update">
    <i class="fa fa-check"></i>
  </a>

  <a *ngIf="isCurrentRowEditing == true" (click)="onCancel($event)" class="cancel" data-action="cancel">
    <i class="fa fa-times"></i>
  </a>

  <a *ngIf="isCurrentRowEditing == false" (click)="onEdit($event)" class="edit" data-action="edit">
    <i class="fa fa-edit"></i>
  </a>

  <a
    *ngIf="!isCurrentRowEditing"
    class="delete"
    data-action="delete"
    nz-popconfirm
    nzPopconfirmTitle="Are you sure you want to delete this report entry?"
    (nzOnConfirm)="onDelete($event)">
    <i class="fa fa-trash"></i>
  </a>
</div>
