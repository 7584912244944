import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ICellEditorComp } from 'ag-grid-community';
import { differenceInCalendarDays } from 'date-fns';
import moment from 'moment';
@Component({
  selector: 'app-date-time-cell-editor',
  templateUrl: './date-time-cell-editor.component.html'
})
export class DateTimeCellEditorComponent implements ICellEditorComp {
  today = new Date();
  selectedDate: Date;
  datePipe: DatePipe;
  constructor() {}

  ngOnInit(): void {}

  isPopup?(): boolean {
    return false;
  }

  getGui(): HTMLElement {
    return null;
  }

  destroy?(): void {}

  getValue(): string {
    return moment(this.selectedDate).format('YYYY-MM-DD HH:mm');
  }

  agInit(params: any): void {
    this.selectedDate = new Date(params.value);
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;
}
