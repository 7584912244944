import { Component, Input, OnInit } from '@angular/core';
import { HeaderSideBarService } from 'src/app/layout/components/header-sidebar.service';
import { CamelToTitleCasePipe } from '../cameltotitlecase.pipe';
import { isNullOrUndefined } from '../function-util';

@Component({
  selector: 'app-dashboard-btn',
  templateUrl: './dashboard-btn.component.html',
  styleUrls: ['./dashboard-btn.component.scss']
})
export class DashboardBtnComponent {
  @Input() moduleName: string;
  @Input() isMobile: boolean = false;
  @Input() functionBinding: {};

  btnModuleContainer: {
    title: string;
    color: string;
    function: Function;
  }[] = [];

  btnColors = ['warn', 'primary', 'accent'];

  constructor(private headerService: HeaderSideBarService) {}

  ngAfterViewInit(): void {
    this.getSubModule();
  }

  getSubModule() {
    this.headerService.getDashboardModule(this.moduleName).subscribe((res: { [moduleName: string]: string[] }) => {
      if (!isNullOrUndefined(res)) {
        res[this.moduleName].forEach((btnId: string, i) => {
          this.btnModuleContainer.push({
            title: CamelToTitleCasePipe.prototype.transform(btnId),
            color: this.btnColors[i % this.btnColors.length],
            function: this.functionBinding[btnId]
          });
        });
      }
    });
  }
}
