<a *ngIf="viewButton" (click)="view($event)" matTooltip="View" triggers="mouseenter:mouseleave" container="body">
  <i class="fa fa-eye"></i>
</a>

<a *ngIf="editButton" (click)="edit($event)" matTooltip="Edit" triggers="mouseenter:mouseleave" container="body">
  <i class="far fa-edit"></i>
</a>

<a *ngIf="deleteButton" (click)="delete($event)" matTooltip="Delete" triggers="mouseenter:mouseleave" container="body">
  <i class="fa fa-trash-alt"></i>
</a>

<a
  *ngIf="activeButton"
  (click)="active($event)"
  matTooltip="{{ isActive ? 'Deactivate' : 'Activate' }}"
  triggers="mouseenter:mouseleave"
  container="body">
  <i *ngIf="isActive" class="fa fa-toggle-on"></i>
  <i *ngIf="!isActive" class="fa fa-toggle-off"></i>
</a>
<a
  *ngIf="activeStatusButton"
  (click)="activeStatus($event)"
  matTooltip="{{ isStatusActive ? 'Deactivate' : 'Activate' }}"
  triggers="mouseenter:mouseleave"
  container="body">
  <i *ngIf="isStatusActive" class="fa fa-toggle-on"></i>
  <i *ngIf="!isStatusActive" class="fa fa-toggle-off"></i>
</a>

<a *ngIf="statusButton" (click)="status($event)" matTooltip="Status" triggers="mouseenter:mouseleave" container="body">
  <i class="fa fa-cogs"></i>
</a>

<a
  *ngIf="downloadButton"
  (click)="download($event)"
  matTooltip="Download"
  triggers="mouseenter:mouseleave"
  container="body">
  <!-- <i class="fa fa-download"></i> -->
  <img style="width: 24px" src="./../../../../assets/icons/download.svg" alt="download" />
</a>

<a
  *ngIf="locationButton"
  (click)="location($event)"
  matTooltip="Location"
  triggers="mouseenter:mouseleave"
  container="body">
  <img style="width: 24px" src="./../../../../assets/icons/map.svg" alt="Location" />
</a>

<a
  *ngIf="tripHistoryButton"
  (click)="tripHistory($event)"
  matTooltip="Trip History"
  triggers="mouseenter:mouseleave"
  container="body">
  <i class="fa fa-history" aria-hidden="true" style="font-size: large"></i>
</a>

<a *ngIf="fillColorButton" triggers="mouseenter:mouseleave" container="body">
  <i class="fa fa-solid fa-eye-dropper" [matMenuTriggerFor]="menu" matTooltip="Actions" aria-hidden="true">
    <mat-menu class="custom-dropdown-height" #menu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngFor="let color of colors" (click)="fillColor(color)">
          {{ color }}
        </button>
      </ng-template>
    </mat-menu>
  </i>
</a>

<a *ngIf="statusButton" (click)="status($event)" matTooltip="Status" triggers="mouseenter:mouseleave" container="body">
  <i class="fa fa-cogs"></i>
</a>