import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  modalHeader: string;
  showCancelButton: boolean = true;
  modalContent: string;
  okMessage: string = 'Ok';
  cancelMessage: string = 'Cancel';
  showInputText: boolean = false;
  placeholderContent: string = '';
  inputContent: any = '';

  @Output() emitService = new EventEmitter();

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  cancelModal() {
    this.activeModal.close('N');
  }

  okModal() {
    if (this.showInputText) {
      this.activeModal.close(this.inputContent);
    } else {
      this.activeModal.close('Y');
    }
  }
}
