<mat-card class="chart-card">
    <mat-card-title>
        <div class="container-header">
            <div class="title">
                <img class="header-icon" [src]="headerIcon" />
                <p class="heading">{{ title }}</p>
            </div>
            <div class="indicate">
                <p class="indicate-msg">{{ indicateMsg }}</p>
                <i class="fa fa-times close-button" aria-hidden="true" (click)="cancel()"></i>
            </div>
        </div>
        <hr class="hr-line" />
    </mat-card-title>
    <mat-card-content>
        <div class="chart-container">
            <div class="graph">
                <div class="chart-element" [id]="chartId"></div>
                <div class="is-data-found" *ngIf="!isDataFound">
                    <P>No Data Found</P>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
