import { Injectable } from '@angular/core';
import { isNullOrUndefined, msToTime } from 'src/app/shared/function-util';
import * as Highcharts from 'highcharts';
import * as grouped from 'highcharts-grouped-categories/grouped-categories';
import { HighchartOptions } from './render-highcharts-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDetailInfoComponent } from '../../layout/dashboard/chart-detail-info/chart-detail-info.component';
import { ChartDetailInfoParams } from 'src/app/layout/dashboard/chart-detail-info/chart-detail-info-params';
import { parseInt } from 'lodash';
import Exporting from 'highcharts/modules/exporting';
import Drilldown from 'highcharts/modules/drilldown';
import heatmap from 'highcharts/modules/heatmap';
import { TrendCharts } from '../enum-util';
heatmap(Highcharts);
Exporting(Highcharts);
Drilldown(Highcharts);
grouped(Highcharts);

@Injectable({
  providedIn: 'root'
})
export class HighchartRenderService {
  constructor(private modalService: NgbModal) {}

  getChartDataset(
    keys: string[],
    inputData: any,
    calculateByKey: string
  ): { name: string; colorByPoint: boolean; data: { name: string; y: number }[]; cursor: string }[] {
    const data = [];
    keys.forEach((ele: string, i: number) => {
      let value = 0;
      if (calculateByKey.length != 0) {
        if (!isNullOrUndefined(inputData[ele])) {
          inputData[ele].forEach((item: { [x: string]: any }) => {
            value += item[calculateByKey];
          });
        }
      } else {
        value = !isNullOrUndefined(inputData[ele]) ? inputData[ele].length : 0;
      }
      const item = {
        name: ele,
        y: value
      };
      data.push(item);
    });
    const series = [
      {
        name: '',
        colorByPoint: true,
        data: data,
        cursor: 'pointer'
      }
    ];
    return series;
  }

  // formatDateTillMonth(keys: string[]) {
  //   return keys.map(ele => ele.split('-').splice(1, 2).reverse().join('/'));
  // }

  onChartClickModalHandler(
    event: Highcharts.PointClickEventObject,
    dataProvider: (event: Highcharts.PointClickEventObject) => Promise<ChartDetailInfoParams>
  ): void {
    dataProvider(event)
      .then((data: ChartDetailInfoParams) => {
        const activeModal = this.modalService.open(ChartDetailInfoComponent);
        activeModal.componentInstance.chartInfoDetailParams.parameterColumn = data.parameterColumn;
        activeModal.componentInstance.chartInfoDetailParams.assetList = data.assetList;
        data.date ? (activeModal.componentInstance.chartInfoDetailParams.date = data.date) : null;
        data.modalHeader ? (activeModal.componentInstance.chartInfoDetailParams.modalHeader = data.modalHeader) : null;
        data.convertToTime
          ? (activeModal.componentInstance.chartInfoDetailParams.convertToTime = data.convertToTime)
          : null;
        activeModal.componentInstance.chartInfoDetailParams.showDateColumn = !isNullOrUndefined(data.showDateColumn);
        data.middleColumnName
          ? (activeModal.componentInstance.chartInfoDetailParams.middleColumnName = data.middleColumnName)
          : null;
        data.middleColumnParamter
          ? (activeModal.componentInstance.chartInfoDetailParams.middleColumnParamter = data.middleColumnParamter)
          : null;
      })
      .catch((err: string) => console.error(err));
  }

  renderHighchart(chartId: string, highchartOptions: HighchartOptions): Highcharts.Chart {
    let total = 0;
    highchartOptions.chartData.forEach((ele: { y: number }) => (total = total + ele.y));
    const chartOptions: Highcharts.Options = {
      chart: {
        type: highchartOptions.chartType,
        height: highchartOptions.height ? highchartOptions.height : 340,
        events: {
          drilldown: function () {
            this.yAxis[0].setTitle({
              text: highchartOptions.yAxisDrillDownLabels[1]
            });
          },
          drillup: function () {
            this.yAxis[0].setTitle({
              text: highchartOptions.yAxisDrillDownLabels[0]
            });
          }
        },
        inverted: !isNullOrUndefined(highchartOptions.inverted)
      },
      boost: {
        useGPUTranslations: true,
        usePreallocated: true
      },
      title: {
        y: 10,
        floating: false,
        useHTML: true,
        text:
          highchartOptions.chartTitle.length == 3
            ? `<span style=" display: flex; flex-direction: row; justify-content: space-evenly; font-size :23px;">
                    <div> <span  style=" color:black;">${highchartOptions.chartTitle[0]}
                    <sub style = "font-size : 11px; "> ${highchartOptions.chartTitle[2]} </sub>
                    <div style="font-size:11px;color: #000000b5;display: flex;justify-content: center;">Average</div>
                    </span></div>
                    <div> <span style="border-right: 2px solid #a0a0a0; margin-left: 11px; padding-top: 4px; padding-bottom: 17px; "></span>
                    <span style="color:#0088f8;">${highchartOptions.chartTitle[4] == undefined ? '&ensp;' : ''}${
                highchartOptions.chartTitle[1]
              }
                    <sub style = "font-size:11px; color: black; ">${highchartOptions.chartTitle[2]}</sub>
                    <div style="font-size:11px;color:#000000b5;display: flex;justify-content: center;">Total</div>
                    </span> </div><span>`
            : `<div style = "font-size:18px">${highchartOptions.chartTitle}</div>`,
        style: {
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: highchartOptions.chartSubTitle,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          color: '#000000b5'
        }
      },
      credits: {
        enabled: false
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category',
        title: {
          text: highchartOptions.xAxisLabel
        },
        categories: highchartOptions.xAxisKeys,
        labels: {
          useHTML: true,
          formatter: function () {
            const value = this.value.toString();
            if (!isNullOrUndefined(highchartOptions.xAxisNameMaxLength)) {
              if (value.length > highchartOptions.xAxisNameMaxLength) {
                return (
                  '<span title=' +
                  value +
                  '>' +
                  value.substring(0, highchartOptions.xAxisNameMaxLength) +
                  '...' +
                  '</span>'
                );
              }
            }
            return value;
          }
        }
        /* labels: {
                    staggerLines: 1,
                    style: {
                        fontSize: '9px',
                        fontWeight: '500'
                    }
                }, */
        // visible: false,
      },
      yAxis: {
        categories: isNullOrUndefined(highchartOptions.yAxisKeys) ? [] : highchartOptions.yAxisKeys,
        type: highchartOptions.dateChart == true ? 'datetime' : 'linear',
        title: {
          text: highchartOptions.yAxisLabel
        },
        visible: false,
        labels: {
          formatter: function () {
            if (highchartOptions.dateChart) {
              const hours = parseInt((parseFloat(this.value.toString()) / 3600).toString());
              return highchartOptions.yAxisSuffix ? hours.toString() + highchartOptions.yAxisSuffix : hours.toString();
            } else {
              return highchartOptions.yAxisSuffix
                ? this.value.toString() + highchartOptions.yAxisSuffix
                : this.value.toString();
            }
          }
        }
      },
      legend: {
        enabled: highchartOptions.showLegend,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        padding: 0,
        floating: true,
        y: 20,
        itemStyle: {
          fontSize: '10px',
          fontWeight: '500'
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            inside: highchartOptions.inside,
            color: 'black',
            formatter: function (): string {
              let value = highchartOptions.dateChart ? msToTime(this.y) : Highcharts.numberFormat(this.y, 0, '.', ',');
              return highchartOptions.chartType != 'pie' ? `<span >${value}</span>` : `${this.point.name}: ${value}`;
            }
          },
          marker: {
            enabled: !highchartOptions.chartType.includes('line'),
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        column: {
          pointWidth: highchartOptions.isStackedColumn ? 35 : 40
        }
      },
      tooltip: {
        headerFormat: '', //'<span style="font-size:11px">{series.name}</span><br>'
        useHTML: true,
        formatter: function (this) {
          const value = highchartOptions.dateChart
            ? msToTime(this.point.y)
            : Highcharts.numberFormat(this.point.y, 0, '.', ',');
          if (this.point.name) {
            return (
              '<div style="position:relative;><span style="color:' +
              this.point.color +
              '">' +
              this.point.name +
              '</span>: <b>' +
              value +
              '</b><br/></div>'
            );
          } else if (this.series.name) {
            return (
              '<div style="position:relative;" ><span style="color:' +
              this.point.color +
              '">' +
              this.series.name +
              '</span>: <b>' +
              value +
              '</b><br/></div>'
            );
          } else if (this.point.category['name']) {
            return (
              '<div style="position:relative;">' +
              this.point.category['name'] +
              '</span>: <b>' +
              value +
              '</b><br/></div>'
            );
          } else {
            return null;
          }
        },
        valueDecimals: 2
      },
      series: highchartOptions.chartData,
      drilldown: highchartOptions.drillDownData === undefined ? {} : highchartOptions.drillDownData
    };
    chartOptions.exporting = {
      enabled: !isNullOrUndefined(highchartOptions.exportIcon)
    };

    if (highchartOptions.yAxisLabel2) {
      chartOptions.yAxis = [
        {
          title: {
            text: highchartOptions.yAxisLabel
          },
          visible: true
        },
        {
          type: 'datetime',
          title: {
            text: highchartOptions.yAxisLabel2
          },
          labels: {
            formatter: function () {
              const hr = this.value;
              // @ts-ignore
              const hours = parseInt(String(hr / 3600));
              return highchartOptions.yAxisSuffix ? hours.toString() + highchartOptions.yAxisSuffix : hours.toString();
            }
          },
          visible: true,
          opposite: true
        }
      ];
      chartOptions.tooltip = {
        headerFormat: '',
        useHTML: true,
        formatter: function (this) {
          var value = this.series.name.localeCompare('Idling Hours') ? this.point.y.toFixed(2) : msToTime(this.point.y);
          return (
            '<div style="position:relative;" ><span style="color:' +
            this.point.color +
            '">' +
            this.series.name +
            '</span>: <b>' +
            value +
            '</b><br/></div>'
          );
        }
      };
    }

    if (highchartOptions.chartType === 'timeline') {
      chartOptions.tooltip = {
        useHTML: true,
        headerFormat:
          '<span style="color:{point.color}">●</span> <span style="font-size: 10px"> <b>{point.key}</b></span><br/>'
      };
    }

    if (chartId == 'asset-status-trend') {
      chartOptions.colors = ['#f15c80', '#f28f43'];
    }

    if (highchartOptions.isStackedColumn) {
      chartOptions.plotOptions.column = {
        stacking: 'normal'
      };
    }

    if (chartId.includes('statusChartMapModal')) {
      chartOptions.plotOptions.series.stacking = 'normal';
      chartOptions.chart.height = 80;
      chartOptions.chart.width = 320;
      chartOptions.yAxis = {
        visible: false
      };
    }

    if (highchartOptions.clickFunctionEnabled) {
      chartOptions.plotOptions.series.point = {
        events: {
          click: (event: Highcharts.PointClickEventObject) =>
            this.onChartClickModalHandler(event, highchartOptions.functionName)
        }
      };
    }
    if (chartId == 'tyreChart') {
      chartOptions.yAxis['plotLines'] = [
        {
          value: highchartOptions.extraData['minPressure'],
          color: 'green',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: 'minimum pressure'
          }
        },
        {
          value: highchartOptions.extraData['maxPressure'],
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: 'maximum pressure'
          }
        }
      ];
    }
    if (chartId.includes('processing-stack-chart') || chartId.includes('da-asset-status-chart-id')) {
      chartOptions.plotOptions.series.stacking = 'normal';
      chartOptions.chart.height = 100;
      chartOptions.yAxis = {
        visible: false
      };
    }

    if (chartId.includes('trend-heatmap')) {
      chartOptions.xAxis = {
        type: 'category',
        categories: highchartOptions.xAxisKeys,
        labels: {
          useHTML: true,
          formatter: function () {
            const value = this.value.toString();
            if (value.length > 12) {
              return '<span title=' + value + '>' + '...' + value.substring(value.length - 12) + '</span>';
            }
            return value;
          }
        }
      };
      chartOptions.tooltip = {
        useHTML: true,
        backgroundColor: '#f5f5f5',
        formatter: function (this: any) {
          if (highchartOptions.chartType.includes('heatmap')) {
            const unit = this.series.name == TrendCharts.UTILIZATION ? '%' : 'L';
            return `${getPointCategoryName(this.point, 'y')}
              <br>
              <div style="position:relative;"><span>
              ${getPointCategoryName(this.point, 'x')}
              </span>: <b>
              ${this.point.value}
              ${unit}
              </b><br/></div>`;
          } else {
            return `${this.key}
              <br> <div style="position:relative;"><span>
              ${this.series.name}
              </span>: <b>
              ${this.point.y}
              %</b><br/></div>`;
          }
        }
      };
      if (highchartOptions.chartType === 'heatmap') {
        chartOptions.plotOptions.series.dataLabels = {
          enabled: true,
          color: '#000000'
        };
        chartOptions.colorAxis = {
          min: 0,
          visible: false,
          minColor: '#FFFFFF',
          max: highchartOptions.colorAxisMin,
          /* stops: [
            [0.2, '#ff0000'],
            [0.5, '#ffea00'],
            [1, '#00FF00']
          ] */
          maxColor: Highcharts.getOptions().colors[0]
        };
      }
    }

    if (!isNullOrUndefined(highchartOptions.innerCircleSize)) {
      chartOptions.subtitle = {
        useHTML: true,
        text: highchartOptions.chartSubTitle,
        style: {
          fontSize: '1.3rem'
        },
        verticalAlign: 'middle',
        y: 0
      };
      chartOptions.plotOptions = {
        ...chartOptions.plotOptions,
        pie: {
          ...chartOptions.plotOptions?.pie,
          innerSize: highchartOptions.innerCircleSize,
          dataLabels: {
            enabled: false
          },
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true
        }
      };
      chartOptions.legend = {
        verticalAlign: 'bottom'
      };
    }

    function getPointCategoryName(point: any, dimension: string): string {
      const series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']].name;
    }
    return Highcharts.chart(chartId, chartOptions);
  }
}
