import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardApiPrefixUrl: String = '/v1/dashboard/';
  caseApiPrefixUrl: String = '/v1/case/';
  alertApiPrefixUrl: String = '/v1/alert/';

  constructor(private http: HttpClient) {}

  getAllAlertCount(siteId: String): Observable<any> {
    return this.http.get(this.alertApiPrefixUrl + 'getAllAlertCount/' + '?siteId=' + siteId);
  }

  getAllCaseCount(siteId: String): Observable<any> {
    return this.http.get(this.caseApiPrefixUrl + 'getAllCaseCount/' + '?siteId=' + siteId);
  }

  getAssetStatus(siteId: String): Observable<any> {
    return this.http.get(this.dashboardApiPrefixUrl + 'getSensorStatus/' + siteId);
  }

  getHalfHourIntervalData(siteId: string): Observable<any> {
    return this.http.get(`${this.dashboardApiPrefixUrl}halfHourIntervalData/${siteId}`);
  }

  getOpenAlertBySiteId(siteId: String): Observable<any> {
    return this.http.get(this.alertApiPrefixUrl + 'getOpenAlertBySiteId/' + '?siteId=' + siteId);
  }

  getAlertsByStatus(alertStatus: String, siteId: String): Observable<any> {
    return this.http.get(this.alertApiPrefixUrl + 'getAlertsByStatus/' + alertStatus + '?siteId=' + siteId);
  }

  getCasesByStatus(caseStatus: String, siteId: String): Observable<any> {
    return this.http.get(this.caseApiPrefixUrl + 'getCasesByStatus/' + caseStatus + '?siteId=' + siteId);
  }

  getOneMonthAlertData(siteId: string): Observable<any> {
    return this.http.get(this.alertApiPrefixUrl + 'getOneMonthAlertData/' + siteId);
  }

  getOneMonthCasesData(siteId: string): Observable<any> {
    return this.http.get(this.caseApiPrefixUrl + 'getOneMonthCasesData/' + siteId);
  }

  generateQuarterHourIntervalData(date: string, siteId: number): Observable<any> {
    return this.http.get(`${this.dashboardApiPrefixUrl}generateQuarterHourIntervalData/${siteId}/${date}`);
  }
}
