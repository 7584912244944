<div class="chart-card">
  <div>
    <div class="container-header">
      <div class="title">
        <p class="heading">{{ data.title }}</p>
      </div>
      <app-modal-close-btn class="close-icon" btnSize="larger" btnAlignment="top-1" [closeByMethod]=true (closeDialog)="cancel()">
      </app-modal-close-btn>
    </div>
    <hr class="hr-line" />
  </div>
  <div>
    <div class="chart-container">
      <div class="graph">
        <div id="scatter-chart-id"></div>
        <div class="is-data-found" *ngIf="isLoading">
          <p>Loading...</p>
        </div>
        <div class="is-data-found" *ngIf="!isLoading && !isDataFound">
          <p>No Data Found</p>
        </div>
      </div>
    </div>
  </div>
</div>
