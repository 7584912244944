import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '../function-util';

@Component({
  selector: 'app-radio-group-modal',
  templateUrl: './radio-group-modal.component.html',
  styleUrls: ['./radio-group-modal.component.scss']
})
export class RadioGroupModalComponent implements OnInit {

  titleName = '';
  textFieldName = '';
  options = [];
  selectedOption: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      textFieldName: string,
      defaultSelected: {},
      options: []
    },
    public dialogRef: MatDialogRef<RadioGroupModalComponent>
  ) { }

  ngOnInit(): void {
    this.options = this.data.options;
    this.titleName = this.data.title;
    this.textFieldName = this.data.textFieldName;
    if (!isNullOrUndefined(this.data.defaultSelected)) {
      this.selectedOption.setValue(this.data.defaultSelected);
    }
  }

  submit(): void {
    this.dialogRef.close(this.selectedOption.value);
  }

  close(): void {
    this.dialogRef.close();
  }
}
