import { Component, Input, TemplateRef } from '@angular/core';

// Using ng zorro boolean attribute for handling boolean input
// can be changed in later angular version to be handled natively
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'app-step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.scss']
})
export class StepCardComponent {
  static ngAcceptInputType_showArrow: BooleanInput;
  static ngAcceptInputType_noIconBorder: BooleanInput;

  @Input() stepTitle: string | TemplateRef<void>;
  @Input() stepSubtitle?: string;
  @Input() stepTitleColor? = '#585858';
  @Input() stepSubtitleColor? = '#8F8F8F';
  @Input() faIconClass? = 'fa-hashtag';
  @Input() faIconColor? = '#1F60C0';

  @Input() @InputBoolean() showArrow = false;
  @Input() @InputBoolean() noIconBorder = false;

  constructor() {}
}
