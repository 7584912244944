import { Component } from '@angular/core';
import { IAfterGuiAttachedParams, ICellEditorComp, ICellEditorParams, AgPromise } from 'ag-grid-community';

@Component({
  selector: 'app-date-cell-editor',
  template: ``,
  styles: []
})
export class DateCellEditorComponent implements ICellEditorComp {
  eInput!: HTMLInputElement;
  getValue() {
    return this.eInput.value;
  }
  isPopup?(): boolean {
    return false;
  }
  getGui(): HTMLElement {
    return this.eInput;
  }
  destroy?(): void {}
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.eInput.focus();
    this.eInput.select();
  }
  init?(params: ICellEditorParams): void | AgPromise<void> {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    this.eInput.classList.add('ag-input');
    this.eInput.style.height = '100%';
    this.eInput.style.width = '100%';
    this.eInput.setAttribute('type', 'date');
  }
}
