import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderSideBarService {
  baseUrl = '/v1/dashboard/';
  isSidebarVisible = false;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }

  getDashboardModule(module: string): Observable<any> {
    return this.http.get(this.baseUrl + 'getDashboardSubModule/' + module);
  }
}
