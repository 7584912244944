<div class="add-stats">
  <div class="add-stats-header">
    <p class="heading">{{ title }}</p>
    <div class="modal__title-close">
      <app-modal-close-btn btnSize="large" [closeByMethod]=true (closeDialog)="cancelModalForOverviewAddStats()">
      </app-modal-close-btn>
    </div>
  </div>
  <p class="sub-heading">Choose the stats you want to see in your dashboard.</p>
  <div class="option-group" *ngIf="statsType === paramUtilStatsType.CHECKBOX_SELECTION" [formGroup]="analyticsForm">
    <div class="option-category" *ngFor="let option of analyticsStatsOptions | keyvalue">
      <p class="heading">{{ option.key }}</p>
      <div class="options" *ngFor="let field of option.value" (click)="toggleAnalyticsFormValue(field.chartId)">
        <input type="checkbox" formControlName="{{ field.chartId }}" />
        <div class="option-body">
          <p class="body-heading">{{ field.name }}</p>
          <p class="body-font">{{ field.body }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="asset-selection" *ngIf="statsType === paramUtilStatsType.ASSET_SELECTION">
    <mat-form-field appearance="outline">
      <mat-label>Asset Name</mat-label>
      <mat-select placeholder="Select Asset" [(ngModel)]="selectedAssetId">
        <input class="input-field dropdown-filter" type="text" [formControl]="assetFilter"
          placeholder="Search Asset Name" (ngModelChange)="filterAssets()" />
        <mat-option *ngFor="let asset of filteredAssetList" [value]="asset.assetId" required>
          {{ asset.assetName }}
        </mat-option>
      </mat-select>
      <mat-hint align="start" class="mat-hint-primary" *ngIf="assetList.length !== 0">Asset will be displayed for
        selected site</mat-hint>
      <mat-hint align="end" class="mat-hint-danger" *ngIf="assetList.length === 0">No assets are available in selected
        site</mat-hint>
    </mat-form-field>
  </div>
  <div class="btn-row">
    <div class="warning" *ngIf="statsType === paramUtilStatsType.CHECKBOX_SELECTION">
      <span *ngIf="analyticsForm.errors.minRequiredChecked">
        Please select atleast {{ validatorCount.minRequired }} options.
      </span>
      <span *ngIf="analyticsForm.errors.maxRequiredChecked">
        Please select atmost {{ validatorCount.maxRequired }} options.
      </span>
    </div>
    <div class="btn">
      <button class="primary-btn" (click)="cancelModalForOverviewAddStats()">Cancel</button>
      <button
        class="secondary-btn"
        [disabled]="analyticsForm?.errors?.minRequiredChecked || analyticsForm?.errors?.maxRequiredChecked"
        (click)="okModal()">
        Save
      </button>
    </div>
  </div>
</div>
