import { NgModule } from '@angular/core';

import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTreeModule } from 'ng-zorro-antd/tree';

const modules = [
  NzSliderModule,
  NzStepsModule,
  NzDividerModule,
  NzSpinModule,
  NzInputModule,
  NzTreeSelectModule,
  NzFormModule,
  NzButtonModule,
  NzSelectModule,
  NzCheckboxModule,
  NzTabsModule,
  NzModalModule,
  NzCollapseModule,
  NzPopconfirmModule,
  NzDropDownModule,
  NzIconModule,
  NzTagModule,
  NzRadioModule,
  NzDatePickerModule,
  NzUploadModule,
  NzBadgeModule,
  NzPopoverModule,
  NzTimePickerModule,
  NzSwitchModule,
  NzProgressModule,
  NzTreeViewModule,
  NzTableModule,
  NzCardModule,
  NzSpaceModule,
  NzSegmentedModule,
  NzEmptyModule,
  NzAlertModule,
  NzTreeModule
];
@NgModule({
  imports: [...modules],
  exports: [...modules]
})
export class NgZorroAntdModule {}
