import { Component, Inject, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CodeInputComponent } from 'angular-code-input';
import { isNullOrUndefined } from '../function-util';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-common-passcode-modal',
  templateUrl: './common-passcode-modal.component.html',
  styleUrls: ['./common-passcode-modal.component.scss']
})
export class CommonPasscodeModalComponent {
  @ViewChild('codeInput', { static: true }) codeInput: CodeInputComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { codeLength: number; passcodeChecker: Function; backUrl?: string },
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CommonPasscodeModalComponent>,
    private router: Router,
    private location: Location
  ) {
    this.codeLength = data.codeLength;
    dialogRef.disableClose = true;
  }

  titleName = 'Enter Passcode';
  passcode: string;
  checkerServiceResponse: number;
  codeLength: number;

  cancel(): void {
    if (!isNullOrUndefined(this.data.backUrl)) {
      if (this.data.backUrl === 'back') {
        this.location.back();
      } else this.router.navigate([this.data.backUrl]);
    }
    this.dialogRef.close('N');
  }

  confirm(): void {
    this.dialogRef.close(this.checkerServiceResponse);
  }

  onCodeCompleted(code: string): void {
    this.passcode = code;
    // function to get operator here
    this.data.passcodeChecker(Number(this.passcode)).subscribe((res: number) => {
      this.checkerServiceResponse = res;
      if (!isNullOrUndefined(this.checkerServiceResponse)) {
        this.confirm();
      } else {
        this.openSnackBar('Wrong pass code, please try again', 'error');
        this.codeInput.reset(true);
      }
    });
  }

  openSnackBar(message: string, type: string, duration: number = 5000): void {
    this.snackBar.open(message, 'X', {
      duration: duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      panelClass:
        type === 'success'
          ? ['success-snackbar']
          : type === 'error'
          ? ['error-snackbar']
          : type === 'info'
          ? ['info-snackbar']
          : null
    });
  }
}
