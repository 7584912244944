import { Component } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';

@Component({
  selector: 'app-cell-edit-renderer',
  templateUrl: './cell-edit-renderer.component.html',
  styleUrls: ['./cell-edit-renderer.component.scss']
})
export class CellEditRendererComponent extends AgCellRendererBase {
  isWorkflowlocked: boolean;
  canEdit = true;
  isCurrentRowEditing = false;

  constructor() {
    super();
  }

  ngOnInit() {
    const currentUserId = sessionStorage.getItem('currentUserId');
    if (this.params.data.lockedByUserId > 0) {
      this.isWorkflowlocked = true;
      if (parseInt(currentUserId) === this.params.data.lockedByUserId) {
        this.canEdit = true;
      } else {
        this.canEdit = false;
      }
    } else {
      this.isWorkflowlocked = false;
      this.canEdit = true;
    }

    let editingCells = this.params.api.getEditingCells();
    this.isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === this.params.node.rowIndex;
    });
  }

  onUpdate(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.UPDATE_EVENT, event);
  }

  onCancel(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.CANCEL_EVENT, event);
  }

  onEdit(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.EDIT_EVENT, event);
  }

  onDelete(event: MouseEvent | any) {
    this.sendEvent(AgCellRendererEvent.DELETE_EVENT, event);
  }
}
