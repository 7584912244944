<div class="modal-header">
  <h1 mat-dialog-title>Upload Document</h1>
  <div class="closeButton pb-2">
    <app-modal-close-btn btnSize="large" [closeByMethod]=true (closeDialog)="cancel()"></app-modal-close-btn>
  </div>
</div>
<div mat-dialog-content class="d-flex flex-column justify-content-center align-items-center pt-4">
  <div class="row" *ngIf="!assetReceived" style="padding-bottom: 15px; width: 50%">
    <div class="col">
      <div class="d-flex justify-content-around align-items-center">
        <span class="text-color-gray">Select Asset</span>
        <!--        <input type="text" />-->
        <nz-select [(ngModel)]="assetId" nzShowSearch nzAllowClear nzPlaceHolder="Select Asset" style="width: 60%">
          <nz-option
            *ngFor="let asset of filteredAssetList"
            [nzValue]="asset.assetId"
            [nzLabel]="asset.assetName"></nz-option>
        </nz-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" *ngIf="!isDefaultCategorySelected">
      <mat-form-field appearance="outline">
        <mat-label>Document Type</mat-label>
        <mat-select placeholder="Select Document Type" [(value)]="requestObj.fileCategory">
          <mat-option *ngFor="let category of fileCategories" [value]="category">
            {{ category }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <label class="custom-file-upload">
        <input type="file" (change)="onFileSelected($event)" />
      </label>
    </div>
  </div>
  <div class="row" style="width: 40%" *ngIf="!isDefaultCategorySelected">
    <div class="col">
      <div class="d-flex justify-content-around align-items-center">
        <span class="text-color-gray">Expiry Date</span>
        <input class="date-picker" type="date" [(ngModel)]="requestObj.expiration" />
      </div>
    </div>
  </div>
</div>
<mat-card-footer>
  <div class="d-flex justify-content-center align-items-center text-center pt-4">
    <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
  </div>
</mat-card-footer>
