<div class="ag-trade-summary-renderer">
  <div>
    <button
      class="btn icon-btn"
      container="body"
      placement="right"
      title="Click for Trade Info"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
    </button>
  </div>
</div>

<ng-template #popTitle>
  <div class="pop-header-container">
    Asset Info
    <span class="header-close" role="button">&times;</span>
  </div>
</ng-template>

<ng-template #popContent>
  <ul class="pop-body-container">
    <li class="pb-2">
      <div class="summaryText">
        <div class="mainText">{{ params?.data?.assetName }}</div>
        <!--               <span class="subText">
                  {{
                      [
                          ['B', 'S'].includes(d.actionType) ? '' : (d.expiration | date:'MMM dd `yy'),
                          (d.strikePrice | currency:'USD'),
                          ['B', 'S'].includes(d.actionType) ? '' : getCallPutLabel(d.optionType)
                      ].join(' ').trim()
                  }}
              </span> -->
      </div>
    </li>
  </ul>
</ng-template>
