export enum FilterType {
  radio,
  checkbox
}

export enum CheckupStatus {
  OK = 'OK',
  NOT_OK = 'NOT_OK',
  NOT_CHECKED = 'NOT_CHECKED'
}

export enum GeoFenceCategory {
  POLYLINE = 'POLYLINE',
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE'
}

export enum GeoFenceAreaType {
  MINING = 'MINING',
  PLANT = 'PLANT',
  DUMP_YARD = 'DUMP YARD',
  BOUNDARY_GEOFENCE = 'BOUNDARY GEOFENCE',
  OTHERS = 'OTHERS',
  DYNAMIC = 'DYNAMIC'
}

export enum DashboardModuleHeader {
  OVERVIEW = 'Overview',
  FLEET = 'Fleet',
  FUEL = 'Fuel',
  MAINTENANCE = 'Maintenance',
  OPERATOR = 'Operator',
  REPAIR = 'Repair'
}

export enum CustomDateFilterOptions {
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM = 'CUSTOM',
  MONTH = 'MONTH'
}

export enum ReportFilters {
  COLUMN_FILTER = 'COLUMN'
}

export enum AllReport {
  LIVE_LOCATION_REPORT = 'LIVE_LOCATION_REPORT',
  VEHICLE_STOPPAGE_REPORT = 'VEHICLE_STOPPAGE_REPORT',
  VEHICLE_TRAVEL_REPORT = 'VEHICLE_TRAVEL_REPORT',
  ALERT_REPORT = 'ALERT_REPORT',
  HOUR_REPORT = 'HOUR_REPORT',
  TRIP_REPORT_BY_ASSET = 'TRIP_REPORT_BY_ASSET',
  VEHICLE_SUMMARY_REPORT = 'VEHICLE_SUMMARY_REPORT',
  DETAIL_REPORT = 'DETAIL_REPORT',
  ASSET_MOVE_REPORT = 'ASSET_MOVE_REPORT',
  SHIFTWISE_TRIP_REPORT_BY_ASSET = 'SHIFTWISE_TRIP_REPORT_BY_ASSET'
}

export enum tripCategory {
  RECLAMATION = 'Reclamation'
}

export enum OperatorProcessingModule {
  OPERATOR_SELECTED_REPORT = 'operator-selected-report'
}

export enum TrendCharts {
  UTILIZATION = 'UTILIZATION',
  FUEL_USAGE = 'FUEL_USAGE',
  AVAILABLE_BY_DAY = 'AVAILABLE_BY_DAY',
  AVAILABLE_BY_ASSET = 'AVAILABLE_BY_ASSET'
}

export enum ServiceReminderStatus {
  SERVICE_DONE = 'Service_Done'
}

export enum AlertParams {
  INSTALL_NEW_SENSOR = 'INSTALL_NEW_SENSOR',
  UNINSTALL_EXISTING_SENSOR = 'UNINSTALL_EXISTING_SENSOR',
  MOVE_ASSET_TO_ANOTHER_SITE = 'MOVE_ASSET_TO_ANOTHER_SITE'
}

export enum PartTypes {
  ASSET_PART = 'ASSET_PART',
  PROJECT_PART = 'PROJECT_PART',
  PETTY_CASH = 'PETTY_CASH'
}

export enum PartsCategoryType {
  ASSET = 'ASSET',
  PETTY_CASH = 'PETTY_CASH',
  PROJECT = 'PROJECT'
}

export enum PartsOperationType {
  ADD = 'ADD',
  CONSUME = 'CONSUME'
}

export enum DateFormat {
  DATE = 'YYYY-MM-DD'
}

export enum GeneralInfoDomain {
  OVERVIEW_CHART_NAME = 'OVERVIEW_CHART_NAME'
}

export enum GeoFenceModalType {
  UPLOAD = 'Upload'
}

export enum PreStartStatus {
  OK = 'OK',
  NOT_OK = 'NOT_OK',
  NOT_CHECKED = 'NOT_CHECKED'
}
