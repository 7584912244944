import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'src/app/shared/function-util';
import { ChartDataRequest } from 'src/app/shared/interfaces/chart-data-request';
import { ChartDataResponse } from 'src/app/shared/interfaces/chart-data-response';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  overviewApiPrefixUrl = '/v1/overview/';
  fuelApiPrefixUrl: String = '/v1/fuel/';
  requestApi: String = '/v1/user';
  nridaApi: String = '/v1/nrida/';

  constructor(private http: HttpClient) {}

  getWorkHoursBySite(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getAssetWorkRunHours/' + siteId);
  }

  getWorkDistanceBySite(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getAssetWorkRunDistance/' + siteId);
  }

  getTripCountBySite(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getAssetTripCountDetails/' + siteId);
  }

  getTripCountProductionChart(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getTripCountProduction/' + siteId);
  }

  getWorkHourTrend(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getWorkHourTrend/' + siteId);
  }

  getWorkHourTrendWeekly(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getWorkHourTrendWeekly/' + siteId);
  }

  getWorkHourTrendMonthly(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getWorkHourTrendMonthly/' + siteId);
  }

  getDistanceTrend(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getDistanceTrend/' + siteId);
  }

  getHighestIdlingDuration(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getHighestIdlingDuration/' + siteId);
  }

  getWeeklyIdlingCostTrend(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getWeeklyIdlingCostTrend/' + siteId);
  }

  getThreeMonthIdlingCostTrend(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getThreeMonthIdlingCostTrend/' + siteId);
  }

  getFuelInTank(siteId: string, siteType: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelInTank/' + siteId + '?siteType=' + siteType);
  }

  getTwoMonthFuelConsumption(siteId: string, siteType: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getTwoMonthFuelConsumption/' +
        siteId +
        '/' +
        fromDate +
        '/' +
        toDate +
        '?siteType=' +
        siteType
    );
  }

  getTwoMonthTripCountByCategory(
    siteId: string,
    category: string,
    siteType: string,
    fromDate: string,
    toDate: string,
    plantId?: number
  ): Observable<any> {
    let params = new HttpParams();
    if (!isNullOrUndefined(plantId)) {
      params = params.append('plantId', plantId);
    }
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getTwoMonthTripCountByCategory/' +
        siteId +
        '/' +
        category +
        '/' +
        fromDate +
        '/' +
        toDate +
        '?siteType=' +
        siteType,
        { params: params }
    );
  }

  getAnalyticsSpecification(siteId: string, siteType: string, fromDate: string, toDate: string): Observable<any> {
    const params = new HttpParams().append('fromDate', fromDate).append('toDate', toDate).append('siteType', siteType);
    return this.http.get(this.overviewApiPrefixUrl + 'getAnalyticsSpecification/' + siteId, { params });
  }

  getFuelConsumptionTrendByAssets(siteId: string, siteType: string, days: number): Observable<any> {
    const params = new HttpParams().append('siteType', siteType).append('days', days);
    return this.http.get(this.overviewApiPrefixUrl + 'getFuelConsumptionTrendByAssets/' + siteId, { params: params });
  }

  getHoursTrend(siteId: string, siteType: string): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getHoursTrend/' + siteId + '?siteType=' + siteType);
  }

  getServiceReminderByStatus(siteId: string, status: string, siteType: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl + 'getServiceReminderByStatus/' + siteId + '/' + status + '?siteType=' + siteType
    );
  }

  getHalfMonthTripCountByCategory(siteId: string, category: string, siteType: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl + 'getHalfMonthTripCountByCategory/' + siteId + '/' + category + '?siteType=' + siteType
    );
  }

  getTwoMonthKilometer(siteId: string, siteType: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getTwoMonthKilometer/' +
        siteId +
        '/' +
        fromDate +
        '/' +
        toDate +
        '?siteType=' +
        siteType
    );
  }

  getTwoMonthHourRun(siteId: string, siteType: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getTwoMonthHourRun/' +
        siteId +
        '/' +
        fromDate +
        '/' +
        toDate +
        '?siteType=' +
        siteType
    );
  }

  getDashboardModulesBySite(siteId: string, dashboardModule: string): Observable<any> {
    return this.http.get(this.requestApi + '/getDashboardModulesBySite/' + siteId + '/' + dashboardModule);
  }

  getOverviewModulesByClientOrSite(siteId: string, siteType: string, dashboardModule: string): Observable<any> {
    return this.http.get(
      this.requestApi + '/getOverviewModulesByClientOrSite/' + siteId + '/' + siteType + '/' + dashboardModule
    );
  }

  setModulesBySite(siteId: string, response: any, dashboardModule: string): Observable<any> {
    return this.http.post(this.requestApi + '/setModulesBySite/' + siteId + '/' + dashboardModule, response);
  }

  getSiteTypeByClient(): Observable<any> {
    return this.http.get(this.requestApi + '/getSiteTypeByClient');
  }

  getTwoMonthUtilization(siteId: string, siteType: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getTwoMonthUtilization/' +
        siteId +
        '/' +
        fromDate +
        '/' +
        toDate +
        '?siteType=' +
        siteType
    );
  }

  getPtaAnalyticsByAsset(chartDataRequest: ChartDataRequest): Observable<Array<ChartDataResponse>> {
    return this.http.post<Array<ChartDataResponse>>(
      this.overviewApiPrefixUrl + 'getPtaAnalyticsByAsset',
      chartDataRequest
    );
  }

  getTwoMonthProcessing(siteId: string, fromDate: string, toDate: string, plantId?: number): Observable<any> {
    const initialParams = plantId ? new HttpParams().append('plantId', plantId) : new HttpParams();
    const params = initialParams.append('fromDate', fromDate).append('toDate', toDate);
    return this.http.get(this.overviewApiPrefixUrl + 'getTwoMonthProcessing/' + siteId, { params });
  }

  getProcessingData(
    siteId: string,
    category: string,
    siteType: string,
    fromDate: string,
    toDate: string,
    plantId: number
  ): Observable<any> {
    const params = new HttpParams().append('fromDate', fromDate).append('toDate', toDate)
    .append('plantId', plantId).append('siteType', siteType).append('category', category);
    return this.http.get(
      this.overviewApiPrefixUrl +
        'getProcessingData/' +
        siteId, { params }
    );
  }

  getSitesByStateForNRIDA(): Observable<any> {
    return this.http.get<any>(`${this.nridaApi}getSitesByStateForNRIDA`);
  }

  getGeoSadakDataForNRIDA(clientId: number, siteId: number): Observable<any> {
    return this.http.get<any>(`${this.nridaApi}getGeoSadakDataForNRIDA/${clientId}/${siteId}`);
  }

  getMapData(siteId: number): Observable<any> {
    return this.http.get<any>(`/v1/map/mapData/${siteId}`);
  }

  getGeneralInfoDataByPropertyDomain(
    propertyDomain: string,
    byClient: boolean,
    byUser: boolean,
    byPropertyCode: string
  ): Observable<any> {
    const params = new HttpParams()
      .append('propertyDomain', propertyDomain)
      .append('byClient', byClient)
      .append('byUser', byUser)
      .append('byPropertyCode', byPropertyCode);
    return this.http.get(this.requestApi + '/getGeneralInfoDataByPropertyDomain', { params });
  }
}

//http://dev.cognecto.com/iot/v1/nrida/getGeoSadakDataForNRIDA/-1/-1
