<!-- <mat-card [ngClass]="\mat-label-card" style="height: 25rem;"> -->
<mat-card class="mat-label-card">
  <mat-card-content>
    <div class="d-flex chart-card-title">{{ title }}</div>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      style="float: right; top: -3rem; left: 3px; padding: 0.5px 8px"
      *ngIf="isExportIcon">
      <mat-icon>menu</mat-icon>
    </button>

    <div *ngIf="settingbuttonVisible">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu1"
        style="float: right; top: -3rem; left: 3px; padding: 0.5px 8px">
        <mat-icon>edit</mat-icon>
      </button>
    </div>

    <div [id]="chartId" class="highchart-card"></div>
  </mat-card-content>
</mat-card>

<mat-menu #menu1="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="menuItem1">{{ XAxisFilterName }}</button>
  <button mat-menu-item *ngIf="isDateMenuEnabled" [matMenuTriggerFor]="menuItem2">{{ dateFilterName }}</button>
</mat-menu>

<mat-menu #menuItem1="matMenu">
  <div class="d-flex flex-column p-2" (click)="$event.stopPropagation()">
    <mat-checkbox *ngFor="let item of XAxisFilterItem" [(ngModel)]="item.isChecked" (change)="filterChartData()">
      {{ item.name }}
    </mat-checkbox>
  </div>
</mat-menu>

<mat-menu #menuItem2="matMenu">
  <div *ngFor="let item of dateFilterItem">
    <button mat-menu-item [value]="item" (click)="filterChartData1($event)">{{ item }}</button>
  </div>
</mat-menu>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="chart.fullscreen.toggle()" [ngStyle]="matMenuButtonStyle">View in full screen</button>
  <button mat-menu-item (click)="chart.print()" [ngStyle]="matMenuButtonStyle">Print chart</button>
  <hr style="margin: 2px 0px 2px" />
  <button mat-menu-item (click)="exportChart('image/png')" [ngStyle]="matMenuButtonStyle">Download PNG image</button>
  <button mat-menu-item (click)="exportChart('image/jpeg')" [ngStyle]="matMenuButtonStyle">Download JPEG image</button>
  <button mat-menu-item (click)="exportChart('application/pdf')" [ngStyle]="matMenuButtonStyle"
    >Download PDF document</button
  >
  <button mat-menu-item (click)="exportChart('image/svg+xml')" [ngStyle]="matMenuButtonStyle"
    >Download SVG vector image</button
  >
  <hr style="margin: 2px 0px 2px" />
  <!-- The below two function calls are not errors even if it shows that way -->
  <!--   <button mat-menu-item (click)="chart.downloadCSV()" [ngStyle]="matMenuButtonStyle">Download CSV</button>
  <button mat-menu-item (click)="chart.downloadXLS()" [ngStyle]="matMenuButtonStyle">Download XLS</button> -->
</mat-menu>
