import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { isNullOrUndefined, getTimeInHHMMSS } from 'src/app/shared/function-util';
import { ChartDetailInfoParams } from './chart-detail-info-params';

@Component({
  selector: 'app-chart-detail-info',
  templateUrl: './chart-detail-info.component.html',
  styleUrls: ['./chart-detail-info.component.scss']
})
export class ChartDetailInfoComponent implements OnInit {
  // Passed parameters
  // modalHeader = "";
  // assetList = [];
  // parameterColumn = "";
  // date: string;
  // Component variables and constants

  ESCAPE_KEYCODE = 27;

  chartInfoDetailParams: ChartDetailInfoParams;
  selectedAsset: any;
  errorMessage = '';

  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  columnDefs;
  context;
  frameworkComponents;
  rowData = [];
  columnHeaderName = '';
  columnValueName = '';
  columnTitles: { [parameterName: string]: string } = {
    fuelConsumption: 'Fuel (in L)',
    status: 'Status',
    tripCount: 'Trip Count',
    totalHours: 'Hours',
    fuelPilferage: 'Fuel (in L)',
    workRunDistance: 'KM',
    alertCategory: 'Category',
    caseCategory: 'Category',
    value: 'Cost',
    efficiencyAvg: 'Efficiency',
    partName: 'Part Name',
    production: 'Production (in Tonnes)',
    fuelEconomy: 'Economy',
    createdDate: 'Created Date',
    idlingCost: 'Idling Cost',
    totalIdleHours: 'Idling Hours',
    idlePrecentage: 'Idle %',
    fuelInTank: 'Fuel(in L)',
    actualFuelConsumption: 'Fuel(in L)'
  };

  constructor(private activeModal: NgbActiveModal, private router: Router) {
    this.chartInfoDetailParams = {
      modalHeader: 'Equipment Details',
      assetList: [],
      parameterColumn: '',
      convertToTime: 'true',
      showDateColumn: false,
      middleColumnName: 'Asset Name',
      middleColumnParamter: 'assetName'
    };
    // this.activeModal.keyboard = true;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.closeModalForDashboardCharts();
    }
  }

  ngOnInit() {
    this.columnHeaderName = this.columnTitles[this.chartInfoDetailParams.parameterColumn]
      ? this.columnTitles[this.chartInfoDetailParams.parameterColumn]
      : '';
    this.columnValueName = this.chartInfoDetailParams.parameterColumn;
    this.chartInfoDetailParams.assetList.forEach((ele) => {
      if (isNumber(ele[this.columnValueName])) {
        if (ele[this.columnValueName] != 0) {
          this.rowData.push(ele);
        }
      } else {
        this.rowData = this.chartInfoDetailParams.assetList;
        return;
      }
    });
    this.rowData.forEach((ele) => {
      if (isNumber(ele[this.columnValueName])) {
        if (
          ['Hours', 'Idling Hours'].indexOf(this.columnHeaderName) >= 0 &&
          this.chartInfoDetailParams.convertToTime == 'true'
        ) {
          ele[this.columnValueName] = getTimeInHHMMSS(ele[this.columnValueName], 'hh:mm:ss');
        } else {
          ele[this.columnValueName] =
            ele[this.columnValueName] % 1 === 0
              ? Number(ele[this.columnValueName])
              : Number(ele[this.columnValueName].toFixed(2));
        }
      }
    });

    this.rowData.sort((a, b) => (a[this.columnValueName] < b[this.columnValueName] ? 1 : -1));
    this.initGrid();
    this.context = {
      componentParent: this
    };
  }

  defaultColDef = { resizable: true, sortable: true };

  initGrid() {
    this.columnDefs = [];
    if (this.chartInfoDetailParams.showDateColumn) {
      this.columnDefs.push({
        headerName: 'Date',
        field: 'date',
        width: 120,
        filter: 'agTextColumnFilter',
        suppressMenu: false,
        floatingFilterComponentParams: { suppressFilterButton: true }
      });
    } else {
      this.columnDefs.push({
        headerName: 'Site',
        field: 'siteName',
        width: 120,
        filter: 'agTextColumnFilter',
        suppressMenu: false,
        floatingFilterComponentParams: { suppressFilterButton: true }
      });
    }

    this.columnDefs.push({
      headerName: this.chartInfoDetailParams.middleColumnName,
      field: this.chartInfoDetailParams.middleColumnParamter,
      width: 210,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      floatingFilterComponentParams: { suppressFilterButton: true }
    });
    this.columnDefs.push({
      headerName: this.columnHeaderName,
      field: this.columnValueName,
      width: 150,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      floatingFilterComponentParams: { suppressFilterButton: true }
    });
  }

  gridSizeChanged() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;

    /*  params.api.addGlobalListener((type: string, e) => {
            if (type === "rowClicked") {
                this.router.navigate(['pages/models/asset-configuration-detail/' + this.gridApi.getSelectedRows()[0].assetId,{
                    'chartType' : this.chartInfoDetailParams.parameterColumn
                }]);
                this.closeModalForDashboardCharts();
            }
        })*/
  }

  isString = (value: string | number) => typeof value === 'string';

  closeModalForDashboardCharts() {
    this.activeModal.close('N');
  }

  clickedAsset(asset) {
    this.selectedAsset = asset;
  }

  navigate(page: 'map' | 'trend-analysis' | 'alerts' | 'cases' | 'configurator') {
    this.selectedAsset = this.gridApi.getSelectedRows()[0];
    if (!isNullOrUndefined(this.selectedAsset)) {
      this.activeModal.close('Y');
      switch (page) {
        case 'map': {
          this.router.navigate([
            'pages/dashboard/google-map',
            {
              assetId: this.selectedAsset.assetId,
              viewMode: 'route-info',
              // 'date': this.chartInfoDetailParams.date
              ...(this.chartInfoDetailParams.date && {
                date: this.chartInfoDetailParams.date
              })
            }
          ]);
          break;
        }
        case 'trend-analysis': {
          this.router.navigate([
            'pages/analytics/trend-analysis',
            {
              assetId: this.selectedAsset.assetId,
              tagId: this.chartInfoDetailParams.parameterColumn
            }
          ]);
          break;
        }
        case 'alerts': {
          this.router.navigate(['/pages', 'alerts', 'alert-list', 'all'], {
            queryParams: { assetName: this.selectedAsset.assetName }
          });
          break;
        }
        case 'cases': {
          this.router.navigate(['/pages', 'cases', 'case-list', 'all'], {
            queryParams: { assetName: this.selectedAsset.assetName }
          });
          break;
        }
        case 'configurator': {
          this.router.navigate(['pages/models/asset-configuration-detail', this.selectedAsset.assetId]);
          break;
        }
      }
    } else {
      this.errorMessage = 'Please select an asset to get detailed information!';
      setTimeout((e) => {
        this.errorMessage = '';
      }, 2000);
    }
  }
}
