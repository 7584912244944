import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-asset-interval-info-renderer',
  templateUrl: './asset-interval-info-renderer.component.html',
  styleUrls: ['./asset-interval-info-renderer.component.scss']
})
export class AssetIntervalInfoRendererComponent implements OnInit, ICellRendererAngularComp {
  constructor() {}

  ngOnInit(): void {}

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
