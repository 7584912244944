import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ExportingMimeTypeValue } from 'highcharts';
import { HighchartRenderService } from './render-highchart.service';
import { HighchartOptions } from './render-highcharts-options';
import Highcharts from 'highcharts';
import { isNullOrUndefined } from '../function-util';
@Component({
  selector: 'app-render-highchart',
  templateUrl: './render-highchart.component.html',
  styleUrls: ['./render-highchart.component.scss']
})
export class RenderHighchartComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() chartId: string;
  @Input() chartOptionsFunction: () => Promise<HighchartOptions>;

  dateFilterName: string = 'Date';
  XAxisFilterName: string = 'X-Axis';
  settingbuttonVisible: boolean = false;
  isDateMenuEnabled: boolean = false;
  isXAxisMenuEnabled: boolean = false;
  dateFilterItem: string[];
  XAxisFilterItem: any[] = [];
  chartFilterSeries: any[];
  chart: Highcharts.Chart;
  defaultValue: string;
  filterFunctionRef: Function;
  matMenuButtonStyle = {
    fontSize: 'x-small',
    height: '1.6rem',
    lineHeight: '0px'
  };
  isExportIcon = true;
  constructor(private highchartService: HighchartRenderService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.chartOptionsFunction().then(
      (response: HighchartOptions) => {
        if (response.chartFilterOptions) {
          this.settingbuttonVisible = true;
          this.filterFunctionRef = response.filterFunction;
          if (response.chartFilterOptions['dateFilter']['data'].length > 0) {
            this.isDateMenuEnabled = true;
            this.dateFilterItem = response.chartFilterOptions['dateFilter']['data'];
            this.dateFilterName = response.chartFilterOptions['dateFilter']['name'];
          }
          if (!isNullOrUndefined(response.xAxisKeys)) this.filterXAxis(response.xAxisKeys, 'xAxis');
          else this.filterXAxis(response.drillDownData, 'drillDown');
          this.chartFilterSeries = response.chartData;
        }
        if (!isNullOrUndefined(response.exportIcon)) {
          this.isExportIcon = response.exportIcon;
        }
        this.chart = this.highchartService.renderHighchart(this.chartId, response);
      },
      (reject: string) => console.error(`${this.chartId}: ${reject}`)
    );
  }

  private filterXAxis(response: any, type: string) {
    if (!type.localeCompare('xAxis')) {
      this.XAxisFilterItem = response.map((key: any) => {
        if (!isNullOrUndefined(key['categories'])) return { ...key, isChecked: true };
        else
          return {
            name: key,
            isChecked: true
          };
      });
    } else {
      this.XAxisFilterItem = response['series'].map((key: any) => {
        return { ...key, isChecked: true };
      });
    }
  }

  exportChart(type: ExportingMimeTypeValue) {
    this.chart.exportChart(
      {
        type: type,
        filename: this.title
      },
      null
    );
  }

  filterChartData() {
    const { data, ...series } = this.chartFilterSeries[0];
    series['data'] = [];
    const newXAxis = this.XAxisFilterItem.map((item) => {
      if (item.isChecked) {
        const { isChecked, ...others } = item;
        this.chartFilterSeries[0]['data'].forEach((seriesData) => {
          if (seriesData['name'].includes(others['name'])) series['data'].push(seriesData);
        });
        return others;
      } else {
        return null;
      }
    }).filter((item) => item != null);
    const newChart: Highcharts.Options = {
      xAxis: {
        categories: newXAxis
      },
      series: [series]
    };
    this.chart.update(newChart);
  }
  filterChartData1(event) {
    const filterKey = event.target.value;
    const { series, finalKey } = this.filterFunctionRef(event.target.value);
    this.filterXAxis(finalKey, 'xAxis');
    const newChart: Highcharts.Options = {
      subtitle: {
        text: filterKey
      },
      xAxis: {
        categories: finalKey
      },
      series: series
    };
    this.chart.update(newChart);
  }
}
