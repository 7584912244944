import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Assets } from 'src/app/shared/interfaces/assets';

@Injectable({
  providedIn: 'root'
})
export class AssetListService {
  requestApi: String = '/v1/asset';

  constructor(private http: HttpClient) {}

  getAllAssets(siteId: String): Observable<any> {
    return this.http.get(this.requestApi + '/getAllAssets' + '?siteId=' + siteId);
  }

  addUpdateAssetConfig(assetConfigDetails: any): Observable<any> {
    return this.http.post(this.requestApi + '/addUpdateAssetConfig', assetConfigDetails);
  }

  getAssetById(assetId: String): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetsById/' + assetId);
  }

  getAllAssetByAssetClass(siteId: String, assetClassName: string): Observable<Assets[]> {
    return this.http.get<Assets[]>(this.requestApi + '/getAllAssetByAssetClass/' + siteId + '/' + assetClassName);
  }

  updateStatusById(assetId: String, status: String): Observable<any> {
    return this.http.delete(this.requestApi + '/updateStatusByAssetId/' + assetId + '/' + status);
  }

  getBusInformation(): Observable<any> {
    return this.http.get(this.requestApi + '/getBusInfo');
  }

  createAssetByUser(data): Observable<any> {
    return this.http.post(this.requestApi + '/createAssetByUser', data);
  }

  getTrendDataByAsset(assetId: String): Observable<any> {
    return this.http.get(this.requestApi + '/getTrendDataByAsset/' + assetId);
  }

  getAlertsByAssetId(assetId: string): Observable<any> {
    return this.http.get(`/v1/map/alertsByAssetId/${assetId}`);
  }

  getCasesByAssetId(assetId: string): Observable<any> {
    return this.http.get(`/v1/map/casesByAssetId/${assetId}`);
  }

  uploadAssetDocument(assetDocFile: FormData): Observable<any> {
    return this.http.post(this.requestApi + '/uploadAssetDocument', assetDocFile);
  }

  getAssetDocumentDetails(assetId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetDocumentDetails/' + assetId);
  }

  getAllDocumentDetails(siteId: string): Observable<any> {
    return this.http.get(this.requestApi + '/getAllDocumentDetails/' + siteId);
  }

  deleteAssetDocument(fileId: number): Observable<any> {
    return this.http.delete(this.requestApi + '/deleteAssetDocument/' + fileId);
  }

  getAdc1ValueByAsset(assetId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getAdc1ValueByAsset/' + assetId);
  }

  getFuelSensorMatrix(assetId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getFuelSensorMatrix/' + assetId);
  }

  addUpdateFuelSensorCalibration(data): Observable<any> {
    return this.http.post(this.requestApi + '/addUpdateFuelSensorCalibration', data);
  }

  generateFuelMatrixEquation(assetId: number): Observable<any> {
    return this.http.get(this.requestApi + '/generateFuelMatrixEquation/' + assetId);
  }

  saveFuelMatrixEquation(data): Observable<any> {
    return this.http.post(this.requestApi + '/saveFuelMatrixEquation', data);
  }

  deleteFuelCalibrationReading(adc: string, assetId: number): Observable<any> {
    return this.http.delete(this.requestApi + '/deleteFuelCalibrationReading/' + adc + '/' + assetId);
  }

  getCalculatedMetricsForTime(
    imeiNumber: string,
    startTimestamp: number,
    endTimestamp: number,
    metricFetchMode: string
  ): Observable<any> {
    return this.http.get(this.requestApi + `/getCalculatedMetricsForTime/${imeiNumber}`, {
      params: {
        startTimestamp: String(startTimestamp),
        endTimestamp: String(endTimestamp),
        metricFetchMode: metricFetchMode
      }
    });
  }

  getAssetTimeStatus(assetId: number): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetTimeStatus/' + assetId);
  }

  getAssetCheckupDocumentDetails(assetId: number, expiration: string): Observable<any> {
    return this.http.get(this.requestApi + '/getAssetCheckupDocumentDetails/' + assetId + '/' + expiration);
  }

  getTextInImageByOcr(docFile): Observable<any> {
    return this.http.post(this.requestApi + '/getTextInImageByOcr', docFile);
  }

  getAssetComponent(assetId: String): Observable<any> {
    return this.http.get('/assetComponents/search/findByAsset_AssetId?assetId=' + assetId);
  }

  addAssetComponent(assetComponent): Observable<any> {
    return this.http.post('/assetComponents', assetComponent);
  }

  updateAssetComponent(updateUrl: string, assetComponent): Observable<any> {
    return this.http.patch(updateUrl, assetComponent);
  }

  deleteAssetComponent(deleteUrl: string): Observable<any> {
    return this.http.delete(deleteUrl);
  }

  savePreStartFilter(data): Observable<any> {
    return this.http.post(this.requestApi + '/savePreStartFilter', data);
  }

  getPreStartFilterList(): Observable<any> {
    return this.http.get(this.requestApi + '/getPreStartFilterList');
  }

  addUpdateAssetColor(assetConfigDetails: any): Observable<any> {
    return this.http.post(this.requestApi + '/addUpdateAssetColor', assetConfigDetails);
  }
}
