import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgCellRendererEvent } from './ag-cell-renderer.event';

export abstract class AgCellRendererBase implements ICellRendererAngularComp {
  params: ICellRendererParams;
  contextComponent: any;

  editButton = false;
  viewButton = false;
  deleteButton = false;
  activeButton = false;
  isActive = false;
  statusButton = false;
  isStatusActive = false;
  activeStatusButton: false;
  downloadButton = false;
  locationButton = false;
  tripHistoryButton = false;
  itemButton = false;
  fillColorButton = false;

  agInit(params: any): void {
    this.params = params;
    const context = params.context;
    this.editButton = context.editButton;
    this.viewButton = context.viewButton;
    this.deleteButton = context.deleteButton;
    this.activeButton = context.activeButton;
    this.fillColorButton = context.fillColorButton;
    this.isActive = params.data[context.isActive];
    if (params.data[context.isStatusActive] === 'ACTIVE') {
      this.isStatusActive = true;
    } else {
      this.isStatusActive = false;
    }
    this.activeStatusButton = context.activeStatusButton;
    this.statusButton = context.statusButton;
    this.downloadButton = context.downloadButton;
    this.locationButton = context.locationButton;
    this.tripHistoryButton = context.tripHistoryButton;
    this.itemButton = context.itemButton;
    if (!params.context || !params.context.componentParent) {
      const errMsg = `Hosting component must implement AgCellRendererEventHandler.
                And attribute [contex]="context" along with others in template is must -> <ag-grid-angular  [defaultColDef]="defaultColDef" ... [context]="context">.`;
      throw new Error(errMsg);
    } else {
      this.contextComponent = params.context.componentParent;
    }
  }

  sendEvent(type: string, event?: MouseEvent) {
    const rendererEvent: AgCellRendererEvent = { type, params: this.params, selectedValue: String(event) };
    this.contextComponent.handleAgRendererEvent(rendererEvent);

    if (event) {
      event.preventDefault();
    }
  }

  sendRedirectEvent(type: string, selectedValue?: string) {
    const rendererEvent: AgCellRendererEvent = {
      type,
      params: this.params,
      selectedValue
    };
    this.contextComponent.handleAgRendererEvent(rendererEvent);

    if (event) {
      event.preventDefault();
    }
  }

  refresh(): boolean {
    return false;
  }
}
