import { AfterViewInit, Component, Input } from '@angular/core';
import { HighchartOptions } from '../render-highchart/render-highcharts-options';
import { HighstockRenderService } from './render-highstock.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '../function-util';
import { LAST15DAYS, SCREEN_OVERVIEW } from '../param-util';
import { HighstockModalComponent } from './highstock-modal/highstock-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-render-highstock',
  templateUrl: './render-highstock.component.html',
  styleUrls: ['./render-highstock.component.scss']
})
export class RenderHighstockComponent implements AfterViewInit {
  constructor(private highstockService: HighstockRenderService, private router: Router, public dialog: MatDialog) {}

  @Input() title: string;
  @Input() chartId: string;
  @Input() chartOptionsFunction: () => Promise<HighchartOptions>;

  headerIcon: string;
  lastUpdatedMsg: string;
  isDataFound: boolean = false;

  indicateMsg: string = '';
  highChartOptionsData: HighchartOptions;

  ngAfterViewInit(): void {
    this.chartOptionsFunction().then(
      (response: HighchartOptions) => {
        this.highChartOptionsData = response;
        if (!isNullOrUndefined(response.extraData) && !isNullOrUndefined(response.extraData['lastUpdatedMsg'])) {
          this.lastUpdatedMsg = response.extraData['lastUpdatedMsg'];
        }
        if (!isNullOrUndefined(response.headerIcon) && response.headerIcon !== '') {
          this.headerIcon = response.headerIcon;
        }

        if (!isNullOrUndefined(response.extraData) && !isNullOrUndefined(response.extraData['indicateMsg'])) {
          this.indicateMsg = response.extraData['indicateMsg'];
        }
        const highChartOptionsData = this.chartId === 'top-fuel-consuming' ? this.filterData(response) : response;
        let trend = this.highstockService.renderHighstock(this.chartId, highChartOptionsData);
        this.isDataFound = true;
      },
      (reject) => {
        if (!isNullOrUndefined(reject['headerIcon'])) {
          this.headerIcon = reject['headerIcon'];
        }
        this.isDataFound = false;
      }
    );
  }

  filterData(highChartOptions: HighchartOptions): HighchartOptions {
    const clone = structuredClone(highChartOptions);
    const filteredData = clone.chartData[0].data.slice(0, 7);
    clone.chartData[0].data = filteredData;
    return clone;
  }

  openReport(chartId: string) {
    switch (chartId) {
      case 'ore-mining-connectedSourceChart':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: LAST15DAYS,
            chartType: chartId
          }
        ]);
        break;

      case 'plant-processing-connectedSourceChart':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: LAST15DAYS,
            chartType: chartId
          }
        ]);
        break;

      case 'rehabilitation-connectedSourceChart':
        this.router.navigate([
          '/pages/dashboard/operator',
          {
            sourceScreen: SCREEN_OVERVIEW,
            clickedButtonType: LAST15DAYS,
            chartType: chartId
          }
        ]);
        break;
      case 'fuel-tank-balance':
        this.router.navigateByUrl('/pages/dashboard/fuel-refill-report');
        break;
      case 'top-fuel-consuming':
        /* this.router.navigateByUrl('/pages/dashboard/fuel-refill-report'); */
        const height = this.highChartOptionsData.chartData[0].data.length * 50;
        this.openHighStockModal('Fuel Consumption', height);
        break;
    }
  }

  openHighStockModal(title: string, height: number): Observable<any> {
    const dialogRef = this.dialog.open(HighstockModalComponent, {
      width: '65vw',
      height: '75vh',
      data: {
        title: title,
        headerIcon: this.headerIcon,
        lastUpdatedMsg: this.lastUpdatedMsg,
        indicateMsg: this.indicateMsg,
        highChartOptions: { ...this.highChartOptionsData, height: height, exporting: { allowHTML: true } }
      }
    });
    return dialogRef.afterClosed();
  }
}
