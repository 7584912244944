<button [matMenuTriggerFor]="redirect" class="border-0">
  {{ params.value }}
</button>
<mat-menu #redirect="matMenu" yPosition="above" class="redirectRendererMenu">
  <button mat-menu-item (click)="onClickRedirect('alert')">
    <img src="../../../../assets/images/menu-icons/alerts.png" />&nbsp;Alerts
  </button>
  <button mat-menu-item (click)="onClickRedirect('case')">
    <img src="../../../../assets/images/menu-icons/cases.png" />&nbsp;Cases
  </button>
  <button mat-menu-item (click)="onClickRedirect('map')">
    <img src="../../../../assets/images/map.png" />&nbsp;Map
  </button>
  <button mat-menu-item (click)="onClickRedirect('asset')">
    <img src="../../../../assets/images/menu-icons/configurator.png" />&nbsp;Asset Config.
  </button>
</mat-menu>
