import { Component, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '../../function-util';
import { HighstockRenderService } from '../render-highstock.service';
import Highstocks from 'highcharts/highstock';

@Component({
  selector: 'app-highstock-modal',
  templateUrl: './highstock-modal.component.html',
  styleUrls: ['./highstock-modal.component.scss']
})
export class HighstockModalComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<HighstockModalComponent>,
    private highstockService: HighstockRenderService,
    private cd: ChangeDetectorRef
  ) {}
  title: string = '';
  headerIcon: string = '';
  lastUpdatedMsg: string = '';
  indicateMsg: string = '';
  chartId: string = 'highstock-modal';
  isDataFound = false;
  matMenuButtonStyle = {
    fontSize: 'x-small',
    height: '1.6rem',
    lineHeight: '0px'
  };
  renderedChart: Highstocks.Chart;

  ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.data)) {
      this.title = this.data.title;
      this.headerIcon = this.data.headerIcon;
      this.lastUpdatedMsg = this.data.lastUpdatedArray;
      this.indicateMsg = this.data.indicateMsg;
      this.isDataFound = true;
      this.renderedChart = this.highstockService.renderHighstock(this.chartId, this.data.highChartOptions);
      this.cd.detectChanges();
    } else {
      this.isDataFound = false;
    }
  }

  cancel(): void {
    this.dialogRef.close('N');
  }
}
