<div nz-popover [nzPopoverContent]="Content" nzPopoverPlacement="right" class="cell-popover">
  {{ params.value }}
</div>
<ng-template #Content>
  <div class="cell-popover-content">
    <span class="map-icon" matTooltip="Map" (click)="onMapClick()"><i class="fa fa-map"></i></span>
    <span class="asset-icon" matTooltip="Asset" (click)="onAssetClick()"
      ><i class="fa fa-cog" aria-hidden="true"></i
    ></span>
  </div>
</ng-template>
