<div class="dropdown-filter">
  <nz-select
    style="width: 200px"
    class="reports-dropdown-filter-custom-select"
    [(ngModel)]="selectedDateOption"
    (ngModelChange)="onDateSelectionChanged($event)">
    <nz-option nzCustomContent="true" [nzLabel]="menu.name" [nzValue]="menu" *ngFor="let menu of filterMenu">
      <div class="reports-dropdown-filter-custom-option">
        <div class="icon" [class.activeIcon]="menu === selectedDateOption"></div>
        <div class="option">{{ menu.name }}</div>
      </div>
    </nz-option>
  </nz-select>
  <div *ngIf="isDateRangeSelector">
    <nz-range-picker [(ngModel)]="selectedRangeOption" (ngModelChange)="onRangeChanged($event)"></nz-range-picker>
  </div>
  <div *ngIf="isWeekRangeSelector">
    <nz-week-picker
      [(ngModel)]="selectedWeekRangeOption"
      (ngModelChange)="onCustomRangeChanged($event, 'week')"
      nzPlaceHolder="Select week"></nz-week-picker>
  </div>
  <div *ngIf="isMonthRangeSelector">
    <nz-date-picker nzMode="month" [(ngModel)]="selectedMonthRangeOption"
      (ngModelChange)="onCustomRangeChanged($event, 'month')"></nz-date-picker>
  </div>
</div>
