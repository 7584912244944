<div class="modal-content modal-centered" data-backdrop="static" data-keyboard="false">
  <div class="modal-body {{ generateQrCodeClass }}">
    <div [innerHtml]="modalContent" class="modal-description"></div>

    <ng-container *ngIf="isConfigureQr">
      <div *ngIf="isScanning" class="qr-code-scanner">
        <ngx-scanner-qrcode
          #scanner1="scanner"
          [config]="qrScannerconfig"
          (event)="onCodeResult($event)"></ngx-scanner-qrcode>
        <button nz-button (click)="handlePermisssions(scanner1, scanner1.isStart ? 'stop' : 'start')">
          <span nz-icon nzType="camera"></span
        ></button>
      </div>

      <div *ngIf="!isScanning && showAssetSelection" class="mt-3">
        <mat-form-field appearance="outline">
          <mat-label>Selected Asset</mat-label>
          <mat-select placeholder="Select Asset" [formControl]="asset" [disabled]="showRetryButton">
            <input
              class="input-field dropdown-filter"
              type="text"
              [formControl]="assetFilter"
              placeholder="Search Asset"
              (ngModelChange)="filterAsset()" />
            <mat-option *ngFor="let asset of assetList" [value]="asset">{{ asset.assetName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="isSearchAssetQr">
      <div *ngIf="isScanning" class="qr-code-scanner">
        <ngx-scanner-qrcode #scanner2="scanner" (event)="onAssetSearchCodeResult($event)"></ngx-scanner-qrcode>
        <button nz-button (click)="handlePermisssions(scanner2, scanner2.isStart ? 'stop' : 'start')">
          <span nz-icon nzType="camera"></span
        ></button>
      </div>
    </ng-container>

    <div *ngIf="!isConfigureQr && !isSearchAssetQr">
      <div class="d-flex flex-column justify-content-around align-items-center">
        <input
          type="number"
          min="0"
          max="100"
          placeholder="Please enter"
          [(ngModel)]="qrCodeQuantity"
          *ngIf="showInput" />
      </div>
      <li class="mat-form-field--inline listData" *ngFor="let qrCode of qrCodeList">
        <qrcode
          [qrdata]="qrCode"
          [width]="325"
          [errorCorrectionLevel]="'M'"
          [elementType]="'img'"
          cssClass="qRCode"></qrcode>
      </li>
    </div>
  </div>

  <div class="modal-footer text-right">
    <button mat-button color="primary" *ngIf="showCancelButton" (click)="cancelModal()">
      {{ cancelButtonName }}
    </button>
    <button mat-button color="primary" *ngIf="showRetryButton" (click)="okModal()">
      {{ retryButtonName }}
    </button>
    <button mat-button color="primary" *ngIf="showConfirmButton" (click)="okModal()">
      {{ okButtonName }}
    </button>
    <button mat-button color="primary" *ngIf="showScanButton" (click)="reScan()">
      {{ scanButtonName }}
    </button>
    <button mat-button color="primary" *ngIf="showDownloadButton" (click)="downloadZip()"> Download </button>
  </div>
</div>
