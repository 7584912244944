import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { statsType } from '../param-util';
import { Assets } from '../interfaces/assets';

@Component({
  selector: 'app-add-stats-modal',
  templateUrl: './add-stats-modal.component.html',
  styleUrls: ['./add-stats-modal.component.scss']
})
export class AddStatsModalComponent implements OnInit {
  analyticsForm: FormGroup;
  analyticsStatsOptions: any[];
  validatorCount: { minRequired: number; maxRequired: number };
  title: string;
  paramUtilStatsType = statsType;
  statsType: string = statsType.CHECKBOX_SELECTION;
  assetList: Assets[] = [];
  selectedAssetId: number;
  assetFilter: FormControl = new FormControl();
  filteredAssetList: Assets[] = [];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.statsType === statsType.ASSET_SELECTION) {
      this.filteredAssetList = this.assetList.slice(0);
    } else {
      const key = Object.keys(this.analyticsStatsOptions);
      const group: any = {};
      this.analyticsStatsOptions[key[0]].forEach((ele) => {
        group[ele.chartId] = new FormControl(ele.isEnabled);
      });
      this.analyticsForm = new FormGroup(group, this.checkboxValidator(this.validatorCount));
    }
  }

  checkboxValidator({ minRequired, maxRequired }): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let selectedCheckboxCount = 0;
      for (const key in formGroup.controls) {
        const formControl = formGroup.controls[key];
        if (formControl.value) {
          selectedCheckboxCount += 1;
        }
      }
      return {
        minRequiredChecked: minRequired > selectedCheckboxCount,
        maxRequiredChecked: maxRequired < selectedCheckboxCount
      };
    };
  }

  cancelModalForOverviewAddStats(): void {
    this.activeModal.close('N');
  }

  okModal(): void {
    if (this.statsType === statsType.ASSET_SELECTION) {
      this.activeModal.close(this.assetList.find((asset: Assets) => asset.assetId === this.selectedAssetId));
    } else {
      this.activeModal.close(this.analyticsForm.value);
    }
  }

  toggleAnalyticsFormValue(field): void {
    let fieldValue = !this.analyticsForm.get(field).value;
    this.analyticsForm.get(field).setValue(fieldValue);
  }

  filterAssets(): void {
    let search = this.assetFilter.value.toLowerCase();
    if (!search) {
      this.filteredAssetList = this.assetList.slice(0);
      return;
    } else {
      this.filteredAssetList = this.assetList.filter((item: Assets) => item.assetName.toLowerCase().includes(search));
    }
  }
}
