import { Component } from '@angular/core';
import Highcharts from 'highcharts/highcharts';
import { Options } from 'highcharts';
import { AgCellRendererBase } from '../ag-cell-renderer-base';

@Component({
  selector: 'app-bar-cell-renderer',
  templateUrl: './bar-cell-renderer.component.html',
  styleUrls: ['./bar-cell-renderer.component.scss']
})
export class BarCellRendererComponent extends AgCellRendererBase {
  constructor() {
    super();
  }

  Highcharts = Highcharts;
  name: string;
  data: number;
  minRange: number;
  maxRange: number;

  ngOnInit() {
    this.data = this.params.value;

    this.name = this.params.data.dataLabel || '';
    this.minRange = this.params.data.minRange || 0;
    this.maxRange = this.params.data.maxRange + this.params.data.maxRange / 4;

    this.chartOptions.series = [
      {
        name: this.name,
        type: 'bar',

        data: [this.data]
      }
    ];
    this.chartOptions.yAxis = {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      min: this.minRange,
      max: this.maxRange,
      tickPositions: [0]
    };
  }

  chartOptions: Options = {
    chart: {
      borderWidth: 0,
      type: 'bar',
      margin: [0, 0, 0, 0],
      width: 300,
      height: 30,
      style: {
        overflow: 'visible'
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          padding: 10,
          formatter: function () {
            return this.series.name + "<span style='color:#000; opacity:1;'> " + this.y + ' </span>';
          }
        }
      },
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        }
      },
      column: {
        negativeColor: '#910000',
        borderColor: 'silver'
      }
    },
    series: [
      {
        name: '',
        type: 'bar',
        data: []
      }
    ]
  };
}
