import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent {
  quickAccessList = [
    {
      name: 'Trips',
      url: '/pages/dashboard/operator',
      image: 'trip.svg'
    },
    {
      name: 'Sites',
      url: '/pages/setup/sites',
      image: 'site.svg'
    },
    {
      name: 'Users',
      url: '/pages/userManagement/users',
      image: 'users.svg'
    },
    {
      name: 'Documents',
      url: '/pages/log-info/asset-document-history',
      image: 'documents.svg'
    },
    {
      name: 'Reminders',
      url: '/pages/log-info/maintenance-log',
      image: 'reminder.svg'
    },
    {
      name: 'Pre-start',
      url: '/pages/log-info/daily-check-reminder',
      image: 'pre-start.svg'
    },
    {
      name: 'Report',
      url: '/pages/insights/reports',
      image: 'report.svg'
    }, {
      name: 'Monitoring',
      url: '/pages/models/dynamic-allocation/info',
      image: 'production-monitoring.png'
    }
  ];
  isPopupVisible: boolean = false;

  constructor(private router: Router) {}

  getSelectedQuickAccess(item: any) {
    this.isPopupVisible = false;
    this.router.navigate([item.url]);
  }
}
