<div class="on-screen-position">
    <button class="button" >
        <span class="icon mar-right-15">
            <mat-icon>group</mat-icon>
        </span>
        <span class="text">{{operatorName}}</span>
        <span class="icon" style="color: red;" nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes" nzCancelText="No"
        [nzPopconfirmTitle]="popConfirmMessage" nzPopconfirmPlacement="bottom" (nzOnConfirm)="onClick()">
            <mat-icon>power_settings_new</mat-icon>
        </span>
    </button>
</div>
