<div class="user-list">
  <div
    nz-popover
    [nzPopoverContent]="userPopoverContent"
    nzPopoverPlacement="bottom"
    class="user"
    [ngStyle]="{
      color: colorList[i % backgroundColorList.length],
      'background-color': backgroundColorList[i % backgroundColorList.length]
    }"
    *ngFor="let user of userList; let i = index">
    {{ user?.symbol }}
    <ng-template #userPopoverContent>
      <div class="cell-popover-notify-content">
        <div class="user-name">
          <span class="">{{ user?.firstName }}</span>
          <span class="">{{ user?.lastName }}</span>
        </div>
        <div class="user-phone-number">
          <span>{{ user?.mobileNumber }}</span>
        </div>
      </div>
    </ng-template>
  </div>
  <div
    class="user"
    *ngIf="additionalList.length > 0"
    nz-popover
    [nzPopoverContent]="userPopoverContentAdditional"
    nzPopoverPlacement="bottom">
    +{{ additionalList.length }}
    <ng-template #userPopoverContentAdditional>
      <div class="cell-popover-notify-content-additional">
        <div class="additional-user" *ngFor="let user of additionalList; let i = index">
          <div
            class="user"
            [ngStyle]="{
              color: colorList[(i + colorLoopIndex) % backgroundColorList.length],
              'background-color': backgroundColorList[(i + colorLoopIndex) % backgroundColorList.length]
            }">
            {{ user?.symbol }}
          </div>
          <div class="additional-user-mobile">
            <div class="user-name">
              <span class="">{{ user?.firstName }}</span>
              <span class="">{{ user?.lastName }}</span>
            </div>
            <div class="user-phone-number">
              <span>{{ user?.mobileNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
