import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NEW_TAB_REDIRECTION } from '../../param-util';

@Component({
  selector: 'app-cell-popover',
  templateUrl: './cell-popover.component.html',
  styleUrls: ['./cell-popover.component.scss']
})
export class CellPopoverComponent implements ICellRendererAngularComp {
  constructor(private router: Router) {}
  params: { [key: string]: any } = {};

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onMapClick() {
    localStorage.setItem(NEW_TAB_REDIRECTION.NEW_TAB_MODULE_FLAG, NEW_TAB_REDIRECTION.NEW_TAB_MODULE_MAP);
    this.setLocalStorageAndOpenNewWindow();
  }

  onAssetClick() {
    localStorage.setItem(NEW_TAB_REDIRECTION.NEW_TAB_MODULE_FLAG, NEW_TAB_REDIRECTION.NEW_TAB_MODULE_ASSET);
    this.setLocalStorageAndOpenNewWindow();
  }

  setLocalStorageAndOpenNewWindow() {
    localStorage.setItem(NEW_TAB_REDIRECTION.NEW_TAB_FLAG, 'true');
    localStorage.setItem(NEW_TAB_REDIRECTION.NEW_TAB_ASSET_ID, this.params.data.assetId);
    const newWindowURL = window.location.origin + NEW_TAB_REDIRECTION.NEW_TAB_BASE_URL;
    window.open(newWindowURL);
  }
}
