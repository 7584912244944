import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HighstockRenderService } from '../../render-highstock/render-highstock.service';
import { isNullOrUndefined } from '../../function-util';
import moment from 'moment';

@Component({
  selector: 'app-review-analytic-modal',
  templateUrl: './review-analytic-modal.component.html',
  styleUrls: ['./review-analytic-modal.component.scss']
})
export class ReviewAnalyticModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private highstockService: HighstockRenderService,
    public dialogRef: MatDialogRef<ReviewAnalyticModalComponent>
  ) {}

  isDataFound = false;

  async ngOnInit() {
    await new Promise((resolve) => setTimeout(resolve, 10));
    if (!isNullOrUndefined(this.data.data)) {
      this.isDataFound = true;
      this.highstockService.renderHighstock('review-analytics-state', this.getStateChart(this.data.data));
    }
  }

  cancel() {
    this.dialogRef.close('N');
  }

  getStateChart(twoMonthData) {
    twoMonthData.forEach((dataPerDay) => {
      dataPerDay[0] = moment(dataPerDay[0]).format('DD-MMM-YYYY');
    });
    let previousMonthData = [];
    let currentMonthData = [];
    if (this.data.selectedMonth < 0) {
      previousMonthData = twoMonthData.slice(0, twoMonthData.length / 2);
      currentMonthData = twoMonthData.slice(twoMonthData.length / 2);
    } else {
      previousMonthData = twoMonthData.filter(
        (data: number[]) => new Date(data[0]).getMonth() !== this.data.selectedMonth
      );
      currentMonthData = twoMonthData.filter(
        (data: number[]) => new Date(data[0]).getMonth() === this.data.selectedMonth
      );
    }
    const xAxisKeys = [];
    currentMonthData.forEach((dataPerDay) => xAxisKeys.push(moment(new Date(dataPerDay[0])).format('DD. MMM')));
    const chartData = [
      {
        name: 'Current Month',
        type: 'areaspline',
        data: currentMonthData,
        color: '#72b3f3'
      },
      {
        type: 'areaspline',
        name: 'Previous Month',
        data: previousMonthData,
        color: '#d7d7d7'
      }
    ];
    return {
      chartType: 'areaspline',
      chartTitle: '',
      xAxisLabel: '',
      xAxisKeys: xAxisKeys,
      yAxisLabel: '',
      inside: false,
      chartData: chartData,
      showLegend: true,
      isStackedColumn: false,
      clickFunctionEnabled: false
    };
  }
}
