import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnApi, GridApi } from 'ag-grid-community';
import Highcharts, { Options } from 'highcharts';
import moment from 'moment';
import { FleetService } from 'src/app/layout/dashboard/fleet/fleet.service';
import { FuelEnergyService } from 'src/app/layout/dashboard/fuel-energy/fuel-energy.service';
import { isNullOrUndefined } from '../function-util';
import { productionRateType } from '../param-util';
import { ScatterChartModalComponent } from './scatter-chart-modal/scatter-chart-modal.component';
import { catchError, lastValueFrom, of } from 'rxjs';

@Component({
  selector: 'app-chart-report-card',
  templateUrl: './chart-report-card.component.html',
  styleUrls: ['./chart-report-card.component.scss']
})
export class ChartReportCardComponent implements AfterViewInit {
  @Input() title: string;
  @Input() chartId: string;
  @Input() chartFunction: () => Promise<{ [key: string]: any }>;
  @Input() isEnabledScatter: boolean = true;

  reportData = [];
  columnDefs = [];
  getRowStyle: any;
  defaultColDef = {};
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  frameworkComponents = {};
  context = {};
  isDataLoaded = false;
  siteId: string;
  header: string;
  subHeader: string;
  generalData: any;
  yestVMonthChangeTypeIsPositive = false;
  latestGraphDate = null;

  constructor(public dialog: MatDialog, private fleetService: FleetService, private fuelService: FuelEnergyService) {
    this.initGrid();
  }

  ngAfterViewInit(): void {
    this.chartFunction()
      .then((response) => {
        if (!isNullOrUndefined(response)) {
          this.generalData = response;
          this.latestGraphDate = moment.unix(response.chartData.graphData.slice(-1)[0][0]).format('YYYY-MM-DD');
          this.header = this.title.split('-')[0].trim();
          this.subHeader = this.title.split('-').length > 1 ? this.title.split('-')[1].trim() : '';

          let monthChangeType = response.chartData.monthChangeType === 'Positive' ? '#049400' : '#F00';
          const areasplineOption = this.getAreaSplineChart(this.header, response.chartData.graphData, monthChangeType);
          Highcharts.chart(this.chartId, areasplineOption);
          this.yestVMonthChangeTypeIsPositive = response.chartData.yestVMonthChangeType === 'Positive';
          response.tableData.columnHeaders.forEach((col) => {
            this.columnDefs.push({
              headerName: col.headerName,
              minWidth: 80,
              field: col.fieldName,
              filter: 'agMultiColumnFilter',
              suppressMenu: true,
              floatingFilterComponentParams: { suppressFilterButton: true },
              suppressMovable: true
            });
          });
          if (!isNullOrUndefined(this.gridApi)) {
            this.gridApi.setColumnDefs(this.columnDefs);
            this.gridApi.setRowData(this.generalData.tableData.reportData);
            this.gridApi.sizeColumnsToFit();
          }
          this.isDataLoaded = true;
        }
      })
      .catch((error) => {
        this.generalData = error;
        this.header = this.title.split('-')[0].trim();
        this.subHeader = this.title.split('-').length > 1 ? this.title.split('-')[1].trim() : '';
      });
  }

  initGrid(): void {
    this.getRowStyle = (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return { background: '#dcf4ffcf' };
      }
    };

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      enableRowGroup: true,
      floatingFilter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  getAreaSplineChart(name: string, paramData: Array<any>[], color: string): Options {
    let chartOption: Options = {
      chart: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        type: 'areaspline',
        margin: [2, 0, 2, 0],
        height: 100,
        style: {
          overflow: 'visible'
        }
      },
      time: {
        useUTC: false
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
        type: 'datetime'
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        outside: true,
        formatter: function () {
          return `<span>${moment.unix(Number(this.key)).format('DD-MMM')}: <b>${Highcharts.numberFormat(
            this.y,
            0,
            '.',
            ','
          )}</b></span>`;
        }
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 2,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          },
          pointPlacement: 'end'
        },
        column: {
          negativeColor: '#910000',
          borderColor: 'silver'
        },
        areaspline: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, color],
              [1, Highcharts.color(color).setOpacity(0).get('rgba').toString()]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      series: [
        {
          color: color,
          name: name,
          type: 'areaspline',
          data: paramData
        }
      ]
    };
    return chartOption;
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  openScatterChart(): void {
    if (this.isDataLoaded && this.isEnabledScatter) {
      const dialogRef = this.dialog.open(ScatterChartModalComponent, {
        width: '60vw',
        backdropClass: 'scatter-chart-modal',
        data: {
          chartId: this.header,
          title: this.header + ' ' + this.subHeader,
          getData: this.getData.bind(this)
        }
      });
    }
  }

  getData(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.generalData.productionRateType == productionRateType.HOUR) {
        const res = await lastValueFrom(
          this.fleetService
            .getTripProductionScatterTrendByAssetClassAndCategory(
              this.generalData.siteId,
              this.generalData.assetClassName,
              this.generalData.tripCategory,
              this.generalData.fetchType
            )
            .pipe(catchError(() => of([])))
        );

        if (!isNullOrUndefined(res)) {
          resolve(this.splitDataByBottomPercentile(res, 0.25));
        }
        reject('Error retrieving data');
      } else if (this.generalData.productionRateType == productionRateType.FUEL) {
        const res = await lastValueFrom(
          this.fuelService
            .getScatterPlotData(
              this.generalData.siteId,
              this.generalData.assetClassName,
              this.generalData.tripCategory,
              this.generalData.fetchType,
              this.latestGraphDate
            )
            .pipe(catchError(() => of([])))
        );
        if (!isNullOrUndefined(res)) {
          resolve(this.splitDataByBottomPercentile(res, 0.25));
        }
        reject('Error retrieving data');
      }
    });
  }

  splitDataByBottomPercentile(data, bottomPercentile) {
    let response = {};
    const sortedData = data.sort(function (a, b) {
      return a.y - b.y;
    });
    const bottomTenPercentilePosition = Math.floor(data.length * bottomPercentile);
    response['bottom'] = sortedData.splice(0, bottomTenPercentilePosition);
    response['top'] = sortedData;
    return response;
  }
}
