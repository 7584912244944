import { Component, OnInit } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';
import { ServiceReminderStatus } from '../../enum-util';

@Component({
  selector: 'app-service-icon-cell-render',
  templateUrl: './service-icon-cell-render.component.html',
  styleUrls: ['./service-icon-cell-render.component.scss']
})
export class ServiceIconCellRenderComponent extends AgCellRendererBase implements OnInit {
  serviceIconEnable: boolean = true;

  ngOnInit(): void {
    if (this.params.data.reminderStatus === ServiceReminderStatus.SERVICE_DONE) {
      this.serviceIconEnable = false;
    }
  }

  item(event: MouseEvent): void {
    this.sendEvent(AgCellRendererEvent.ITEM_EVENT, event);
  }

  view(event: MouseEvent): void {
    this.sendEvent(AgCellRendererEvent.VIEW_EVENT, event);
  }
}
