<div class="modal-header">
  <h1 mat-dialog-title>{{ titleName }}</h1>
  <div class="closeButton pb-2">
    <app-modal-close-btn btnSize="large" [closeByMethod]=true (closeDialog)="cancel()"></app-modal-close-btn>
  </div>
</div>
<div mat-dialog-content>
  <div class="d-flex flex-column justify-content-around align-items-center" style="height: 100%; margin-top: 4vh">
    <code-input #codeInput [isCodeHidden]="true" [codeLength]="codeLength" (codeCompleted)="onCodeCompleted($event)">
    </code-input>
  </div>
</div>
