import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNullOrUndefined } from '../../function-util';

@Component({
  selector: 'app-cell-user-popover',
  templateUrl: './cell-user-popover.component.html',
  styleUrls: ['./cell-user-popover.component.scss']
})
export class CellUserPopoverComponent implements ICellRendererAngularComp, AfterViewInit {
  @Input() inputUserList = null;

  constructor() {}
  params: { [key: string]: any } = {};
  userList = [];
  allUser = [];
  additionalList = [];
  colorLoopIndex: number = 3;
  backgroundColorList = ['#45a721', '#4b4b53', '#276ef1', '#00a3bf', '#de350c', '#5244ab'];
  colorList = ['#fff', '#fff', '#fff', '#000', '#fff', '#fff'];

  ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.inputUserList)) {
      this.allUser = this.inputUserList;
      this.createUserSymbol();
      this.userList = this.allUser.slice(0, this.colorLoopIndex);
      this.additionalList = this.allUser.slice(this.colorLoopIndex);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    if (isNullOrUndefined(this.inputUserList)) {
      this.params = params;
      this.allUser = this.params?.data?.alertSendToList;
      if (!isNullOrUndefined(this.allUser)) {
        this.createUserSymbol();
        this.userList = this.allUser.slice(0, this.colorLoopIndex);
        this.additionalList = this.allUser.slice(this.colorLoopIndex);
      }
    }
  }

  createUserSymbol() {
    this.allUser.forEach((user) => {
      let fnameFirstLetter = user.firstName[0];
      let lnameFirstLetter = user.lastName[0];
      let symbol = (fnameFirstLetter + lnameFirstLetter).toUpperCase();
      user.symbol = symbol;
    });
  }
}
