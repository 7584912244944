<div class="heading">
  <h6 mat-dialog-title>{{titleName}}</h6>
</div>
<div mat-dialog-content>
  <div class="option-list" *ngIf="options.length > 0, else noData">
    <mat-radio-group [formControl]="selectedOption" class="option-btns">
      <mat-radio-button *ngFor="let option of options" [value]="option">{{option[textFieldName]}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <ng-template #noData>
    <div class="no-data">No Data</div>
  </ng-template>
</div>
<div mat-dialog-action class="action-btn">
  <button mat-button color="primary" (click)="submit()" [disabled]="!selectedOption.value">Submit</button>
  <button mat-button color="primary" (click)="close()">Cancel</button>
</div>