import { Component, Input, OnInit } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html'
})
export class ExcelExportComponent implements OnInit {
  @Input() dataList: any;
  @Input() title: string;
  @Input() reportName: string;

  ngOnInit(): void {}

  downloadExcel(): void {
    let title = this.title;
    const dynamicAlertParams = Object.keys(this.dataList[0].alertParameters);
    let header = Object.keys(this.dataList[0]).filter((key) => key !== 'alertParameters');
    header = [...header, ...dynamicAlertParams];
    header = header.map((res) => res.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase()));
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.reportName);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Saysettha OT', family: 4, size: 16, bold: true };

    worksheet.addRow([]);
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.font = { name: 'Saysettha OT', bold: true };
    });
    worksheet.columns = header.map(() => ({ width: 25 }));

    this.dataList.forEach((d) => {
      let rowValues = [d.consumableName, d.consumableType, d.createdDate || '', d.specification || '', d.stock || 0];
      dynamicAlertParams.forEach((param) => {
        rowValues.push(d.alertParameters[param] || '');
      });
      let row = worksheet.addRow(rowValues);
      row.font = { name: 'Saysettha OT' };
    });

    workbook.xlsx.writeBuffer().then((excelData) => {
      const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.reportName + '.xlsx');
    });
  }
}
