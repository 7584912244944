import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetListService } from 'src/app/layout/models/asset/asset-service/asset-list.service';

@Component({
  selector: 'app-ocr-operation',
  templateUrl: './ocr-operation.component.html',
  styleUrls: ['./ocr-operation.component.scss']
})
export class OcrOperationComponent {
  @Input() ocrId: string;
  @Input() textMaxLength: number;
  @Output() textEmitter = new EventEmitter<{ text: string; id: string }>();

  constructor(private assetService: AssetListService) {}

  upload(event: any) {
    const image = (event.target as any).files;
    const docFile = new FormData();
    docFile.append('document', image.item(0));

    this.assetService.getTextInImageByOcr(docFile).subscribe((res) => {
      const response = {
        text: res['Response'].substring(0, this.textMaxLength) as string,
        id: this.ocrId
      };
      this.textEmitter.emit(response);
    });
  }
}
