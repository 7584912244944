import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { getDescriptionById, monthsWithFullName as months, statsType } from '../param-util';
import { MatDialog } from '@angular/material/dialog';
import { AddStatsModalComponent } from '../add-stats-modal/add-stats-modal.component';
import { OverviewService } from 'src/app/layout/dashboard/overview/overview.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataResponse } from '../interfaces/chart-data-response';
import { ChartDataRequest } from '../interfaces/chart-data-request';
import { AssetListService } from 'src/app/layout/models/asset/asset-service/asset-list.service';
import { isNullOrUndefined } from '../function-util';
import { Assets } from '../interfaces/assets';

@Component({
  selector: 'app-analytics-modal',
  templateUrl: './analytics-modal.component.html',
  styleUrls: ['./analytics-modal.component.scss']
})
export class AnalyticsModalComponent implements OnInit {
  @Input() siteId: string;
  @Input() siteType: string;
  @Input() moduleName: string;
  @Input() section: {
    sectionId: string;
    sectionEnabled: boolean;
    analyticsArray: {
      title: string;
      chartId: string;
      values: any;
      enabled: boolean;
    }[];
  };
  @Input() analyticModalType: 'ANALYTICS' | 'PTA';
  @Input() analyticModalHeading: string = 'Analytics';
  @Input() analyticModalDesc: string = 'Your Key Business Indicators for';

  constructor(
    public dialog: MatDialog,
    private service: OverviewService,
    private assetService: AssetListService,
    private modalService: NgbModal
  ) {}

  analyticsFromDate = '';
  analyticsToDate = '';
  selectedMonth: number = -1;
  monthList: { monthName: string; monthIndex: number }[] = [];
  analyticsComparisonType: 'days' | 'months' = 'days';

  analyticsArray: {
    title: string;
    chartId: string;
    values: any;
    enabled: boolean;
  }[] = [];
  selectedAsset: Assets;
  assetList: Assets[];
  statsButtonText: string;

  functionBinding = {};

  async ngOnInit(): Promise<void> {
    this.getMonthStartAndEnd();
    this.monthList = [{ monthName: 'Last 30 Days', monthIndex: -1 }];
    let currentMonth = new Date().getMonth();
    for (const month in months) {
      if (Number(month) <= currentMonth) {
        this.monthList.push({
          monthName: months[month],
          monthIndex: Number(month)
        });
      }
    }
    this.monthList.sort((a: any, b: any) => a.monthIndex - b.monthIndex);
    if (this.section.sectionId === '2') {
      this.getAssetList();
      while (isNullOrUndefined(this.selectedAsset)) {
        await new Promise((resolve: any) => setTimeout(resolve, 10));
      }
      this.getPtaAnalytics();
    }
    this.statsButtonText = this.analyticModalType === 'ANALYTICS' ? '+ Add Stats' : 'Change Asset';
  }

  async onAnalyticsMonthChange(): Promise<void> {
    this.getMonthStartAndEnd(this.selectedMonth);
    this.analyticsComparisonType = this.selectedMonth === -1 ? 'days' : 'months';
    if (this.section.sectionId === '2') {
      this.getPtaAnalytics();
    } else {
      let backup = [...this.analyticsArray];
      this.analyticsArray = [];
      await new Promise((resolve: any) => setTimeout(resolve, 5));
      this.analyticsArray = backup;
    }
  }

  getAssetList(): void {
    this.assetService.getAllAssets(this.siteId).subscribe((res: Assets[]) => {
      if (!isNullOrUndefined(res)) {
        this.assetList = res.filter((asset: Assets) => asset.assetClassName.includes('PTA'));
        if (isNullOrUndefined(this.selectedAsset)) {
          this.selectedAsset = this.assetList[0];
        }
      }
    });
  }

  getPtaAnalytics(): void {
    let chartDataRequest: ChartDataRequest = {
      chartId: this.section.analyticsArray[0].chartId,
      chartName: this.section.analyticsArray[0].title,
      siteId: Number(this.siteId),
      startDate: this.analyticsFromDate,
      endDate: this.analyticsToDate,
      siteType: this.siteType,
      comparisonRange: 0,
      assetId: this.selectedAsset.assetId
    };

    this.service.getPtaAnalyticsByAsset(chartDataRequest).subscribe((chartDataRes: Array<ChartDataResponse>) => {
      this.analyticsArray = [];
      chartDataRes.forEach((chartData: ChartDataResponse) => {
        this.analyticsArray.push({
          title: chartData.chartName,
          chartId: chartData.chartId,
          enabled: this.section.sectionEnabled,
          values: this.dynamicPromiseForPtaAnalytics.bind(this, chartData)
        });
      });
      this.analyticModalDesc = `Analytic of asset ${this.selectedAsset.assetName} for `;
    });
  }

  dynamicPromiseForPtaAnalytics(chartData: ChartDataResponse): Promise<ChartDataResponse> {
    return new Promise((resolve: any) => resolve(chartData));
  }

  getMonthStartAndEnd(monthNumber: number = -1): void {
    if (monthNumber === -1) {
      this.analyticsFromDate = moment().subtract(59, 'days').format('YYYY-MM-DD');
      this.analyticsToDate = moment().format('YYYY-MM-DD');
    } else {
      this.analyticsFromDate = moment()
        .set('month', monthNumber - 1)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.analyticsToDate = moment().set('month', monthNumber).endOf('month').format('YYYY-MM-DD');
    }
  }

  filterStatsData(type: string): any {
    const response: {
      [key: string]: {
        name: string;
        chartId: string;
        isEnabled: boolean;
        body: string;
      }[];
    } = {};
    const data = [];
    this.section.analyticsArray.forEach((ele: any) => {
      const a = {
        name: ele.title,
        chartId: ele.chartId,
        isEnabled: ele.enabled,
        body: getDescriptionById(ele.chartId)
      };
      data.push(a);
    });
    response[type] = data;
    return response;
  }

  openAddStats(): void {
    let filteredTripOptions;
    let validatorCount: { minRequired: number; maxRequired: number };
    switch (this.analyticModalType) {
      case 'ANALYTICS':
        filteredTripOptions = this.filterStatsData('Analytics');
        validatorCount = {
          minRequired: 1,
          maxRequired: 4
        };
        break;
      case 'PTA':
        this.getAssetList();
        break;
    }
    const activeModal = this.modalService.open(AddStatsModalComponent, {
      backdrop: 'static'
    });
    activeModal.componentInstance.analyticsStatsOptions = filteredTripOptions;
    activeModal.componentInstance.validatorCount = validatorCount;
    activeModal.componentInstance.title = 'Customize Your Analytics Overview';
    if (this.analyticModalType === 'PTA') {
      activeModal.componentInstance.statsType = statsType.ASSET_SELECTION;
      activeModal.componentInstance.assetList = this.assetList;
      activeModal.componentInstance.selectedAssetId = this.selectedAsset.assetId;
    }
    activeModal.result.then((response: any) => {
      if (response != 'N') {
        switch (this.analyticModalType) {
          case 'ANALYTICS':
            this.service.setModulesBySite(this.siteId, response, this.moduleName).subscribe((res: any) => {});
            this.section.analyticsArray.forEach((ele: any) => {
              ele.enabled = response[ele.chartId];
            });
            break;
          case 'PTA':
            this.selectedAsset = response;
            this.getPtaAnalytics();
            break;
        }
      }
    });
  }
}
