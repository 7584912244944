import { Component, Input } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { LoggedInUserDetailService } from 'src/app/core/services/loggedIn-user-detail-service';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from '../function-util';

@Component({
  selector: 'app-common-map',
  templateUrl: './common-map.component.html',
  styleUrls: ['./common-map.component.scss']
})
export class CommonMapComponent {
  @Input() latLng: { latitude: number; longitude: number };
  @Input() markerHeader = {
    title: '',
    subTitle: ''
  };

  googleMap: google.maps.Map = null;
  private mapBox?: mapboxgl.Map = null;
  private mapstyle = 'mapbox://styles/mapbox/satellite-streets-v12';
  zoom = 5;
  clientId: number;

  constructor(private logInUserDetService: LoggedInUserDetailService) {}

  ngOnChanges() {
    this.logInUserDetService.loggedInUserDetails.subscribe((res: any) => {
      if (res && res != null) {
        this.clientId = res.client.clientId;
      }
    });
    mapboxgl.accessToken = environment.mapbox.accessToken;

    if (this.clientId == 15) {
      if (isNullOrUndefined(this.mapBox)) {
        this.mapboxMapRender();
      } else {
        this.createMapboxMarker({ lat: this.latLng.latitude, lng: this.latLng.longitude });
      }
    } else {
      if (isNullOrUndefined(this.googleMap)) {
        this.getMap();
      } else {
        this.setGoogleMapMarker();
      }
    }
  }

  getMap(): void {
    const centerlatlng = new google.maps.LatLng(this.latLng.latitude, this.latLng.longitude);
    const myOptions: google.maps.MapOptions = {
      zoom: this.zoom,
      scrollwheel: true,
      zoomControl: false,
      center: centerlatlng,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.DEFAULT
      },
      fullscreenControl: false,
      scaleControl: false,
      streetViewControl: false,
      disableDefaultUI: true,
      mapTypeId: 'hybrid'
    };
    this.googleMap = new google.maps.Map(document.getElementById('map'), {
      ...myOptions,
      mapId: '62304de93ee45a67'
    } as google.maps.MapOptions);

    this.setGoogleMapMarker();
  }

  setGoogleMapMarker(): void {
    const centerLatLng = new google.maps.LatLng(this.latLng.latitude, this.latLng.longitude);
    const marker = new google.maps.Marker({
      position: { lat: this.latLng.latitude, lng: this.latLng.longitude },
      map: this.googleMap,
      title: this.markerHeader ? this.markerHeader.title + '\n' + this.markerHeader.subTitle : '',
      animation: google.maps.Animation.DROP
    });
    this.googleMap.setCenter(centerLatLng);
  }

  mapboxMapRender(): void {
    this.mapBox = new mapboxgl.Map({
      container: 'map',
      style: this.mapstyle,
      zoom: this.zoom
    });

    const moveToPosition = {
      lat: this.latLng.latitude,
      lng: this.latLng.longitude
    };
    this.mapBox.flyTo({ center: moveToPosition });
    this.createMapboxMarker(moveToPosition);
    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true
    });
    this.mapBox.addControl(nav, 'bottom-right');
  }

  createMapboxMarker(position: { lat: number; lng: number }): void {
    const marker = new mapboxgl.Marker({
      color: 'red'
    })
      .setLngLat(position)
      .setPopup(new mapboxgl.Popup({ offset: 13 }).setText(this.markerHeader.title))
      .addTo(this.mapBox);
    this.mapBox.flyTo({ center: position });
  }
}
