import { Injectable } from '@angular/core';
import Highstocks from 'highcharts/highstock';
import Highcharts from 'highcharts/highcharts';
import { Options } from 'highcharts';
import { HighchartOptions } from '../render-highchart/render-highcharts-options';
import moment from 'moment';
import { isNullOrUndefined } from '../function-util';

@Injectable({
  providedIn: 'root'
})
export class HighstockRenderService {
  constructor() {}

  renderHighstock(chartId: string | HTMLElement, highchartOptions: HighchartOptions): Highcharts.Chart {
    const chartIdName = chartId instanceof HTMLElement ? chartId.className : chartId;
    const showDecimal =
      !isNullOrUndefined(highchartOptions.extraData) && !isNullOrUndefined(highchartOptions.extraData.showDecimal)
        ? highchartOptions.extraData.showDecimal
        : false;
    let option: Options = {
      chart: {
        type: highchartOptions.chartType,
        height: highchartOptions.height ? highchartOptions.height : 310
      },
      rangeSelector: {
        enabled: false
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      title: {
        text: highchartOptions.chartTitle
      },
      subtitle: {
        text: ''
      },
      legend: {
        enabled: highchartOptions.showLegend
      },
      exporting: {
        enabled: !isNullOrUndefined(highchartOptions.exportIcon)
      },
      xAxis: {
        type: 'category',
        title: {
          text: highchartOptions.xAxisLabel
        },
        categories: highchartOptions.xAxisKeys,
        crosshair: false
      },

      yAxis: {
        title: {
          text: highchartOptions.yAxisLabel
        }
      },
      tooltip: {
        formatter: function (this) {
          if (highchartOptions.chartType === 'pie') {
            return `<div><span style="color:#000;padding:0">${this.point.name}:
                    <b>${Highcharts.numberFormat(this.y, 0, '.', ',')}</b></span></div>`;
          } else {
            return `<div><span style="color:#000;padding:0">${this.points[0].series.name}:
                    <b>${Highcharts.numberFormat(
                      this.y,
                      showDecimal && !Number.isInteger(this.y) ? 2 : 0,
                      '.',
                      ','
                    )}</b></span></div>`;
          }
        },

        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        },
        areaspline: {
          fillOpacity: 0.5
        }
      },
      credits: {
        enabled: false
      },
      series: highchartOptions.chartData
    };

    if (highchartOptions.chartType === 'bar' || highchartOptions.chartType === 'column') {
      option.plotOptions['series'] = {
        dataLabels: {
          enabled: true,
          color: 'black',
          formatter: function (e): string {
            let value = Highcharts.numberFormat(this.y, showDecimal && !Number.isInteger(this.y) ? 2 : 0, '.', ',');
            return `<span >${value}</span>`;
          }
        }
      };
    }

    if (highchartOptions.isStackedColumn) {
      option.plotOptions.column = {
        stacking: 'normal'
      };
    }

    if (highchartOptions.chartType === 'pie') {
      option.chart = {
        type: highchartOptions.chartType,
        height: 198
      };
    }

    if (chartId.toString().includes('trip') && highchartOptions.chartType === 'pie') {
      option.title = {
        text:
          '<div><span style="font-size:1.3rem;">Trips</span><br /><span style="font-size:1.3rem;"><b>' +
          highchartOptions.extraData['totalSum'] +
          '</b></span></div>',
        align: 'center',
        verticalAlign: 'middle',
        y: 20
      };
    }

    if (
      chartId.toString().includes('state') ||
      chartIdName === 'child-hourChart' ||
      chartIdName === 'child-cumulativeHourChart' ||
      chartIdName === 'tripDailyChart'
    ) {
      option.responsive = {
        rules: [
          {
            condition: {
              minWidth: 0,
              maxWidth: 768
            },
            chartOptions: {
              xAxis: [
                {
                  tickInterval: 10
                },
                {}
              ]
            }
          },
          {
            condition: {
              minWidth: 768
            },
            chartOptions: {
              xAxis: [
                {
                  tickInterval: 3
                },
                {}
              ]
            }
          }
        ]
      };
    }

    if (chartId.toString().includes('state') || chartIdName.includes('connectedDailyChart')) {
      option.plotOptions['series'] = {
        marker: {
          enabled: false
        }
      };
      option.tooltip = {
        useHTML: true,
        split: true,
        formatter: function (this) {
          let tooltips = '<div>';
          this.points.forEach((point, index) => {
            if (index != 0) {
              tooltips += '<br />';
            }
            tooltips += `<span style="color:#000;padding:0">${point.series.name}: <b>${Highcharts.numberFormat(
              point.y,
              point.y % 1 !== 0 ? 2 : 0,
              '.',
              ','
            )}</b><br />${point.key}</span><br />`;
          });
          tooltips += `</div>`;
          return tooltips;
        }
      };
    }

    if (chartIdName === 'hourByAssetClassFleet' || chartIdName === 'fuelConsumedByAssetClass') {
      option.plotOptions['series'] = {
        marker: {
          enabled: false
        }
      };
      option.tooltip = {
        useHTML: true,
        split: true,
        formatter: function (this) {
          let tooltips = '<div>';
          this.points.forEach((point, index) => {
            if (index != 0) {
              tooltips += '<br />';
            }
            tooltips += `<span style="color:${point.color}">●</span><span style="color:#000;padding:0">${
              point.series.name
            }: <b>${Highcharts.numberFormat(point.y, 0, '.', ',')}</b></span><br />`;
          });
          tooltips += `${this.points[0].key}</div>`;
          return tooltips;
        }
      };
    }

    if (chartIdName === 'child-hourChart' || chartIdName === 'child-cumulativeHourChart') {
      option.plotOptions['series'] = {
        marker: {
          enabled: false
        }
      };
      option.chart = {
        type: 'areaspline',
        height: 160
      };
      option.xAxis['crosshair'] = {
        enabled: true,
        width: 2,
        color: '#e0e0e0'
      };
    }

    if (chartIdName === 'child-hourChart') {
      let extraData = highchartOptions.extraData;
      option.title = {
        text: `<div><span>${highchartOptions.chartTitle}</span><span>  <b>${
          extraData['totalSum']
        }</b></span><span style="font-size:15px;color:${extraData['color']}"> (${
          extraData['percent'] > 0 ? '+' + extraData['percent'] : '' + extraData['percent']
        }%)</span></div>`,
        align: 'left',
        margin: 0,
        x: 0
      };
      option.tooltip = {
        formatter: function (this) {
          return `<div><span style="color:#000;padding:0">${this.points[0].series.name}: <b>${Highcharts.numberFormat(
            this.y,
            0,
            '.',
            ','
          )} Hrs</b><br />${this.points[0].key}</span></div>`;
        },
        shared: true,
        useHTML: true
      };
    }

    if (chartIdName === 'child-cumulativeHourChart') {
      let extraData = highchartOptions.extraData;
      option.title = {
        text: `<div><span>${highchartOptions.chartTitle.split(' ')[0]}</span><span>  <b>${
          extraData['totalSum']
        }</b></span><span style="font-size:15px;color:${extraData['color']}"> (${
          extraData['percent'] > 0 ? '+' + extraData['percent'] : '' + extraData['percent']
        }%)</span></div>`,
        align: 'left',
        margin: 0,
        x: 0
      };
      option.plotOptions = {
        series: {
          cumulative: true,
          marker: {
            enabled: false
          }
        },
        areaspline: {
          fillOpacity: 0.5
        }
      };
      option.tooltip = {
        formatter: function (this) {
          return `<div><span style="color:#000;padding:0">${this.points[0].series.name}: <b>${Highcharts.numberFormat(
            this.points[0].point['cumulativeSum'],
            0,
            '.',
            ','
          )} Hrs</b><br />${this.points[0].key}</span></div>`;
        },
        shared: true,
        useHTML: true
      };
    }

    if (chartId === 'connectedMonthlyChart' && highchartOptions.extraData['average']) {
      option.yAxis = [
        {
          title: {
            text: ''
          },
          plotLines: [
            {
              value: highchartOptions.extraData['average'],
              color: '#8b8b8b',
              id: 'Last 12 months avg',
              zIndex: 10,
              dashStyle: 'LongDash',
              label: {
                align: 'right',
                style: {
                  color: '#a7a7a7'
                },
                text: 'Avg: <b>' + Highcharts.numberFormat(highchartOptions.extraData['average'], 0, '.', ',') + '</b>'
              }
            }
          ]
        }
      ];
    }

    if (chartIdName === 'scatter-chart-id') {
      option.plotOptions = {
        scatter: {
          marker: {
            radius: 4,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          }
        }
      };

      option.tooltip = {
        formatter: function (this: any) {
          return `<span>${moment(this.x * 1000).format('DD-MMM')}: <b>${this.y}</b> <br /><br /> ${
            this.point['assetName']
          }</span>`;
        }
      };

      option.xAxis = {
        type: 'datetime',
        title: {
          text: highchartOptions.xAxisLabel
        },
        labels: {
          formatter: function (this) {
            return `<span>${moment(Number(this.value) * 1000).format('DD-MMM')}</span>`;
          }
        },
        crosshair: false
      };
      option.yAxis = {
        type: 'logarithmic'
      };
      option.colors = ['rgba(5,141,199,0.5)', 'rgba(232,7,22,0.5)'];
    }

    if (highchartOptions.clickFunctionEnabled) {
      option.plotOptions.series.cursor = 'pointer';
      option.plotOptions.series.point = {
        events: {
          click: (event: Highcharts.PointClickEventObject) => highchartOptions.functionName(event)
        }
      };
    }

    if (chartIdName.includes('tank-balance')) {
      option.tooltip = {
        formatter: function (this) {
          return `<div><span style="color:#000;padding:0">${this.points[0].series.name}:
          <b>${Highcharts.numberFormat(this.y, 0, '.', ',')}</b><br>
          Last updated: 
          <b>${highchartOptions.extraData.extraToolTips[this.x]}</b></span></div>`;
        },
        shared: true,
        useHTML: true
      };
    }

    return Highstocks.chart(chartId, option);
  }
}
