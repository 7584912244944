import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'cameltotitlecase'
})
export class CamelToTitleCasePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return _.startCase(_.camelCase(value));
  }
}
