import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-close-btn',
  templateUrl: './modal-close-btn.component.html',
  styleUrls: ['./modal-close-btn.component.scss']
})
export class ModalCloseBtnComponent {
  @Input() btnSize: string;
  @Input() closeByMethod: boolean;
  @Input() btnAlignment: string;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  btnClicked(): void {
    if (this.closeByMethod) {
      this.closeDialog.emit();
    }
  }
}
