import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteDetailsService } from '../../layout/setup/sites/site-service/site-detail.service';
import { AssetClassService } from '../../layout/models/asset-class/asset-class-service/asset-class.service';
import { AssetListService } from '../../layout/models/asset/asset-service/asset-list.service';
import { isNullOrUndefined } from '../function-util';
import { AnyLayout } from 'mapbox-gl';

@Component({
  selector: 'app-create-asset-by-user',
  templateUrl: './create-asset-by-user.component.html',
  styleUrls: ['./create-asset-by-user.component.scss']
})
export class CreateAssetByUserComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateAssetByUserComponent>,
    private siteService: SiteDetailsService,
    private assetClassService: AssetClassService,
    private assetService: AssetListService,
    private snackBar: MatSnackBar
  ) {}

  siteList: any[] = [];
  filteredAssetClassList = [];
  assetStatus = ['ACTIVE', 'INACTIVE'];
  assetClassFilter;

  requestObj: {
    siteId: number;
    assetClassId: number;
    assetName: string;
    assetDesc: string;
    status: string;
    hourReading: number;
  };

  returnAsset: {
    assetClassId: number;
    asset: {
      entityId: number;
      entityName: string;
      entityType: string;
      entityValue: number;
      entityLastReading: number;
    };
  };
  assetClassList = [];

  ngOnInit() {
    this.siteService.getAllSites().subscribe((res) => {
      this.siteList = res;
    });
    /*this.assetClassService.getAllAssetClass().subscribe(res => {
      this.assetClassList = res;
      this.filteredAssetClassList = this.assetClassList.slice(0);
    });*/
   
    this.requestObj = {
      siteId: null,
      assetClassId: null,
      assetName: null,
      assetDesc: null,
      status: null,
      hourReading: null
    };
    this.assetClassService.getAllAssetClass().subscribe((res: any) => {
      this.filteredAssetClassList = res.slice(0);
      this.assetClassList = res;
    });
  }

  onSubmit() {
    if (
      this.requestObj.siteId != null &&
      this.requestObj.assetClassId != null &&
      this.requestObj.assetName.length &&
      this.requestObj.hourReading != null
    ) {
      this.requestObj.assetDesc = this.requestObj.assetName;
      this.requestObj.status = 'INACTIVE';
      this.assetService.createAssetByUser(this.requestObj).subscribe((res) => {
        if (!isNullOrUndefined(res)) {
          this.confirm(res);
          this.openSnackBar('Asset created Successfully', 'success');
        } else {
          this.openSnackBar('Unable to Perform Action', 'error');
        }
      });
    } else {
      this.openSnackBar('Please fill all the boxes to continue', 'error');
    }
  }

  filterAssetClass() {
    const search = this.assetClassFilter.toLowerCase();
    if (!search) {
      this.filteredAssetClassList = this.assetClassList.slice(0);
      return;
    } else {
      this.filteredAssetClassList = this.assetClassList.filter((item) =>
        item.assetClassDesc.toLowerCase().includes(search)
      );
    }
  }

  cancel() {
    this.dialogRef.close('N');
  }

  confirm(res) {
    this.returnAsset = {
      assetClassId: res.assetClassId,
      asset: {
        entityId: res.assetId,
        entityName: res.assetName,
        entityType: 'ASSET',
        entityValue: res.siteId,
        entityLastReading: 0
      }
    };
    this.dialogRef.close(this.returnAsset);
  }

  openSnackBar(message: string, type: string, duration: number = 5000): void {
    this.snackBar.open(message, 'X', {
      duration: duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      panelClass:
        type === 'success'
          ? ['success-snackbar']
          : type === 'error'
          ? ['error-snackbar']
          : type === 'info'
          ? ['info-snackbar']
          : null
    });
  }
}
