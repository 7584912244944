import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { LoggedInUserDetailService } from './core/services/loggedIn-user-detail-service';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalHeaderSiteService } from './core/services/global-header-site.service';
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private loggedInUserDetService: LoggedInUserDetailService,
    private router: Router,
    private headerService: GlobalHeaderSiteService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.split('/').includes('dashboard')) {
          this.headerService.headerActiveClass.next(true);
        } else {
          this.headerService.headerActiveClass.next(false);
        }
      }
    });

    this.authService.getLoggedInUserDetails().subscribe((response) => {
      this.loggedInUserDetService.loggedInUserDetails.next(response);
    });
  }
}
