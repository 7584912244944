import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.scss']
})
export class StepListComponent {
  @Input() stepListTitle: string;
  @Input() stepListSubtitle?: string;
  @Input() stepListTitleColor? = '#585858';
  @Input() stepListSubtitleColor? = '#8F8F8F';
  @Input() faIconClass? = 'fa-hashtag';
  @Input() onAddClick: Function;

  constructor() {}
}
