<div class="tabPane-wrapper">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="tabPane">
      <ul class="nav nav-tabs" role="tablist" *ngIf="chartArray?.length > 0">
        <ng-container *ngFor="let report of chartArray">
          <li class="nav-item" *ngIf="report.enabled" (click)="showTab(report.title)">
            <a class="nav-link" data-toggle="tab" role="tab"
              [class.tabActive]="forPdf ? (report.title === currentActive) : report.tabActive">
              <span>{{ report.title }}</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>