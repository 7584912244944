<div class="modal-content modal-centered" data-backdrop="static" data-keyboard="false">
  <!-- <div class="modal-header">
        <h4 class="modal-title">{{modalHeader}}</h4>
        <button class="close" aria-label="Close" (click)="cancelModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
  <div class="modal-body">
    <div [innerHtml]="modalContent"></div>
    <div class="form-group" *ngIf="showInputText">
      <input type="text" class="form-control" [(ngModel)]="inputContent" placeholder="{{ placeholderContent }}" />
    </div>
  </div>

  <div class="modal-footer text-right">
    <button mat-button color="primary" *ngIf="showCancelButton" (click)="cancelModal()">{{ cancelMessage }}</button>
    <button mat-button color="primary" (click)="okModal()">{{ okMessage }}</button>
  </div>
</div>
