import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from '../../function-util';
import { HighstockRenderService } from '../../render-highstock/render-highstock.service';

@Component({
  selector: 'app-scatter-chart-modal',
  templateUrl: './scatter-chart-modal.component.html',
  styleUrls: ['./scatter-chart-modal.component.scss']
})
export class ScatterChartModalComponent implements OnInit {
  isDataFound = false;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chartId: string;
      title: string;
      getData: () => Promise<any>;
    },
    private highstockService: HighstockRenderService,
    public dialogRef: MatDialogRef<ScatterChartModalComponent>
  ) {}

  ngOnInit() {
    this.data.getData().then((res) => {
      this.highstockService.renderHighstock('scatter-chart-id', this.getScatterChart(res));
    })
    .finally(() => {
        this.isLoading = false;
    });
  }

  getScatterChart(data) {
    if (data.top.length === 0 && data.bottom.length === 0) {
      this.isDataFound = false;
      return;
    }
    const chartData = [
      {
        turboThreshold: data.top.length,
        name: 'Top 75 percentile',
        id: 'chartTop',
        marker: {
          symbol: 'circle'
        },
        data: data.top
      },
      {
        turboThreshold: data.bottom.length,
        name: 'Bottom 25 percentile',
        id: 'chartBottom',
        marker: {
          symbol: 'triangle'
        },
        data: data.bottom
      }
    ];

    this.isDataFound = true;

    return {
      chartType: 'scatter',
      chartTitle: '',
      xAxisLabel: '',
      xAxisKeys: [],
      yAxisLabel: '',
      inside: false,
      chartData: chartData,
      showLegend: false,
      isStackedColumn: false,
      clickFunctionEnabled: false
    };
  }

  cancel() {
    this.dialogRef.close('N');
  }
}
