<div class="chart-card">
  <div>
    <div class="container-header">
      <div class="title">
        <p class="heading">{{ data.title }}</p>
      </div>
      <app-modal-close-btn class="close-icon" btnAlignment="top-1" btnSize="larger" [closeByMethod]=true (closeDialog)="cancel()">
      </app-modal-close-btn>
    </div>
    <hr class="hr-line" />
  </div>
  <div>
    <div class="chart-container">
      <div class="graph">
        <div id="review-analytics-state"></div>
        <div class="is-data-found" *ngIf="!isDataFound">
          <P>No Data Found</P>
        </div>
      </div>
    </div>
  </div>
</div>
