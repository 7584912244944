<div class="step-card">
    <div class="card-flex">
        <div class="title-section">
            <i class="title-icon fa" [ngClass]="faIconClass" [style.color]="faIconColor"
             [ngStyle]="noIconBorder ? {'border': 'none'} : {}"></i>
            <div class="title">
                <ng-container *nzStringTemplateOutlet="stepTitle">
                    <h6 [style.color]="stepTitleColor">
                        {{ stepTitle }}
                    </h6>
                </ng-container>
                <span [style.color]="stepSubtitleColor">
                    {{ stepSubtitle }}
                </span>
            </div>
            <i *ngIf="showArrow" class="fa fa-arrow-right" style="font-size: 1.75rem; color: #ADACAC"></i>
        </div>

        <div class="children-input">
            <ng-content></ng-content>
        </div>
    </div>
</div>