import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { CustomDateFilterOptions } from '../enum-util';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent implements OnInit {
  @Input() dateFilterOption: string[];
  @Input() initialDateOption: CustomDateFilterOptions;
  @Output() dateChanged = new EventEmitter<{ fromDate: string; toDate: string }>();

  allFilterMenu = [
    {
      name: 'Yesterday',
      key: 'YESTERDAY',
      value: 1
    },
    {
      name: 'Today',
      key: 'TODAY',
      value: 0
    },
    {
      name: 'Last 7 Days',
      key: 'LAST_7_DAYS',
      value: 7
    },
    {
      name: 'Week',
      key: 'WEEK_CUSTOM',
      value: -2
    },
    {
      name: 'Month',
      key: 'MONTH',
      value: -3
    },
    {
      name: 'Custom',
      key: 'CUSTOM',
      value: -1
    }
  ];

  filterMenu = [];
  selectedDateOption: any;
  isDateRangeSelector: boolean = false;
  isWeekRangeSelector = false;
  isMonthRangeSelector: boolean = false;
  selectedRangeOption: any;
  selectedWeekRangeOption: any;
  selectedMonthRangeOption: any;

  constructor() {}

  ngOnInit(): void {
    this.filterMenu = this.allFilterMenu.filter((menu: { key: string }) => !this.dateFilterOption.includes(menu.key));
    this.selectedDateOption =
      this.initialDateOption !== undefined
        ? this.filterMenu.find((menu: { key: CustomDateFilterOptions }) => this.initialDateOption == menu.key)
        : this.filterMenu[0];

    this.onDateSelectionChanged(this.selectedDateOption);
  }

  onDateSelectionChanged(event: { key: string; value: number }): void {
    this.isDateRangeSelector = this.isWeekRangeSelector = this.isMonthRangeSelector = false;
    const currentDate = new Date();

    switch (event.key) {
      case 'CUSTOM':
        this.isDateRangeSelector = true;
        break;
      case 'WEEK_CUSTOM':
        this.isWeekRangeSelector = true;
        break;
      case 'LAST_7_DAYS':
        this.emitDateRangeChange(currentDate, currentDate, event.value, 0);
        break;
      case 'MONTH':
        this.isMonthRangeSelector = true;
        this.selectedMonthRangeOption = Date.now();
        this.onCustomRangeChanged(currentDate, 'month');
        break;
      default:
        this.emitDateRangeChange(currentDate, currentDate, event.value, event.value);
        break;
    }
  }

  onRangeChanged(event: Date[]): void {
    this.emitDateRangeChange(event[0], event[1], 0, 0);
  }

  onCustomRangeChanged(date: Date, range: 'week' | 'month'): void {
    this.dateChanged.emit({
      fromDate: moment(date).startOf(range).format('yyyy-MM-DD'),
      toDate: moment(date).endOf(range).format('yyyy-MM-DD')
    });
  }

  emitDateRangeChange(fromDate?: Date, toDate?: Date, minusFrom?: number, minusTo?: number): void {
    this.dateChanged.emit({
      fromDate: moment(fromDate).subtract(minusFrom, 'day').format('yyyy-MM-DD'),
      toDate: moment(toDate).subtract(minusTo, 'day').format('yyyy-MM-DD')
    });
  }
}
