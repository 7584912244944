import { Component } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';

@Component({
  selector: 'app-link-renderer',
  templateUrl: './link-site-render.component.html',
  styleUrls: ['./link-site-render.component.scss']
})
export class LinkSiteRenderComponent extends AgCellRendererBase {
  isWorkflowlocked: boolean;
  canEdit = true;

  constructor() {
    super();
  }

  ngOnInit() {
    const currentUserId = sessionStorage.getItem('currentUserId');
    if (this.params.data.lockedByUserId > 0) {
      this.isWorkflowlocked = true;
      // tslint:disable-next-line:radix
      if (parseInt(currentUserId) === this.params.data.lockedByUserId) {
        this.canEdit = true;
      } else {
        this.canEdit = false;
      }
    } else {
      this.isWorkflowlocked = false;
      this.canEdit = true;
    }
  }

  onLink(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.REDIRECT_LINK_EVENT, event);
  }
}
