import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { isNullOrUndefined } from '../function-util';
import { HighchartOptions } from '../render-highchart/render-highcharts-options';
import { HighstockRenderService } from '../render-highstock/render-highstock.service';

@Component({
  selector: 'app-daily-monthly-connected-chart',
  templateUrl: './daily-monthly-connected-chart.component.html',
  styleUrls: ['./daily-monthly-connected-chart.component.scss']
})
export class DailyMonthlyConnectedChartComponent implements OnInit, AfterViewInit {
  @Input() chartData: any;
  @Input() label: any;
  @Input() forPdf: boolean;

  constructor(private renderHighstockService: HighstockRenderService) {}

  siteId: string;
  chart: { [key: string]: string } = {};
  monthChartTrend: Highcharts.Chart;
  sourceChartTrend: Highcharts.Chart;
  dailyChartTrend: Highcharts.Chart;
  monthChartCategory = [];
  chartName: string;

  ngOnInit() {}

  async ngAfterViewInit() {
    while (isNullOrUndefined(this.chartData) || isNullOrUndefined(this.label)) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    this.chart = this.chartData;
    this.chartName = this.label;

    if (!isNullOrUndefined(this.chartName) && !isNullOrUndefined(this.chart)) {
      while (isNullOrUndefined(document.querySelector('#connectedMonthlyChart' + this.chartName))) {
        await new Promise((resolve) => setTimeout(resolve, 10));
      }

      this.monthChartTrend = this.renderHighstockService.renderHighstock(
        'connectedMonthlyChart' + this.chartName,
        this.getMonthChartData(this.chart.MONTH)
      );
      this.sourceChartTrend = this.renderHighstockService.renderHighstock(
        'connectedSourceChart' + this.chartName,
        this.getSourceChartData(this.chart.SOURCE)
      );
      this.dailyChartTrend = this.renderHighstockService.renderHighstock(
        'connectedDailyChart' + this.chartName,
        this.getDailyChartData(this.chart.DAILY)
      );
    }
  }

  getMonthChartData(month): HighchartOptions {
    const chartMapData = month['value'];
    const averageData = month['mean'];
    const data = [];
    Object.keys(chartMapData).forEach((monthKey) => {
      const yearRegex = /\b\d{4}\b/;
      const monthName = yearRegex.test(monthKey) ? moment(monthKey).format('MMMM').toUpperCase() : monthKey;
      data.push([monthName, chartMapData[monthKey]]);
      this.monthChartCategory.push(monthName);
    });
    const chartData = [
      {
        name: this.chartName,
        type: 'column',
        data: data,
        colorByPoint: true
      }
    ];
    const highchartOptions: HighchartOptions = {
      chartType: 'column',
      chartTitle: '',
      xAxisLabel: '',
      xAxisKeys: this.monthChartCategory,
      yAxisLabel: '',
      inside: false,
      chartData: chartData,
      showLegend: false,
      isStackedColumn: false,
      clickFunctionEnabled: true,
      height: 180,
      extraData: {
        average: averageData
      },
      functionName: this.onClickEvent
    };
    return highchartOptions;
  }

  onClickEvent = (event) => {
    const source = this.updateSourceChartData(event.point.name, 'column');
    const daily = this.updateDailyChartData(event.point.name, 'areaspline');
    this.sourceChartTrend.update(source);
    this.dailyChartTrend.update(daily);
  };

  updateSourceChartData(pointId, chartType): Highcharts.Options {
    const chartMapData = this.chart.SOURCE[pointId];
    const data = [];
    const categories = [];
    Object.keys(chartMapData)
      .sort()
      .forEach((source) => {
        data.push([source, chartMapData[source]]);
        categories.push(source);
      });
    return {
      chart: {
        type: chartType
      },
      xAxis: {
        categories: categories
      },
      series: [
        {
          name: this.chartName,
          type: chartType,
          data: data
        }
      ]
    };
  }

  updateDailyChartData(pointId, chartType): Highcharts.Options {
    const { chartData, categories } = this.getDailySeriesAndCategory(this.chart.DAILY[pointId], chartType);
    return {
      chart: {
        type: chartType
      },
      xAxis: {
        categories: categories
      },
      series: chartData
    };
  }

  getSourceChartData(source): HighchartOptions {
    const chartMapData = source[this.monthChartCategory[this.monthChartCategory.length - 1]];
    const data = [];
    const categories = [];
    Object.keys(chartMapData)
      .sort()
      .forEach((sourceKey) => {
        data.push([sourceKey, chartMapData[sourceKey]]);
        categories.push(sourceKey);
      });
    const chartData = [
      {
        name: this.chartName,
        type: 'column',
        data: data,
        colorByPoint: true
      }
    ];
    const highchartOptions: HighchartOptions = {
      chartType: 'column',
      chartTitle: '',
      xAxisLabel: '',
      xAxisKeys: categories,
      yAxisLabel: '',
      inside: false,
      chartData: chartData,
      showLegend: false,
      isStackedColumn: false,
      clickFunctionEnabled: false,
      height: 180
    };
    return highchartOptions;
  }

  getDailyChartData(daily): HighchartOptions {
    const { chartData, categories } = this.getDailySeriesAndCategory(
      daily[this.monthChartCategory[this.monthChartCategory.length - 1]],
      'areaspline'
    );
    const highchartOptions: HighchartOptions = {
      chartType: 'areaspline',
      chartTitle: '',
      xAxisLabel: '',
      xAxisKeys: categories,
      yAxisLabel: '',
      inside: false,
      chartData: chartData,
      showLegend: false,
      isStackedColumn: false,
      clickFunctionEnabled: false,
      height: 180
    };
    return highchartOptions;
  }

  getDailySeriesAndCategory(chartMapData, chartType) {
    const data = [];
    const categories = [];
    const categoriesPrev = [];
    chartMapData.forEach((chartData, index) => {
      const dataArr = [];
      Object.keys(chartData)
        .sort()
        .forEach((localDateKey) => {
          dataArr.push([localDateKey, chartData[localDateKey]]);
          if (index == 0) {
            categoriesPrev.push(moment(new Date(localDateKey)).format('DD/MM'));
          } else {
            categories.push(moment(new Date(localDateKey)).format('DD/MM'));
          }
        });
      data.push(dataArr);
    });
    if (categoriesPrev.length > categories.length) {
      const additionalKeys = categoriesPrev.slice(categoriesPrev.length - (categoriesPrev.length - categories.length));
      categories.push(...additionalKeys);
    }
    const chartData: any[] = [
      {
        name: 'Selected Month',
        type: chartType,
        data: data[1],
        color: '#72b3f3'
      },
      {
        type: chartType,
        name: 'Previous Month',
        data: data[0],
        color: '#d7d7d7'
      }
    ];
    return { chartData, categories };
  }
}
