import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'src/app/shared/function-util';
import { MasterEntity } from 'src/app/shared/interfaces/master-entity';

@Injectable({
  providedIn: 'root'
})
export class FuelEnergyService {
  fuelApiPrefixUrl: String = '/v1/fuel/';
  overviewApiPrefixUrl: String = '/v1/overview/';

  constructor(private http: HttpClient) {}

  getFuelConsumptionBySite(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAssetFuelUsage/' + siteId);
  }

  getDistanceFuelWorkHourTrend(siteId: String): Observable<any> {
    return this.http.get(this.overviewApiPrefixUrl + 'getDistanceFuelWorkHourTrend/' + siteId);
  }

  getFuelEconomyPerAsset(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelEconomyPerAsset/' + siteId);
  }

  getActualEstimatedFuel(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getActualEstimatedFuel/' + siteId);
  }

  getEfficiencyAverage(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getEfficiencyAverage/' + siteId);
  }

  getFuelAlertCount(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelAlertCount/' + siteId);
  }

  getFuelTankTrend(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelTankTrend/' + siteId);
  }

  getFuelTrend(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelTrend/' + siteId);
  }

  getFuelConsumptionWeekly(siteId: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelConsumptionWeekly/' + siteId);
  }

  getFuelConsumptionMonthly(siteId: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelConsumptionMonthly/' + siteId);
  }

  getFuelInTank(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelInTank/' + siteId);
  }

  getAllFuelEntity(): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAllFuelEntity');
  }

  getEntities(siteId: string, getAllLocations?: boolean): Observable<any> {
    const url = `${this.fuelApiPrefixUrl}getEntities/${siteId}`;
    if (!isNullOrUndefined(getAllLocations)) {
      let params = new HttpParams().set('getAllLocations', String(!isNullOrUndefined(getAllLocations)));
      return this.http.get(url, { params: params });
    } else {
      return this.http.get(url);
    }
  }

  getAllFuelEntityAssetByClassId(): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAllFuelEntityAssetByClassId');
  }

  getAllAssetsByClassId(siteId: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAllAssetsByClassId/' + siteId);
  }

  addFuelEntity(fuelEntity: { entityName: string; entityType: string; siteId: number }): Observable<any> {
    return this.http.post(this.fuelApiPrefixUrl + 'addFuelEntity', {
      entityId: 0,
      ...fuelEntity
    });
  }

  deleteFuelEntity(fuelEntityId: number): Observable<any> {
    return this.http.delete(this.fuelApiPrefixUrl + `deleteFuelEntity/${fuelEntityId}`);
  }

  getFuelRefillDetails(siteId: string, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAllFuelRefillDetails/' + siteId + '/' + fromDate + '&' + toDate);
  }

  saveFuelRefillDetails(fuelRefillEntry: {
    asset: MasterEntity;
    date: string;
    department: MasterEntity;
    fuelEndReading: number;
    fuelRefillId: number;
    fuelSource: MasterEntity;
    fuelStartReading: number;
    hourReading: number;
    workLocation: MasterEntity;
    operator: MasterEntity;
    siteId: number;
  }): Observable<any> {
    return this.http.post(this.fuelApiPrefixUrl + 'saveFuelRefillDetails', fuelRefillEntry);
  }

  checkOperatorPasscode(data: number, dataType: string, module: string): Observable<any> {
    return this.http.get(`${this.fuelApiPrefixUrl}checkOperatorPasscode?module=${module}&${dataType}=${data}`);
  }

  getFuelConsumptionForRefillAsset(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelConsumptionFromRefillData/' + siteId);
  }

  updateFuelRefillDetails(fuelRefillEntry: {
    date: string;
    fuelRefillId: number;
    fuelStartReading: number;
    fuelEndReading: number;
    hourReading: number;
    tankBalance: number;
    siteId: number;
    comment: string;
    asset: { entityId: number; entityName: string; entityType: string };
    department: { entityId: number; entityName: string; entityType: string };
    fuelSource: {
      entityId: number;
      entityName: string;
      entityType: string;
      entityLastReading: number;
      entityValue: number;
    };
    workLocation: { entityId: number; entityName: string; entityType: string };
    operator: { entityId: number; entityName: string; entityType: string };
  }): Observable<any> {
    return this.http.post(this.fuelApiPrefixUrl + 'updateFuelRefillDetails', fuelRefillEntry);
  }

  qrCodeOperation(data: any): Observable<any> {
    return this.http.post('/v1/asset/qrCodeOperation', data);
  }

  deleteFuelRefillDetails(fuelRefillEntry: {
    date: string;
    fuelRefillId: number;
    fuelStartReading: number;
    fuelEndReading: number;
    hourReading: number;
    tankBalance: number;
    siteId: number;
    comment: string;
    asset: { entityId: number; entityName: string; entityType: string };
    department: { entityId: number; entityName: string; entityType: string };
    fuelSource: {
      entityId: number;
      entityName: string;
      entityType: string;
      entityLastReading: number;
      entityValue: number;
    };
    workLocation: { entityId: number; entityName: string; entityType: string };
    operator: { entityId: number; entityName: string; entityType: string };
  }): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: fuelRefillEntry
    };
    return this.http.delete(this.fuelApiPrefixUrl + 'deleteFuelRefillDetails', options);
  }

  getAssetTagValueBySite(siteId: String): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getAssetTagValueBySite/' + siteId);
  }

  updateFuelSource(data): Observable<any> {
    return this.http.post(this.fuelApiPrefixUrl + 'updateFuelSource', data);
  }

  getFuelDataForTheYear(
    siteId: string,
    assetClassNameList: any[],
    fetchType: string,
    year: number = 0
  ): Observable<any> {
    const params = new HttpParams().append('selectedYear', year);
    return this.http.get(
      this.fuelApiPrefixUrl + 'getMonthlyFuelReportByAssetClass/' + siteId + '/' + assetClassNameList + '/' + fetchType,
      { params: params }
    );
  }

  getTripRateChartData(
    siteId: string,
    assetClassName: string,
    tripCategory: string,
    fetchType: string,
    year: number = 0
  ): Observable<any> {
    const params = new HttpParams().append('selectedYear', year);
    return this.http.get(
      this.fuelApiPrefixUrl +
        'getTripRateChartData/' +
        siteId +
        '/' +
        assetClassName +
        '/' +
        tripCategory +
        '/' +
        fetchType,
      { params: params }
    );
  }

  getScatterPlotData(
    siteId: string,
    assetClassName: string,
    category: string,
    fetchType: string,
    latestGraphDate: string
  ): Observable<any> {
    const params = new HttpParams().append('latestDate', latestGraphDate);
    return this.http.get(
      this.fuelApiPrefixUrl + 'getScatterPlotData/' + siteId + '/' + assetClassName + '/' + category + '/' + fetchType,
      { params: params }
    );
  }

  getFuelLastThreeMonthChart(siteId: string): Observable<any> {
    return this.http.get(this.fuelApiPrefixUrl + 'getFuelLastThreeMonthChart/' + siteId);
  }
}
