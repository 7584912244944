<div class="modal-header">
  <span mat-dialog-title>Create New Asset</span>
  <div class="closeButton">
    <button type="button" mat-icon-button aria-label="Cancel" (click)="cancel()">
      <mat-icon style="padding-left: 7px">close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="d-flex justify-content-center align-items-center pt-4">
  <mat-card-content>
    <div class="row">
      <div class="col">
        <span>Asset Name</span>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Asset Name</mat-label>
          <input matInput placeholder="Asset Name" [(ngModel)]="requestObj.assetName" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>Asset Class</span>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Asset Class</mat-label>
          <mat-select placeholder="Select Asset Class" [(value)]="requestObj.assetClassId">
            <input
              class="input-field dropdown-filter"
              type="text"
              [(ngModel)]="assetClassFilter"
              placeholder="Search Asset Class"
              (ngModelChange)="filterAssetClass()" />
            <mat-option *ngFor="let assetClass of filteredAssetClassList" [value]="assetClass.assetClassId">
              {{ assetClass.assetClassDesc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>Site</span>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Site</mat-label>
          <mat-select placeholder="Select Site Name" [(value)]="requestObj.siteId">
            <mat-option *ngFor="let site of siteList" [value]="site.siteId">
              {{ site.siteName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>Hour Reading</span>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Hour Reading</mat-label>
          <input matInput type="number" placeholder="Hour Reading" [(ngModel)]="requestObj.hourReading" />
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</div>
<mat-card-footer>
  <div class="modal-footer text-right">
    <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
  </div>
</mat-card-footer>
