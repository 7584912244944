import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorComp } from 'ag-grid-community';

@Component({
  selector: 'app-dropdown-cell-editor',
  templateUrl: './dropdown-cell-editor.component.html',
  styleUrls: ['./dropdown-cell-editor.component.scss']
})
export class DropdownCellEditorComponent implements ICellEditorComp {
  options = [];
  selectedOption: FormControl = new FormControl();

  isPopup?(): boolean {
    return false;
  }

  getGui(): HTMLElement {
    return null;
  }

  destroy?(): void {}

  getValue() {
    return this.selectedOption.value;
  }

  agInit(params: any): void {
    this.options = params.values;
    const selectOption = params.values.filter((ele) => ele.entityName === params.value)[0];
    this.selectedOption.setValue(selectOption);
  }
}
