import { MatDialog } from '@angular/material/dialog';
import { CommonPasscodeModalComponent } from './common-passcode-modal/common-passcode-modal.component';
import { isNullOrUndefined } from './function-util';
import { FuelEnergyService } from '../layout/dashboard/fuel-energy/fuel-energy.service';
import { lastValueFrom } from 'rxjs';

export function openPasscodeDialog(
  matDialog: MatDialog,
  fuelService: FuelEnergyService,
  operatorType: string,
  backUri?: string
): Promise<boolean> {
  return new Promise<boolean>((resolve: (value: boolean) => void, reject: (value: boolean) => void) => {
    const dialogRef = matDialog.open(CommonPasscodeModalComponent, {
      width: '46vw',
      height: '30vh',
      backdropClass: 'passcode-modal-backdrop',
      data: {
        codeLength: 4,
        backUrl: !isNullOrUndefined(backUri) ? backUri : null,
        passcodeChecker: (pin) => fuelService.checkOperatorPasscode(pin, 'passcode', operatorType)
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!isNullOrUndefined(result) && result !== 'N') {
        const item = {
          id: result.operatorId,
          name: result.operatorName,
          expiry: new Date().getTime() + 1000 * 60 * 30 // (1000 milliseconds * 60) -> 1 minute
        };
        localStorage.setItem('operator', JSON.stringify(item));
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

export async function checkOperatorExpiration(module: string, fuelService: FuelEnergyService): Promise<boolean> {
  const operatorDetails = JSON.parse(localStorage.getItem('operator'));
  if (!isNullOrUndefined(operatorDetails) && operatorDetails.expiry >= new Date().getTime()) {
    const observable = fuelService.checkOperatorPasscode(operatorDetails.id, 'operatorId', module);
    const result = await lastValueFrom(observable);
    if (isNullOrUndefined(result)) {
      return false;
    } else {
      const item = {
        id: result.operatorId,
        name: result.operatorName,
        expiry: new Date().getTime() + 1000 * 60 * 30
      };
      localStorage.setItem('operator', JSON.stringify(item));
      return true;
    }
  } else {
    return false;
  }
}
