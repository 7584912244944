import { Component, OnInit } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';

@Component({
  selector: 'app-count-renderer',
  template: `<span class="badge badge-secondary" [ngStyle]="countBadgeStyle(params.value)">{{ params.value }}</span>`
})
export class CountRendererComponent extends AgCellRendererBase implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  countBadgeStyle(badgeCount) {
    var textColor = 'white';
    var fontSize = '14px';
    var border = '0px';

    if (badgeCount == 1)
      return {
        color: textColor,
        background: '#ffd900',
        'font-size': fontSize,
        'border-radius': border
      };
    else if (badgeCount == 2)
      return {
        color: textColor,
        background: '#f1a94a',
        'font-size': fontSize,
        'border-radius': border
      };
    else if (badgeCount == 3)
      return {
        color: textColor,
        background: '#fc8c4c',
        'font-size': fontSize,
        'border-radius': border
      };
    else if (badgeCount == 4)
      return {
        color: textColor,
        background: '#f51717',
        'font-size': fontSize,
        'border-radius': border
      };
    else if (badgeCount == 5)
      return {
        color: textColor,
        background: '#a00000',
        'font-size': fontSize,
        'border-radius': border
      };
    else {
      return {
        color: null,
        background: null,
        'font-size': null,
        'border-radius': null
      };
    }
  }
}
